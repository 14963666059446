import React, { useRef } from "react";
import { useState } from "react";
import Collapsible from "react-collapsible";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  getApplyVoucherDetail,
  removeItemVoucherDetail,
} from "../../Redux/actions";
import {
  capitalizeFirstLetter,
  nFormatter,
  getUnixDateFormat,
  checkNull,
} from "../../helpers";
import { voucherPngIcon } from "../../Assets";
import moment from "moment";

function XgatePromoDetails({
  applied_voucher,
  voucher,
  activeTab,
  isValid,
  merchantId,
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const voucherKey =
    applied_voucher.length > 0
      ? applied_voucher.findIndex((p) => p.VoucherNo == voucher?.VoucherNo)
      : -1;
  function getVoucherType() {
    let res = "OFF";
    return res;
  }

  function getVoucherName() {
    let res = "Discount";
    return res;
  }

  function getVoucherValue() {
    let res = checkNull(voucher?.coupon_info?.discount_amt);
    return res;
  }

  return (
    <div className="promotion_details">
      <div className="row mx-0 justify-content-start">
        {/* <div className="squre-img col-4 mx-0 px-0">
          <figure className="">
            <img src={voucherPngIcon} alt="" />
          </figure>
        </div> */}
        <div className="naming-sec col-12">
          <h4>
            {checkNull(voucher?.coupon_info?.name,"N/A")}
          </h4>
          <p>
            <em>
              {" "}
              Expiry Date:{" "}
              {voucher?.coupon_info?.validateend
                ? moment(voucher?.coupon_info?.validateend).format("DD/MM/YYYY")
                : "N/A"}{" "}
            </em>
          </p>
          <div className="btns d-flex pt-3">
            <button className="more-btn" onClick={() => setOpen(!open)}>
              {" "}
              More{" "}
            </button>
            {/* {activeTab === 0 && (
              <button
                className={`green-btn btn-sm ${
                  voucherKey > -1 || !isValid ? "disabled" : ""
                }`}
                // onClick={() => {
                //   if (!isValid) {
                //     return;
                //   } else if (voucherKey > -1) {
                //     dispatch(removeItemVoucherDetail(voucherKey));
                //   } else {
                //     dispatch(getApplyVoucherDetail(voucher, merchantId));
                //   }
                // }}
              >
                {`${voucherKey > -1 ? "Applied" : "Apply"}`}
              </button>
            )} */}
          </div>
        </div>
      </div>
      <Collapsible open={open}>
        <div className="row">
          <div className="col-4 pr-0">
            <label> Promotion Code </label>
            <p> {checkNull(voucher?.coupon_info?.identifier,"N/A")} </p>
          </div>
          <div className="col-4 pr-0">
            <label> Promotion Type </label>
            <p> {`${getVoucherName()}`}</p>
          </div>
          <div className="col-4 pr-0">
            <label> Amount </label>
            <p>
              {`${getVoucherType()}`}
              {getVoucherValue()}
            </p>
          </div>
          <div className="col-4 pr-0">
            <label> Qualifying Items </label>
            <p> Order </p>
          </div>
        </div>
      </Collapsible>
    </div>
  );
}

export default XgatePromoDetails;
