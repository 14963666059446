import React, { useRef } from "react";
import { checkNull } from "../../helpers";

const LoginInput = ({ divClass = "", type = "text", children, ...extra }) => {
  return (
    <div
      className={`${
        checkNull(divClass, false) ? divClass : "row mx-0 justify-content-end"
      }`}
    >
      <input type={type} className="input-text" {...extra} />
      {children}
    </div>
  );
};
export default LoginInput;
