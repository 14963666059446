import {
  AddItemScreen,
  BundleAddItemScreen,
  CartScreen,
  NewItemsScreen,
  ConfirmationScreen,
  PromotionsScreen,
  TabScreen,
  CompletedScreen,
  SessionExpiredScreen,
} from "../Scenes/Screens";

export const tableRoutes = [
  {
    path: "/cart",
    component: CartScreen,
  },
  {
    path: "/new-items",
    component: ConfirmationScreen,
  },
  {
    path: "/completed",
    component: CompletedScreen,
  },
  {
    path: "/session-expired",
    component: SessionExpiredScreen,
  },
  {
    path: "/add-item",
    component: AddItemScreen,
  },
  {
    path: "/bundle-add-item",
    component: BundleAddItemScreen,
  },
  {
    path: "/promotions",
    component: PromotionsScreen,
  },
  {
    path: "/tab-screen",
    component: TabScreen,
  },
];
