import styled from 'styled-components';
import SVG from "react-inlinesvg";

export const StyledSVG = styled(SVG)`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  & path {
    fill: ${({ color }) => color};
  }
  & g {
    stroke: ${({ color,stroke }) => stroke ? color :'none'};
  }
`;

export const Wrapper = styled.div`
&: before{
  background : 'red';
}
`
//${({background})=>background}