import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import UnableToLoadData from "../Components/UnableToLoadData";
import ImageTag from "../Components/ImageTag";
import ImageViewer from "../Components/ImageViewer";
import ImageSlider from "../Components/ImageSlider";
import { Motion, spring, presets } from "react-motion";
import { useSwipeable, config } from "react-swipeable";

import {
  addItemCartDetail,
  updateItemCartDetail,
  removeItemCartDetail,
  getTableDetailByFilter,
  showNotificationWithTimeout,
  emptyEditedItemDetail,
} from "../../Redux/actions";
import {
  getModifierActionName,
  getModifierTypeName,
  getModifierActionSign,
  checkNegNum,
  makeNegToPosNum,
  checkBundleItemExistInCart,
  checkItemExistInCart,
  groupByArray,
  getRandomString,
  imageExists,
  getUniTimeString,
  checkExpiredSession,
  checkInactiveSession,
  checkArray,
  checkNull,
  checkIsNum,
  pluckArray,
} from "../../helpers";
import {
  badge,
  close_black,
  crossWhtIcon,
  cross_icon,
  downDown,
  downUp,
  fireFlame,
  minus,
  plus,
  squreImgLgPngIcon,
  tags,
  trending,
} from "../../Assets";
import { Fragment } from "react";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { StyledSVG } from "../../Utils/style";
import CustomDropDown from "../../Component/molecules/CustomDropDown";
import CustomButton from "../Components/CustomButton";

function BundleAddItem({
  loading,
  selected_item_detail,
  edited_item_detail,
  table_detail,
  filters,
  filter_tag_detail,
  cart_detail,
  show_complete_page,
  addItemCartDetail,
  updateItemCartDetail,
  getTableDetailByFilter,
  showNotificationWithTimeout,
  removeItemCartDetail,
  emptyEditedItemDetail,
  recent_order_detail,
  show_session_expired_page,
  pos_cart_detail,
  is_payment_cleared,
  items_86_detail,
  server_detail,
  server_user_detail,
  changeModalStyle = () => {},
  showBumdleItem,
}) {
  const history = useHistory();
  const timezone = table_detail?.Merchant?.timezone_offset ?? "00:00";
  const isPromotion = selected_item_detail?.promotions?.id ? true : false;

  const currDateTime = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("yyyy/MM/DD HH:mm:ss");
  const showPostTaxPrice =
    table_detail?.Merchant?.MerchantFinanceInfo?.menu_pricing_default == "2"
      ? true
      : false;
  // console.log("edited_item_detail", edited_item_detail);

  const isContainDiscountArr =
    selected_item_detail?.DiscountModifiers.findIndex(
      (p) => p.action == "discount"
    ) > -1
      ? true
      : false;
  const items86Arr = checkArray(items_86_detail)
    ? pluckArray(items_86_detail, "id")
    : [];
  const serviceChargePer = parseFloat(table_detail?.ServiceCharges ?? 0);
  const salesTaxPer = parseFloat(table_detail?.SalesTaxRate ?? 0);
  const showNoteCon =
    table_detail?.QrOrdering?.hide_note !== "yes" ? true : false;

  const [wrapperClass, setWrapperClass] = useState("");
  const cartItemKey =
    cart_detail && cart_detail.length > 0 && selected_item_detail?.id
      ? cart_detail.findIndex((p) => p.id == selected_item_detail.id)
      : -1;

  // console.log("selected_item_detail", selected_item_detail);

  function setBundleItems() {
    let res = [];
    if (edited_item_detail?.selectedBundleItem?.length > 0) {
      edited_item_detail?.selectedBundleItem?.map((item, index) => {
        // if (index == 0) {
        const bundleOptnKey =
          selected_item_detail?.BundledOptionSeries?.findIndex(
            (p) => p.id == item?.option_series_id
          );
        if (bundleOptnKey > -1) {
          const bundleOptn =
            selected_item_detail?.BundledOptionSeries[bundleOptnKey];
          const bundleOptnItmKey = bundleOptn?.BundledMenuItem?.findIndex(
            (p) => p.id == item?.id
          );
          if (bundleOptnItmKey > -1) {
            res.push(bundleOptn?.BundledMenuItem[bundleOptnItmKey]);
          }
        }
        // }
      });
    } else if (
      selected_item_detail?.recomItem?.bundle_item_ids &&
      selected_item_detail?.recomItem?.bundle_item_ids?.length > 0
    ) {
      selected_item_detail?.recomItem?.bundle_item_ids?.map((bundleItemId) => {
        const bundleItemIndex =
          selected_item_detail?.BundledOptionSeries?.findIndex((p) => {
            // console.log("catbundleItem", p?.BundledMenuItem, bundleItemId);
            return (
              p?.BundledMenuItem.findIndex(
                (q) => q.bundle_menu_item_id == bundleItemId?.menu_item_id
              ) > -1
            );
          });
        if (bundleItemIndex > -1) {
          const bundleItem =
            selected_item_detail?.BundledOptionSeries?.[bundleItemIndex];
          const bundleOptionItem = bundleItem?.BundledMenuItem?.findIndex(
            (s) => s.bundle_menu_item_id == bundleItemId?.menu_item_id
          );
          if (
            bundleOptionItem > -1 &&
            bundleItem?.BundledMenuItem?.[bundleOptionItem]
          ) {
            res.push(bundleItem?.BundledMenuItem?.[bundleOptionItem]);
          }
          // console.log("catbundleItem", bundleItem);
        }
      });
    }

    if (selected_item_detail?.BundledOptionSeries?.length > 0) {
      //Includes auto select
      selected_item_detail?.BundledOptionSeries?.filter(
        (p) => p?.option_series_type == ""
      )?.map((item, index) => {
        if (item?.BundledMenuItem?.length > 0) {
          item?.BundledMenuItem?.filter(
            (q) => !items86Arr.includes(q?.id)
          )?.map((option) => {
            // prevent dublicate from automatic adding fixed item
            if (
              selectedBundleItem.findIndex((p) => p.id == option.id) === -1 &&
              res.findIndex((p) => p.id == option.id) === -1
            ) {
              res.push(option);
            }
            // prevent dublicate from automatic adding fixed item END
          });
        }
      });
    }
    if (res.length > 0) {
      setSelectedBundleItem(res);
      setModifierChanged(!modifierChanged);
    }
    return res;
  }

  const editedItemSelectedKey = edited_item_detail?.modifierArray?.findIndex(
    (p) => p.action == "discount"
  );
  const defaultSelectedItemPrice =
    editedItemSelectedKey !== undefined && editedItemSelectedKey > -1
      ? parseFloat(
          edited_item_detail?.modifierArray?.[editedItemSelectedKey]
            ?.modifier_price
        )
      : parseFloat(
          selected_item_detail?.dinein_price?.DineIn
            ? selected_item_detail?.dinein_price?.DineIn
            : 0
        );

  const [currentItemQytEdit, setCurrentItemQytEdit] = useState(false);
  const [currentItemQyt, setCurrentItemQyt] = useState(
    edited_item_detail?.quantity != undefined ||
      edited_item_detail?.quantity > 0
      ? edited_item_detail?.quantity
      : 1
  );
  const currentItemQytRef = useRef(currentItemQyt);
  currentItemQytRef.current = currentItemQyt;
  const [selectedItemPrice, setSelectedItemPrice] = useState(
    defaultSelectedItemPrice
  );
  const [modifierChanged, setModifierChanged] = useState(false);

  const [showModifiers, setShowModifiers] = useState(false);
  const [totalPrice, setTotalPrice] = useState(parseFloat(selectedItemPrice));
  const [totalPriceWithTax, setTotalPriceWithTax] = useState(
    parseFloat(selectedItemPrice)
  );
  const [hideHeader, setHideHeader] = useState(false);
  const [hideImages, setHideImages] = useState(false);
  const [isHeaderAllUp, setIsHeaderAllUp] = useState(true);
  const [showImageView, setShowImageView] = useState(false);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedBundleItem, setSelectedBundleItem] = useState([]);

  const [imageArr, setImageArr] = useState([]);
  const imageArrRef = useRef(imageArr);
  imageArrRef.current = imageArr;

  const qytEditInputRef = useRef(null);

  useEffect(() => {
    if (show_complete_page) {
      history.push("/completed");
    }
  }, [show_complete_page]);

  useEffect(() => {
    if (currentItemQytEdit) {
      qytEditInputRef?.current?.focus();
    }
  }, [currentItemQytEdit]);

  function getImages(images) {
    let arr = images?.map(async (img) => {
      if (img?.file_name) {
        let a = await imageExists(img?.file_name);
        return a;
      }
    });
    Promise.all(arr)
      .then((resp) => {
        if (resp?.length > 0) {
          setImageArr(resp);
        } else {
          setHideImages(true);
          setHideHeader(true);
          setWrapperClass("scroll-active");
        }
      })
      .catch((er) => {
        setHideImages(true);
        setHideHeader(true);
        setWrapperClass("scroll-active");
      });
  }

  // console.log("selectedBundleItem", selectedBundleItem);

  const getPostSaleTaxPrice = (val) => {
    let res = parseFloat(checkIsNum(val, 0));
    if (selected_item_detail?.dinein_price?.sales_tax == true) {
      const resTax = (res * salesTaxPer) / 100;
      res = res + resTax;
    }
    return res;
  };

  const getBundleItemOptionsPrice = (bundleItem, currency = "") => {
    let res = null;
    if (
      bundleItem?.action_price !== null &&
      bundleItem?.action_price !== "" &&
      bundleItem?.amount !== "" &&
      bundleItem?.amount !== null
    ) {
      const bundleAmt = showPostTaxPrice
        ? getPostSaleTaxPrice(bundleItem.amount)
        : bundleItem.amount;
      res = (
        <span className="price-sm price-position">
          {parseFloat(bundleAmt) !== 0
            ? checkNegNum(bundleAmt)
              ? "-"
              : "+"
            : ""}
          {`${currency}`}
          {parseFloat(bundleAmt) < 0
            ? makeNegToPosNum(bundleAmt)
            : parseFloat(bundleAmt).toFixed(2)}
        </span>
      );
    } else if (
      bundleItem?.unit_price !== null &&
      bundleItem?.unit_price !== "" &&
      parseFloat(bundleItem?.unit_price) > 0
    ) {
      const bundleUnitPrice = showPostTaxPrice
        ? getPostSaleTaxPrice(bundleItem.unit_price)
        : bundleItem.unit_price;
      res = (
        <span className="price-sm">
          {"+"}
          {`${currency}`}
          {parseFloat(bundleUnitPrice).toFixed(2)}
        </span>
      );
    }
    return res;
  };

  const [modifierArray, setModifierArray] = useState([]);

  const getPreModifiers = () => {
    let res = [];
    if (
      edited_item_detail?.modifierArray != null ||
      edited_item_detail?.modifierArray?.length > 0
    ) {
      res = edited_item_detail?.modifierArray;
    } else if (
      selected_item_detail?.BundledOptionSeries &&
      selected_item_detail?.BundledOptionSeries?.length > 0 &&
      selected_item_detail?.recomItem?.bundle_item_ids &&
      selected_item_detail?.recomItem?.bundle_item_ids?.length > 0
    ) {
      selected_item_detail?.recomItem?.bundle_item_ids?.map((bundleItemId) => {
        const bundleItemIndex =
          selected_item_detail?.BundledOptionSeries?.findIndex((p) => {
            // console.log("catbundleItem", p?.BundledMenuItem, bundleItemId);
            return (
              p?.BundledMenuItem.findIndex(
                (q) => q.bundle_menu_item_id == bundleItemId?.menu_item_id
              ) > -1
            );
          });
        if (bundleItemIndex > -1) {
          const bundleItem =
            selected_item_detail?.BundledOptionSeries?.[bundleItemIndex];
          const bundleOptionItem = bundleItem?.BundledMenuItem?.findIndex(
            (s) => s.bundle_menu_item_id == bundleItemId?.menu_item_id
          );
          if (
            bundleOptionItem > -1 &&
            bundleItem?.BundledMenuItem?.[bundleOptionItem] &&
            bundleItem?.BundledMenuItem?.[bundleOptionItem]
              ?.PosMenuitemsModifier?.length > 0
          ) {
            bundleItem?.BundledMenuItem?.[
              bundleOptionItem
            ]?.PosMenuitemsModifier?.filter((r) => {
              // console.log("bundleItemId", r?.id, bundleItemId?.modifiers_id);
              return bundleItemId?.modifiers_id.includes(r?.id);
            })?.map((modItem) => {
              const bundleName = modItem?.modifier_name ?? "";
              const modifierType = modItem?.type ?? "";
              const modifierAction = modItem?.action ?? "";
              const modifierName = getModifierTypeName(modifierType, modItem);
              const unitPrice =
                modItem?.unit_price &&
                modItem?.unit_price !== null &&
                parseFloat(modItem?.unit_price) > 0 &&
                modItem?.unit_price !== ""
                  ? parseFloat(modItem?.unit_price)
                  : 0;
              res.push({
                id: modItem?.id,
                modifier_id: modItem?.id,
                modifier_group_id: modItem?.modifier_group_id,
                action: modifierAction,
                name: modifierName,
                unit_price: parseFloat(unitPrice.toFixed(2)),
                modifier_price: parseFloat(unitPrice.toFixed(2)),
                modifier_quantity: 1,
                menu_item_id: modItem?.menu_item_id,
              });
            });
            // console.log(
            //   "catbundleItem",
            //   bundleItem?.BundledMenuItem?.[bundleOptionItem]
            // );
          }
        }
      });
    }
    if (res.length > 0) {
      setModifierArray(res);
      setModifierChanged(!modifierChanged);
    }
    return res;
  };

  function calTotalPrice() {
    const currentQyt = currentItemQytRef.current;
    const modifierAddActionPrice =
      selectedBundleItem.length > 0
        ? selectedBundleItem
            .filter((item) => item.action_price == "add")
            .map((item) => parseFloat(item?.amount ?? 0))
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
    const modifierRemoveActionPrice =
      selectedBundleItem.length > 0
        ? selectedBundleItem
            .filter((item) => item.action_price == "remove")
            .map((item) => parseFloat(item?.amount ?? 0))
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
    const modifierAddPrice =
      modifierArray.length > 0
        ? modifierArray
            .filter((item) => item.action == "add" || item.action == "swap")
            .map((item) => item.unit_price)
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
    const modifierRemovePrice =
      modifierArray.length > 0
        ? modifierArray
            .filter((item) => item.action == "remove")
            .map((item) => item.unit_price)
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
    const modifierSizePrice =
      modifierArray.length > 0
        ? modifierArray
            .filter((item) => item.action == "size")
            .map((item) => item.unit_price)
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
    const priceWithModifier =
      selectedItemPrice * currentQyt +
      modifierAddActionPrice * currentQyt +
      modifierAddPrice * currentQyt -
      modifierRemovePrice * currentQyt -
      modifierRemoveActionPrice * currentQyt +
      modifierSizePrice * currentQyt;
    setTotalPrice(parseFloat(priceWithModifier.toFixed(2)));
    setTotalPriceWithTax(getPostSaleTaxPrice(priceWithModifier));
  }

  useEffect(() => {
    calTotalPrice();
  }, [selectedItemPrice, modifierChanged]);

  const calculateIsToolBarStatus = () => {
    let res = false;
    let maxHeight = window.outerHeight - window.innerHeight;
    if (maxHeight > 120) {
      res = true;
    }
    return res;
  };

  const [totalHeight, setTotalHeight] = useState(window.innerHeight);
  const totalHeightRef = useRef(totalHeight);
  totalHeightRef.current = totalHeight;
  const [isToolBarHide, setIsToolBarHide] = useState(
    calculateIsToolBarStatus()
  );

  useEffect(() => {
    setIsToolBarHide(calculateIsToolBarStatus());
  }, [totalHeight]);

  useEffect(() => {
    getImages(
      selected_item_detail?.content?.length > 0
        ? selected_item_detail?.content
        : selected_item_detail?.image
        ? [{ file_name: selected_item_detail?.image }]
        : []
    );
    getPreModifiers();
    setBundleItems();

    /* check expired session */
    const checkExpired = checkExpiredSession(table_detail);
    const checkInactive =
      recent_order_detail === null && !server_detail
        ? checkInactiveSession()
        : false;
    if (checkExpired || checkInactive) {
      if (server_detail?.CurrentRoom) {
        history.push(
          `/get_server_details/${server_detail.CurrentRoom.merchant_id}/${server_detail.CurrentRoom.server_code}`
        );
      } else {
        history.push("/session-expired");
      }
    }
    /* check expired session END */

    window.addEventListener("resize", function (event) {
      setTotalHeight(window.innerHeight);
    });

    return () => {
      emptyEditedItemDetail();
      window.removeEventListener("resize", (e) => {});
    };
  }, []);

  useEffect(() => {
    if (show_session_expired_page) {
      if (server_detail.CurrentRoom) {
        history.push(
          `/get_server_details/${server_detail.CurrentRoom.merchant_id}/${server_detail.CurrentRoom.server_code}`
        );
      } else {
        history.push("/session-expired");
      }
    }
  }, [show_session_expired_page]);

  // console.log("modifierArray", modifierArray);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      console.log("User Swiped!", eventData.dir);
      if (
        hideHeader == true &&
        eventData.dir == "Down" &&
        hideImages == false &&
        isHeaderAllUp === true
      ) {
        setWrapperClass("");
        setHideHeader(false);
      }
      if (hideHeader == false && eventData.dir == "Up") {
        setWrapperClass("scroll-active");
        setHideHeader(true);
      }
    },
    ...config,
  });

  function scrolledElement(element) {
    const headerPageOffset = element.target?.scrollTop;
    const headerPageScrollHeight = element.target?.scrollHeight;
    const headerPageClientHeight =
      element.target?.parentNode?.parentNode?.clientHeight;
    if (headerPageOffset === 0 && hideHeader === true) {
      setIsHeaderAllUp(true);
    }
    if (headerPageOffset > 0 && isHeaderAllUp === true) {
      setIsHeaderAllUp(false);
    }
  }

  const editItemQyt = (quantity) => {
    setCurrentItemQyt(quantity);
    setModifierChanged(!modifierChanged);
  };

  const increaseItem = () => {
    const currentQyt = currentItemQyt + 1;
    setCurrentItemQyt(currentQyt);
    setModifierChanged(!modifierChanged);
  };

  const decreaseItem = () => {
    const currentQyt = currentItemQyt > 1 ? currentItemQyt - 1 : currentItemQyt;
    setCurrentItemQyt(currentQyt);
    setModifierChanged(!modifierChanged);
  };

  function checkModifiersLength() {
    console.log("selectedBundleItem--------------------", selectedBundleItem);
    let res = false;
    if (
      selectedBundleItem?.filter((item) => {
        let filterRes = item?.modifier_availability != "4";
        if (filterRes) {
          console.log("------------------------", item?.modifier_availability);
          console.log(
            "PosMenuitemsModifier------------------------",
            item?.PosMenuitemsModifier
          );
          console.log(
            "StandardModifier------------------------",
            item?.StandardModifier
          );

          if (
            (item?.modifier_availability != "3" &&
              item?.PosMenuitemsModifier &&
              checkArray(
                item?.PosMenuitemsModifier.filter((pq) => pq.action !== "size")
              )) ||
            (item?.modifier_availability != "2" &&
              item?.StandardModifier &&
              checkArray(item?.StandardModifier))
          ) {
            filterRes = true;
          } else {
            filterRes = false;
          }
        }
        // if (
        //   selected_item_detail?.hide_fixed_items_qr == "yes" &&
        //   item?.option_series_name == "Fixed Items"
        // ) {
        //   filterRes = false;
        // }
        return filterRes;
      }).length > 0
    ) {
      res = true;
    }
    return res;
  }
  const checkExtraSpace = () => {
    // let res = false;
    if (
      (selected_item_detail?.PosMenuitemsModifier &&
        selected_item_detail?.PosMenuitemsModifier.length > 0) ||
      (selected_item_detail?.StandardModifier &&
        selected_item_detail?.StandardModifier.length)
    ) {
      return true;
    } else if (isContainDiscountArr) {
      return true;
    } else if (
      selected_item_detail?.BundledOptionSeries?.length > 0 &&
      !showModifiers
    ) {
      return true;
    }

    // else if(selected_item_detail?.allergy_tags &&
    //   selected_item_detail?.allergy_tags.length)
    //   {
    //     return true;
    //   }
    return false;
  };
  function getItemModifiers() {
    let res = null;
    if (selectedBundleItem?.length > 0) {
      res = selectedBundleItem
        ?.filter((item) => {
          let showModCon = true;
          if (!showNoteCon) {
            showModCon =
              ((item?.PosMenuitemsModifier &&
                checkArray(item?.PosMenuitemsModifier)) ||
                (item?.StandardModifier &&
                  checkArray(item?.StandardModifier))) &&
              item?.modifier_availability != "4";
          }
          // if (
          //   selected_item_detail?.hide_fixed_items_qr == "yes" &&
          //   item?.option_series_name == "Fixed Items"
          // ) {
          //   showModCon = false;
          // }
          return showModCon;
        })
        .map((element, index) => {
          console.log(element, "test111");

          return (
            <>
              {checkArray(element?.PosMenuitemsModifier) ||
              checkArray(element?.StandardModifier) ||
              (showNoteCon && element?.modifier_availability != "4") ? (
                <h5 className="sm-title tt-p tt-p-override mb-2">
                  {" "}
                  {element?.bundle_menu_item_name + " Modifiers" ?? ""}
                  {`${
                    checkArray(element?.PosMenuitemsModifier)
                      ? " Modifiers"
                      : ""
                  }`}{" "}
                </h5>
              ) : null}

              {checkArray(element?.PosMenuitemsModifier) && (
                <div className="selectors Px-15 py-0">
                  {/*
                 commented by ajay 
                {element?.PosMenuitemsModifier.findIndex(
                  (p) => p.action == "size"
                ) > -1 && <>{getItemModifierOptions(element, "size")}</>} */}
                  {element?.modifier_availability != "3" &&
                    getItemModifierOptions(element, "")}
                </div>
              )}

              {checkArray(element?.StandardModifier) && (
                <div className="selectors Px-15 py-0">
                  {/*
                 commented by ajay 
                {element?.PosMenuitemsModifier.findIndex(
                  (p) => p.action == "size"
                ) > -1 && <>{getItemModifierOptions(element, "size")}</>} */}
                  {element?.modifier_availability != "2" &&
                    getComponentStandardModifiers(element)}
                </div>
              )}
              {showNoteCon && element?.modifier_availability != "4" && (
                <>
                  <h5 className="sm-title bg-white  tt-p">Notes</h5>
                  <div className="message_col">
                    <textarea
                      id={`comment_${element.id}`}
                      name={`comment_${element.id}`}
                      className={"form-control"}
                      placeholder="Add note here."
                      type="text"
                      onChange={(el) => {
                        const itemIndex = selectedBundleItem.findIndex(
                          (p) => p.id == element.id
                        );
                        let newArr = [...selectedBundleItem]; // copying the old datas array
                        newArr[itemIndex]["comment"] = el.target.value;
                        setSelectedBundleItem(newArr);
                      }}
                    >
                      {element?.comment ?? ""}
                    </textarea>
                  </div>
                </>
              )}
            </>
          );
        });
    }
    return res;
  }

  function getComponentStandardModifiers(component) {
    console.log("component", component);
    let res = null;
    if (component?.StandardModifier && component?.StandardModifier.length > 0) {
      res = component?.StandardModifier.map((stanEl, stanIndex) => {
        return (
          <>
            <h5 className="sm-title pl-1 bg-white tt-p" key={stanIndex}>
              {stanEl?.ModifierClass?.name ?? ""}
            </h5>
            {stanEl?.ModifierClass?.Modifier?.map((element, index) => {
              const modifierId = element?.id ? `SM-${element?.id}` : "";
              const modifierType = element?.type ?? "";
              const modifierAction = stanEl?.ModifierClass?.name ?? "";
              const modifierName = element?.name ?? "";
              return (
                <div className="options" key={index}>
                  <input
                    id={`standard_com_modifier_${modifierId}`}
                    type="checkbox"
                    className="check"
                    checked={
                      modifierArray.findIndex((p) => p.id == modifierId) > -1
                    }
                    onChange={(el) => {
                      if (el?.target?.checked) {
                        const addEl = {
                          id: modifierId,
                          menu_item_id: component?.bundle_menu_item_id,
                          modifier_id: modifierId,
                          action: modifierAction,
                          name: modifierName,
                          unit_price: 0,
                          modifier_price: 0,
                          modifier_quantity: 1,
                        };
                        const existIndex = modifierArray.findIndex(
                          (p) => p.action == modifierAction
                        );
                        if (existIndex > -1) {
                          setModifierArray([
                            ...modifierArray.slice(0, existIndex),
                            addEl,
                            ...modifierArray.slice(existIndex + 1),
                          ]);
                        } else {
                          setModifierArray([...modifierArray, addEl]);
                        }
                      } else if (
                        modifierArray.findIndex((p) => p.id == modifierId) > -1
                      ) {
                        setModifierArray(
                          modifierArray.filter(
                            (modifier) => modifier.id != modifierId
                          )
                        );
                      }
                    }}
                  />
                  <label htmlFor={`standard_com_modifier_${modifierId}`}>
                    {" "}
                    {/* {getModifierActionName(modifierAction)}{" "} */}
                    {modifierName}
                  </label>
                </div>
              );
            })}
          </>
        );
      });
    }
    return res;
  }

  function getItemStandardModifiers() {
    let res = null;
    if (
      selected_item_detail?.StandardModifier &&
      selected_item_detail?.StandardModifier.length > 0
    ) {
      res = selected_item_detail?.StandardModifier.map((stanEl, stanIndex) => {
        return (
          <>
            <h5 className="sm-title pl-1 bg-white" key={stanIndex}>
              {stanEl?.ModifierClass?.name ?? ""}
            </h5>
            {stanEl?.ModifierClass?.Modifier?.map((element, index) => {
              const modifierId = element?.id ? `SM-${element?.id}` : "";
              const modifierType = element?.type ?? "";
              const modifierAction = stanEl?.ModifierClass?.name ?? "";
              const modifierName = element?.name ?? "";
              return (
                <div className="options" key={index}>
                  <input
                    id={`standard_modifier_${modifierId}`}
                    type="checkbox"
                    className="check"
                    checked={
                      modifierArray.findIndex((p) => p.id == modifierId) > -1
                    }
                    onChange={(el) => {
                      if (el?.target?.checked) {
                        const addEl = {
                          id: modifierId,
                          modifier_id: modifierId,
                          action: modifierAction,
                          name: modifierName,
                          unit_price: 0,
                          modifier_price: 0,
                          modifier_quantity: 1,
                        };
                        const existIndex = modifierArray.findIndex(
                          (p) => p.action == modifierAction
                        );
                        if (existIndex > -1) {
                          setModifierArray([
                            ...modifierArray.slice(0, existIndex),
                            addEl,
                            ...modifierArray.slice(existIndex + 1),
                          ]);
                        } else {
                          setModifierArray([...modifierArray, addEl]);
                        }
                      } else if (
                        modifierArray.findIndex((p) => p.id == modifierId) > -1
                      ) {
                        setModifierArray(
                          modifierArray.filter(
                            (modifier) => modifier.id != modifierId
                          )
                        );
                      }
                    }}
                  />
                  <label htmlFor={`standard_modifier_${modifierId}`}>
                    {" "}
                    {/* {getModifierActionName(modifierAction)}{" "} */}
                    {modifierName}
                  </label>
                </div>
              );
            })}
          </>
        );
      });
    }
    return res;
  }

  function checkModifierItemOption(element) {
    let res = false;
    if (
      modifierArray.length > 0 &&
      modifierArray.findIndex((p) => p.id == element.id) > -1
    ) {
      res = true;
    }
    return res;
  }

  function checkMustSelectedItems() {
    let res = { status: false, limit: 0, name: "" };
    if (
      selected_item_detail?.BundledOptionSeries?.length > 0 &&
      selected_item_detail?.BundledOptionSeries?.findIndex(
        (p) => p.option_series_type == ""
      ) > -1
    ) {
      const includeBundleSeries =
        selected_item_detail?.BundledOptionSeries?.filter(
          (p) => p.option_series_type == ""
        );
      for (let i = 0; i < includeBundleSeries?.length; i++) {
        const bundle = includeBundleSeries?.[i];
        const limit = parseInt(bundle?.BundledMenuItem?.length ?? 0);
        if (
          limit &&
          selectedBundleItem.filter((p) => p.option_series_id == bundle?.id)
            .length < limit
        ) {
          res = { status: true, limit: limit, name: bundle?.name };
          break;
        }
      }
    }
    if (
      selected_item_detail?.BundledOptionSeries?.length > 0 &&
      selected_item_detail?.BundledOptionSeries?.findIndex(
        (p) => p.option_series_type == "ms"
      ) > -1
    ) {
      const mustBundleSeries =
        selected_item_detail?.BundledOptionSeries?.filter(
          (p) => p.option_series_type == "ms"
        );
      for (let i = 0; i < mustBundleSeries?.length; i++) {
        const bundle = mustBundleSeries?.[i];
        const limit = parseInt(bundle?.series_total_allowed);
        if (
          selectedBundleItem.filter((p) => p.option_series_id == bundle?.id)
            .length < limit
        ) {
          res = { status: true, limit: limit, name: bundle?.name };
          break;
        }
      }
    }
    return res;
  }

  function getItemDiscountModifiers() {
    let res = null;
    if (
      selected_item_detail?.DiscountModifiers &&
      selected_item_detail?.DiscountModifiers.length > 0
    ) {
      const discountModifiersArr =
        selected_item_detail?.DiscountModifiers.filter(
          (p) => p.action == "discount"
        ).map((element, index) => {
          const modifierType = element?.type ?? "";
          const modifierAction = element?.action ?? "";
          const modifierName = getModifierTypeName(modifierType, element);
          const elUnitPrice = showPostTaxPrice
            ? getPostSaleTaxPrice(element?.unit_price)
            : element?.unit_price;

          const unitPrice =
            element?.unit_price &&
            element?.unit_price !== null &&
            element?.unit_price !== ""
              ? parseFloat(element?.unit_price)
              : 0;
          return (
            <li className="position-relative">
              <input
                type="radio"
                name="select_options"
                id={`modifier_${element?.id}`}
                className="check"
                onClick={() => {
                  const discountEl = {
                    id: element?.id,
                    modifier_id: element?.id,
                    modifier_group_id: element?.modifier_group_id,
                    action: modifierAction,
                    name: modifierName,
                    unit_price: parseFloat(unitPrice),
                    modifier_price: parseFloat(unitPrice),
                    modifier_quantity: 1,
                  };
                  const existIndex = modifierArray.findIndex(
                    (p) => p.action == "discount"
                  );
                  if (existIndex > -1) {
                    setModifierArray([
                      ...modifierArray.slice(0, existIndex),
                      discountEl,
                      ...modifierArray.slice(existIndex + 1),
                    ]);
                  } else {
                    setModifierArray([...modifierArray, discountEl]);
                  }
                  setSelectedItemPrice(parseFloat(unitPrice));
                }}
                checked={
                  modifierArray.findIndex((p) => p.id == element?.id) > -1
                }
              />
              <label htmlFor={`modifier_${element?.id}`}>
                {element?.amount_per_item} x{" "}
                {selected_item_detail?.menu_item_name}
              </label>
              <span className="price-sm price-position">
                {`${table_detail?.currency ?? ""}`}
                {parseFloat(elUnitPrice).toFixed(2)}
              </span>
            </li>
          );
        });
      res = (
        <div className="options">
          <ul className="w-100">
            <li className="mb-4 position-relative">
              <input
                type="radio"
                name="select_options"
                id={`default_options`}
                className="check"
                onClick={() => {
                  setModifierArray(
                    modifierArray.filter(
                      (modifier) => modifier.action != "discount"
                    )
                  );
                  setSelectedItemPrice(defaultSelectedItemPrice);
                }}
                checked={
                  modifierArray.findIndex((p) => p.action == "discount") < 0
                }
              />
              <label htmlFor="default_options">
                1 x {selected_item_detail?.menu_item_name}
              </label>
              <span className="price-sm price-position">
                {`${table_detail?.currency ?? ""}`}
                {parseFloat(defaultSelectedItemPrice).toFixed(2)}
              </span>
            </li>
            {discountModifiersArr}
          </ul>
        </div>
      );
    }
    return res;
  }

  function AddToCartHandler() {
    // console.log(
    //   "selected_item_detail ============================================= ",
    //   selected_item_detail
    // );
    if (selected_item_detail?.id) {
      const checkMustSelectItems = checkMustSelectedItems();

      if (checkMustSelectItems?.status === true) {
        showNotificationWithTimeout(
          `Please select atleast ${checkMustSelectItems?.limit} under "${checkMustSelectItems?.name}" section!`,
          "error"
        );
        return;
      }
      const totalLengthModifiers = checkModifiersLength();
      console.log(
        "check the position  ------------------- 111111",
        showModifiers,
        totalLengthModifiers
      );

      if (showModifiers || totalLengthModifiers === false) {
        const modifierAddActionPrice =
          selectedBundleItem.length > 0
            ? selectedBundleItem
                .filter((item) => item.action_price == "add")
                .map((item) => parseFloat(item?.amount ?? 0))
                .reduce((prev, curr) => prev + curr, 0)
            : 0;
        const modifierRemoveActionPrice =
          selectedBundleItem.length > 0
            ? selectedBundleItem
                .filter((item) => item.action_price == "remove")
                .map((item) => parseFloat(item?.amount ?? 0))
                .reduce((prev, curr) => prev + curr, 0)
            : 0;
        const modifierAddPrice =
          modifierArray.length > 0
            ? modifierArray
                .filter((item) => item.action == "add" || item.action == "swap")
                .map((item) => item.unit_price)
                .reduce((prev, curr) => prev + curr, 0)
            : 0;
        const modifierRemovePrice =
          modifierArray.length > 0
            ? modifierArray
                .filter((item) => item.action == "remove")
                .map((item) => item.unit_price)
                .reduce((prev, curr) => prev + curr, 0)
            : 0;
        const modifierSizePrice =
          modifierArray.length > 0
            ? modifierArray
                .filter((item) => item.action == "size")
                .map((item) => item.unit_price)
                .reduce((prev, curr) => prev + curr, 0)
            : 0;

        const modifersPrice =
          modifierAddActionPrice -
          modifierRemoveActionPrice +
          modifierAddPrice -
          modifierRemovePrice -
          modifierSizePrice;
        let actualItemPrice = selectedItemPrice;
        if (selected_item_detail?.promotions) {
          // total amount changes with percentage discount
          if (
            modifersPrice !== 0 &&
            selected_item_detail.promotions.type == "Percentage"
          ) {
            let discountPrice =
              ((selected_item_detail.promotions.accutalPrice + modifersPrice) *
                parseFloat(selected_item_detail.promotions.amount)) /
              100;
            actualItemPrice =
              selected_item_detail.promotions.accutalPrice - discountPrice >= 0
                ? selected_item_detail.promotions.accutalPrice - discountPrice
                : 0;
          }
        }
        const singlePriceWithModifier = actualItemPrice + modifersPrice;
        const priceWithModifier = singlePriceWithModifier * currentItemQyt;
        const serviceChargeAmount =
          selected_item_detail?.dinein_price?.service_charge == true
            ? parseFloat(
                (parseFloat(priceWithModifier ?? 0) * serviceChargePer) / 100
              )
            : 0;
        const salesTaxAmount =
          selected_item_detail?.dinein_price?.sales_tax == true
            ? parseFloat(
                ((priceWithModifier + serviceChargeAmount) * salesTaxPer) / 100
              )
            : 0;
        const BundledMenuItem = selectedBundleItem.map((item) => {
          return {
            amount: parseFloat(item?.amount ?? 0),
            amount_without_modifiers: parseFloat(actualItemPrice),
            comment: item?.comment ?? "",
            is_hold: false,
            // is_server_fired
            // viwek:"check1",
            option_series_id: parseInt(item?.option_series_id),
            bundle_menu_item_name: item?.bundle_menu_item_name,
            menu_item_pos_name: item?.menu_item_pos_name,
            void: 0,
            discount: 0,
            discountType: "",
            option_series_name: item?.option_series_name,
            id: parseInt(item?.id),
            quantity: currentItemQyt,
            perBundleQty: item?.quantity ?? "",
            bundle_hide_fixed_items: item?.bundle_hide_fixed_items ?? "no",
            category_id: item?.category_id ?? "",
            sub_category_id: item?.sub_category_id ?? "",
            is_fired:
              item?.bundle_hide_fixed_items == "yes" &&
              modifierArray.findIndex(
                (p) => p.menu_item_id == item.bundle_menu_item_id
              ) == -1
                ? true
                : false,
            fired_by:
              item?.bundle_hide_fixed_items == "yes" &&
              modifierArray.findIndex(
                (p) => p.menu_item_id == item.bundle_menu_item_id
              ) == -1
                ? "Autofire"
                : "",
            is_server_fired: server_detail ? "yes" : "no",
            // viwek:"check7",
            fired_by: server_user_detail?.nickname
              ? server_user_detail?.nickname
              : server_user_detail?.first_name,
            username: server_user_detail?.nickname
              ? server_user_detail?.nickname
              : server_user_detail?.first_name,
            firetime:
              item?.bundle_hide_fixed_items == "yes" &&
              modifierArray.findIndex(
                (p) => p.menu_item_id == item.bundle_menu_item_id
              ) == -1
                ? currDateTime
                : "",
            bundleName: selected_item_detail?.menu_item_name ?? "",
            bundle_menu_item_id: parseInt(item?.bundle_menu_item_id),
            offlineID: `${getUniTimeString()}${parseInt(item?.id)}`,
            sort: item?.sort ?? "",
            type: item?.type ?? "",
            void: 0,
            action_price: item?.action_price ?? "",
            modifier_price: item?.modifier_price
              ? parseFloat(item?.modifier_price)
              : 0,
            bundledMenuitemModifers: modifierArray.filter(
              (p) => p.menu_item_id == item.bundle_menu_item_id
            ),
          };
        });
        let doubleActualAmout = parseFloat(
          selected_item_detail?.promotions?.accutalPrice
            ? parseFloat(selected_item_detail?.promotions?.accutalPrice) +
                modifersPrice
            : parseFloat(selected_item_detail?.dinein_price?.DineIn) +
                modifersPrice ?? 0
        );
        let item = {
          id: selected_item_detail?.id,
          offline_id: getRandomString(),
          name: selected_item_detail?.pos_name,
          pos_name: selected_item_detail?.menu_item_name,
          kitchen_docket_language:
            selected_item_detail?.kitchen_docket_language,
          chinese_menu_item_name: selected_item_detail?.chinese_menu_item_name,
          default_course: selected_item_detail?.default_course || "",
          bundleName: selected_item_detail?.menu_item_name,
          bundlePosName: selected_item_detail?.pos_name,
          hide_fixed_items: selected_item_detail?.hide_fixed_items ?? "",
          hide_fixed_items_qr: selected_item_detail?.hide_fixed_items_qr ?? "",
          image: selected_item_detail?.image,
          menu_class_name: selected_item_detail?.menu_class_name ?? "",
          firetime: null,
          createDate: currDateTime,
          is_added: false,
          is_fired: false,
          is_hold: false,
          // viwek:"check2",

          // is_server_fired:"selected_item_detail?.is_server_fired123465789",

          void: 0,
          is_service_charge:
            selected_item_detail?.dinein_price?.service_charge ?? false,
          is_sales_tax: selected_item_detail?.dinein_price?.sales_tax ?? false,
          quantity: currentItemQyt,
          amount: selected_item_detail?.promotions?.accutalPrice
            ? selected_item_detail?.promotions?.accutalPrice + modifersPrice
            : singlePriceWithModifier,
          amount_without_modifiers: parseFloat(actualItemPrice),
          modifersPrice: modifersPrice,
          doubleActualAmout: doubleActualAmout,
          total: priceWithModifier,
          item_amount: actualItemPrice,
          actual_menu_price: parseFloat(
            selected_item_detail?.promotions?.accutalPrice
              ? selected_item_detail?.promotions?.accutalPrice
              : selected_item_detail?.dinein_price?.DineIn ?? 0
          ),
          menu_item_type: selected_item_detail?.menu_item_type ?? "",
          kitchen_print_preference:
            selected_item_detail?.kitchen_print_preference ?? null,
          menu_price: selected_item_detail?.menu_price ?? "",
          service_charge_amount: serviceChargeAmount,
          sales_tax_amount: salesTaxAmount,
          catID: selected_item_detail?.other_category_id ?? "",
          sub_category_id: selected_item_detail?.sub_category_id ?? "",
          sub_class_id: selected_item_detail?.sub_class_id ?? "",
          modifierArray: modifierArray.sort(function (a, b) {
            return a.id - b.id;
          }),
          BundledMenuItem: BundledMenuItem,
          selectedBundleItem: selectedBundleItem.sort(function (a, b) {
            return a.id - b.id;
          }),
        };
        if (selected_item_detail?.promotions) {
          item.promotions = selected_item_detail.promotions;
          if (modifersPrice !== 0 && item.promotions.type == "Percentage") {
            let discountPrice =
              ((item.promotions.accutalPrice + modifersPrice) *
                parseFloat(item.promotions.amount)) /
              100;
            item.promotions.promotionTotaldiscount =
              parseFloat(discountPrice) * currentItemQyt;
            item.promotions.discountPerItem = discountPrice;
          }
          if (item.promotions.accutalPrice < item.promotions.discountPerItem) {
            item.promotions.discountPerItem = item.promotions.accutalPrice;
            item.promotions.promotionTotaldiscount =
              parseFloat(item.promotions.acutalPrice) * currentItemQyt;
          }
        }
        const checkItemExistInCartKey = checkBundleItemExistInCart(
          item,
          cart_detail
        );
        if (
          (edited_item_detail !== null &&
            edited_item_detail?.offline_id !== undefined) ||
          checkItemExistInCartKey > -1
        ) {
          console.log("check the item -------------- 1");
          const existOffId =
            checkItemExistInCartKey > -1
              ? checkItemExistInCartKey
              : cart_detail?.findIndex(
                  (p) => p.offline_id == edited_item_detail.offline_id
                );
          if (existOffId > -1) {
            console.log("check the item -------------- 2");

            updateItemCartDetail(existOffId, item);
          } else {
            console.log("check the item -------------- 3");

            addItemCartDetail(item);
          }
        } else {
          console.log("check the item -------------- 4");

          addItemCartDetail(item);
        }
        getTableDetailByFilter(
          table_detail?.PosTableMaster?.qr_code_id,
          filters,
          ""
        );
        history.push(
          `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
        );
        changeModalStyle();
      } else {
        if (document.getElementById(`item-details-container`)) {
          document
            .getElementById(`item-details-container`)
            .scroll({ top: 0, behavior: "smooth" });
        }
        setShowModifiers(true);
      }
    }
    // changeModalStyle();
    return;
  }

  function getItemModifiersItems(modifers, action) {
    let res = null;
    if (modifers?.length > 0) {
      res = modifers
        .filter((p) =>
          action === "size"
            ? p.action == "size"
            : p.action == "add" || p.action == "remove" || p.action == "swap"
        )
        .map((element, index) => {
          const bundleName = element?.modifier_name ?? "";
          const modifierType = element?.type ?? "";
          const modifierAction = element?.action ?? "";
          const modifierName = getModifierTypeName(modifierType, element);
          const elUnitPrice = showPostTaxPrice
            ? getPostSaleTaxPrice(element?.unit_price)
            : element?.unit_price;

          const unitPrice =
            element?.unit_price &&
            element?.unit_price !== null &&
            parseFloat(element?.unit_price) > 0 &&
            element?.unit_price !== ""
              ? parseFloat(element?.unit_price)
              : 0;
          return (
            <div
              className="options"
              key={index}
              onClick={(el) => {
                const elArr = {
                  id: element?.id,
                  modifier_id: element?.id,
                  modifier_group_id: element?.modifier_group_id,
                  action: modifierAction,
                  name: modifierName,
                  unit_price: parseFloat(unitPrice.toFixed(2)),
                  modifier_price: parseFloat(unitPrice.toFixed(2)),
                  modifier_quantity: 1,
                  menu_item_id: element?.menu_item_id,
                };

                if (modifierArray.findIndex((p) => p.id == element?.id) > -1) {
                  setModifierArray(
                    modifierArray.filter(
                      (modifier) => modifier.id != element?.id
                    )
                  );
                  setModifierChanged(!modifierChanged);
                } else {
                  const existIndex = modifierArray.findIndex(
                    (p) => p.action == modifierAction
                  );
                  if (existIndex > -1 && modifierAction == "size") {
                    setModifierArray([
                      ...modifierArray.slice(0, existIndex),
                      elArr,
                      ...modifierArray.slice(existIndex + 1),
                    ]);
                  } else {
                    setModifierArray([...modifierArray, elArr]);
                  }
                  setModifierChanged(!modifierChanged);
                }
              }}
            >
              <input
                id={`bundle_${element.menu_item_id}`}
                name={`bundle_${element.menu_item_id}`}
                type="checkbox"
                className="check"
                checked={checkModifierItemOption(element)}
              />
              <label htmlFor={`bundle_${index}`}>
                {modifierAction === "remove" ? "" : ""} {bundleName}
              </label>

              {elUnitPrice !== null &&
                elUnitPrice !== "" &&
                (elUnitPrice > 0 || element?.action == "size") &&
                parseFloat(unitPrice) > 0 && (
                  <span className="price-sm price-position">
                    {getModifierActionSign(modifierAction)}{" "}
                    {checkNegNum(elUnitPrice) ? "- " : ""}
                    {`${table_detail?.currency ?? ""}`}
                    {parseFloat(
                      checkNegNum(elUnitPrice)
                        ? makeNegToPosNum(elUnitPrice)
                        : elUnitPrice
                    ).toFixed(2)}
                  </span>
                )}
            </div>
          );
        });
    }
    return res;
  }

  function getItemModifierOptions(bundleItem, action = "") {
    let res = null;
    if (
      bundleItem?.PosMenuitemsModifier &&
      bundleItem?.PosMenuitemsModifier.length > 0
    ) {
      const groupedModifiers = groupByArray(
        bundleItem?.PosMenuitemsModifier.sort(function (a, b) {
          return parseInt(a?.sort ?? 0) - parseInt(b?.sort ?? 0);
        }).filter((p) =>
          action === "size"
            ? p.action == "size"
            : p.action == "add" || p.action == "remove" || p.action == "swap"
        ),
        "modifier_group_id"
      );
      const groupedModifiersArr = Object.values(groupedModifiers);
      if (groupedModifiersArr) {
        res = groupedModifiersArr.map((group,index) => {
          if (group.length > 0)
            return (
              <>
                <h5 className="sm-title pl-1 pb-0 bg-white tt-p">
                  {group?.[0]?.["modifier_group_name"]}
                </h5>
                {getItemModifiersItems(group, action)}
              </>
            );
        });
      }
    }
    return res;
  }

  function checkBundleItemOption(element) {
    let res = false;
    if (
      selectedBundleItem.length > 0 &&
      selectedBundleItem.findIndex((p) => p.id == element.id) > -1 &&
      !items86Arr.includes(element?.id)
    ) {
      res = true;
    }
    return res;
  }
  function getBundleItemOptions(bundleItem) {
    let res = null;
    if (bundleItem?.BundledMenuItem && bundleItem?.BundledMenuItem.length > 0) {
      res = bundleItem?.BundledMenuItem.sort(function (a, b) {
        return parseInt(a?.sort ?? 0) - parseInt(b?.sort ?? 0);
      }).map((element, index) => {
        const bundleName = element?.bundle_menu_item_name ?? "";
        const bundleQuantity = element?.quantity
          ? parseInt(element?.quantity)
          : 1;
        const bundleType = element?.type ?? "";
        const bundleAction = element?.action ?? "";
        const checkAvailable = !items86Arr.includes(
          element?.bundle_menu_item_id
        )
          ? true
          : false;
        return (
          <div
            className={`options${checkAvailable === false ? " disabled" : ""}`}
            key={index}
            onClick={(el) => {
              if (
                bundleItem?.option_series_type == "" ||
                checkAvailable === false
              ) {
                return;
              }
              const totalCountItems = selectedBundleItem.filter(
                (p) => p.option_series_id == element.option_series_id
              ).length;
              const selectUpTO =
                bundleItem?.series_total_allowed == null
                  ? parseInt(bundleItem?.BundledMenuItem?.length ?? 1)
                  : parseInt(bundleItem?.series_total_allowed ?? 1);
              if (!checkBundleItemOption(element)) {
                if (totalCountItems == selectUpTO) {
                  const existIndex = selectedBundleItem.findIndex(
                    (p) => p.option_series_id == element.option_series_id
                  );
                  if (existIndex > -1) {
                    setSelectedBundleItem([
                      ...selectedBundleItem.slice(0, existIndex),
                      element,
                      ...selectedBundleItem.slice(existIndex + 1),
                    ]);
                  }
                } else {
                  setSelectedBundleItem([...selectedBundleItem, element]);
                }
              } else {
                setSelectedBundleItem(
                  selectedBundleItem.filter((p) => p.id != element.id)
                );
              }
              setModifierChanged(!modifierChanged);
            }}
          >
            <input
              id={`bundle_${element.option_series_id}`}
              name={`bundle_${element.option_series_id}`}
              type="checkbox"
              className={`check`}
              checked={checkBundleItemOption(element)}
            />

            <label htmlFor={`bundle_${index}`}>
              {" "}
              {getBundleItemOptionsPrice(element)}
              {bundleName} {bundleQuantity > 1 ? `x ${bundleQuantity}` : ""}
            </label>
          </div>
        );
      });
    }
    return res;
  }

  function checkMustSelectText(bundleEl) {
    let res = null;
    if (
      bundleEl?.series_total_allowed &&
      parseInt(bundleEl?.series_total_allowed) > 0
    ) {
      if (bundleEl?.option_series_type == "ms") {
        res = (
          <span className="mt-2 modifier-options">{`(Must Select ${parseInt(
            bundleEl?.series_total_allowed
          )})`}</span>
        );
      } else if (parseInt(bundleEl?.series_total_allowed) > 0) {
        res = (
          <span className="mt-2 modifier-options">{`(Select Up to ${parseInt(
            bundleEl?.series_total_allowed
          )})`}</span>
        );
      }
    }
    return res;
  }

  function getBundleItems() {
    let res = null;
    if (
      selected_item_detail?.BundledOptionSeries &&
      selected_item_detail?.BundledOptionSeries.length > 0
    ) {
      res = selected_item_detail?.BundledOptionSeries.filter(
        (p) => p.name !== "Modifier"
      )
        .sort(function (a, b) {
          return parseInt(a?.sort ?? 0) - parseInt(b?.sort ?? 0);
        })
        .map((element, index) => {
          const styleEle = {};
          if (
            selected_item_detail?.hide_fixed_items_qr == "yes" &&
            element?.name == "Includes"
          ) {
            styleEle.display = "none";
          }
          return (
            <div key={index} style={styleEle}>
              <h5 className="sm-title bg-white Px-15 tt-p">
                {" "}
                {element?.name ?? ""} {checkMustSelectText(element)}{" "}
              </h5>
              <div className="selectors Px-15 py-0">
                {getBundleItemOptions(element)}
              </div>
            </div>
          );
        });
    }
    return res;
  }

  const getModifierBundleItems = () => {
    let res = null;
    if (
      selected_item_detail?.BundledOptionSeries &&
      selected_item_detail?.BundledOptionSeries.length > 0
    ) {
      const bundleOptionArr = selected_item_detail?.BundledOptionSeries.filter(
        (p) => p.name === "Modifier"
      );
      res = bundleOptionArr
        .sort(function (a, b) {
          return parseInt(a?.sort ?? 0) - parseInt(b?.sort ?? 0);
        })
        .map((element, index) => {
          console.log("test---------",element)
          return (
            <div key={index}>
              <h5 className="sm-title bg-white pb-0 tt-p">
                {" "}
                {element?.name ?? ""} {checkMustSelectText(element)}{" "}
              </h5>
              <div
                className={`selectors Px-15 py-0${
                  index == bundleOptionArr.length - 1 ? " pb-0" : ""
                }`}
              >
                {getBundleItemOptions(element)}
              </div>
            </div>
          );
        });
    }
    return res;
  };

  const cal_discount = () => {
    const orginal = isPromotion
      ? showPostTaxPrice
        ? selected_item_detail?.promotions?.accutalPostTaxPrice
          ? parseFloat(
              selected_item_detail?.promotions?.accutalPostTaxPrice
            ).toFixed(2)
          : "0.00"
        : selected_item_detail?.promotions?.accutalPrice
        ? parseFloat(selected_item_detail?.promotions?.accutalPrice).toFixed(2)
        : "0.00"
      : showPostTaxPrice
      ? selected_item_detail?.dinein_price?.DineInPostTax
        ? parseFloat(selected_item_detail?.dinein_price?.DineInPostTax).toFixed(
            2
          )
        : "0.00"
      : selected_item_detail?.dinein_price?.DineIn
      ? parseFloat(selected_item_detail?.dinein_price?.DineIn).toFixed(2)
      : "0.00";

    const new_price = showPostTaxPrice
      ? selected_item_detail?.dinein_price?.DineInPostTax
        ? parseFloat(selected_item_detail?.dinein_price?.DineInPostTax).toFixed(
            2
          )
        : "0.00"
      : selected_item_detail?.dinein_price?.DineIn
      ? parseFloat(selected_item_detail?.dinein_price?.DineIn).toFixed(2)
      : "0.00";
    console.log("new_price/orginal;", new_price, orginal);
    console.log("new_price/orginal;", orginal - new_price / orginal);
    console.log("new_price/orginal;", orginal - new_price);
    const price_diff = orginal - new_price;
    console.log("new_price/orginal;", price_diff / orginal);

    return price_diff / orginal;
  };

  function getItemTags() {
    let res = null;
    // if (
    //   selected_item_detail?.is_popular &&
    //   selected_item_detail?.is_popular === 1 &&
    //   selected_item_detail?.is_trending &&
    //   selected_item_detail?.is_trending === 1
    // ) {
    //   res = (
    //     <span className="d-flex align-items-center">
    //       <span className="d-flex align-items-center ml-3">
    //         <StyledSVG src={fireFlame} />
    //         <span className="item-tags ml-1">Popular</span>
    //       </span>
    //       <span className="d-flex align-items-center ml-3">
    //         <StyledSVG src={fireFlame} />
    //         <span className="item-tags ml-1">Trending</span>
    //       </span>
    //     </span>
    //   );
    // } else
    if (
      selected_item_detail?.is_popular &&
      selected_item_detail?.is_popular === 1
    ) {
      res = (
        <span className="">
          <StyledSVG src={fireFlame} />
          <span className="item-tags ml-1">Popular</span>
        </span>
      );
    } else if (
      selected_item_detail?.is_trending &&
      selected_item_detail?.is_trending === 1
    ) {
      res = (
        <span>
          <StyledSVG src={trending} color={"#9A9A9A"} />
          <span className="item-tags ml-1">Trending</span>
        </span>
      );
    }
    return res;
  }
  console.log("selected_item_detail", selected_item_detail);
  function getItemFilterTags(item) {
    let res = null;
    if (item?.allergy_tags && item?.allergy_tags.length > 0) {
      res = (
        <span className="d-block">
          {item?.allergy_tags.map(function (tag, index) {
            const filterTagKey =
              filter_tag_detail && filter_tag_detail.length > 0
                ? filter_tag_detail.findIndex((p) => p.id == tag)
                : null;
            const filterTag =
              filterTagKey !== null && filterTagKey !== undefined
                ? filter_tag_detail[filterTagKey]
                : null;
            const style =
              filterTag?.color && filterTag?.color !== ""
                ? { background: filterTag?.color ?? "" }
                : {};
            const className =
              filterTag?.color && filterTag?.color !== "" ? "" : "gray";
            const fullFilterName =
              filterTag?.name && filterTag?.name !== "" ? filterTag?.name : "";
            return (
              <span
                // style={style}
                // className={className}
                className={"filterTag-menuItem"}
                key={index}
                data-tip={`${fullFilterName}`}
              >
                {fullFilterName}
                {item?.allergy_tags.length - 1 != index ? ", " : ""}
              </span>
            );
          })}
        </span>
      );
    }
    return res;
  }
  const [originalHeight, setOriginalHeight] = useState(0);

  const testing = () => {
    console.log(
      "window.scrollY--------------inside the testing function  ",
      window.scrollY
    );
  };

  useEffect(() => {
    const modalContent = document.getElementById("scrolltest"); // Replace with your actual ID
    console.log("modalContent---------", modalContent);
    modalContent.addEventListener("scroll", testing, { capture: true });
    // window.addEventListener("scroll", testing ,{capture:true});

    return () => {
      // modalContent.removeEventListener("scroll", testing,{capture:true});
      modalContent.addEventListener("scroll", testing, { capture: true });
    };
  }, []);

  useEffect(() => {
    console.log("inside the useEffect ", originalHeight);
    const headerImg = document.getElementById("header-img");
    // const headerImg = document.getElementsByClassName("img-fluid-add");
    const header = document.getElementById("header");

    if (header && headerImg) {
      // Set initial height of the image
      setOriginalHeight(header.clientHeight);

      const handleScroll = () => {
        console.log(
          "window.scrollY--------------inside the bundle-add-items Screen ",
          window.scrollY
        );

        const scrollPosition = window.scrollY;
        if (headerImg) {
          headerImg.style.transform = `scale(${
            1 + scrollPosition / originalHeight
          })`;
        }

        // Reset image size when scrolling back to the top
        if (scrollPosition === 0 && headerImg) {
          headerImg.style.transform = "scale(1)";
        }
      };

      // Add scroll event listener
      window.addEventListener("scroll", handleScroll, { capture: true });

      return () => {
        // Clean up event listener on unmount
        window.removeEventListener("scroll", handleScroll, { capture: true });
      };
    }
  }, [originalHeight]);
  console.log("outSide the useEffect----------------- ", originalHeight);

  const [isOpen, seIsOpen] = useState(false);

  // useEffect(() => {
  //   const headerImg = document.getElementById("header-img");
  //   const header = document.getElementById("header");

  //   const handleScroll = () => {
  //     console.log("window.scrollY--------------inside the bundle-add-items Screen ",window.scrollY)
  //     const scrollPosition = window.scrollY;
  //     if (headerImg) {
  //       headerImg.style.transform = `scale(${
  //         1 + scrollPosition / originalHeight
  //       })`;
  //     }

  //     // Reset image size when scrolling back to the top
  //     if (scrollPosition === 0 && headerImg) {
  //       headerImg.style.transform = "scale(1)";
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  function getAddItemDetail() {
    // console.log("selected_item_detail", selected_item_detail?.id);
    if (table_detail?.PosTableMaster && table_detail?.Merchant) {
      return (
        <div>
          <header className="add-item-header  sticky-top bg-white p-3 Px-15 ">
            <h1 className="add-items-title px-0">Basket Summary</h1>
            <div
              class={`dropdown w-100 ${
                isOpen ? "dropdown-cart  open" : "dropdown-car"
              } dropdown-cart dropdown-add `}
            >
              <button
                onClick={() => seIsOpen(!isOpen)}
                class="my-btn btn p-2 px-4 d-flex w-100 justify-content-between align-items-center dropdown-cart"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                disabled={
                  cart_detail.filter(
                    (p) =>
                      p?.is_added == false ||
                      (p.is_fired == false && p.is_fired == true)
                  ).length == 0
                }
              >
                {cart_detail
                  .filter(
                    (p) =>
                      p?.is_added == false ||
                      (p.is_fired == false && p.is_fired == true)
                  )
                  .reduce((sum, item) => sum + item.quantity, 0) +
                  "  Items in basket"}
                <StyledSVG src={downDown} className="collapse-content" />
              </button>
              <ul
                className={`dropdown-menu ${
                  isOpen ? "show" : null
                } animated-dropdown dropdown-menu-cart text-start px-2`}
              >
                {cart_detail
                  .filter(
                    (p) =>
                      p?.is_added == false ||
                      (p.is_fired == false && p.is_fired == true)
                  )
                  .map((item, index) => (
                    <li>
                      <a
                        class={
                          index != cart_detail.length - 1
                            ? "dropdown-item p-4"
                            : "dropdown-item p-4 dropdown-item-border-rm"
                        }
                      >
                        {item?.quantity + " x " + item?.name}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </header>
          <div id="scrolltest">
            <section
              id="bundleContent"
              className="add-items_sec custom_radio default-bg mb-80"
            >
              <div id="header">
                {imageArr.length ? (
                  <div className="img-section position-relative">
                    <img
                      id="header-img"
                      className="img-fluid-add d-block mx-auto"
                      src={imageArr[0]}
                      alt="Header Image"
                      // style={{transform: "scale(1)"}}
                      // style={{transform:}}
                    />
                    <div
                      onClick={() => {
                        if (!showBumdleItem) {
                          server_detail
                            ? history.push(
                                `/get_table_details/server/${table_detail?.PosTableMaster?.qr_code_id}`
                              )
                            : history.push(
                                `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
                              );
                        } else {
                          changeModalStyle();
                        }
                      }}
                      className="cross-btn"
                    >
                      {" "}
                    </div>
                  </div>
                ) : null}
              </div>

              <div
                className={`item-details height-view${
                  hideHeader === true ? "top-scroll" : ""
                }`}
              >
                <div className="p-3 Px-15 bg-white pt-0">
                  <>
                    <div className={"position-relative pt-2"}>
                      <span className="item-title mr-3">
                        {" "}
                        <span>{selected_item_detail?.menu_item_name} </span>
                        <span className="">{getItemTags()}</span>
                      </span>

                      {imageArr.length == 0 && (
                        <StyledSVG
                          style={{
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                          }}
                          onClick={() => {
                            changeModalStyle();
                          }}
                          // color={"black"}
                          src={close_black}
                          height={"20"}
                        />
                      )}
                    </div>
                  </>
                  <p className="description mt-2">
                    {selected_item_detail?.description}
                  </p>
                  <div className="mt-2 d-flex align-items-center">
                    <p
                      className={`pricing mt-0 item-promotion-price align-items-center ${
                        isPromotion ? "promotion-strike-off" : ""
                      }`}
                    >
                      {" "}
                      <small className="mr-1 fw-bolder">{`${
                        table_detail?.currency ?? ""
                      }`}</small>
                      <span>
                        {isPromotion
                          ? showPostTaxPrice
                            ? selected_item_detail?.promotions
                                ?.accutalPostTaxPrice
                              ? parseFloat(
                                  selected_item_detail?.promotions
                                    ?.accutalPostTaxPrice
                                ).toFixed(2)
                              : "0.00"
                            : selected_item_detail?.promotions?.accutalPrice
                            ? parseFloat(
                                selected_item_detail?.promotions?.accutalPrice
                              ).toFixed(2)
                            : "0.00"
                          : showPostTaxPrice
                          ? selected_item_detail?.dinein_price?.DineInPostTax
                            ? parseFloat(
                                selected_item_detail?.dinein_price
                                  ?.DineInPostTax
                              ).toFixed(2)
                            : "0.00"
                          : selected_item_detail?.dinein_price?.DineIn
                          ? parseFloat(
                              selected_item_detail?.dinein_price?.DineIn
                            ).toFixed(2)
                          : "0.00"}
                      </span>
                    </p>
                    {isPromotion && (
                      <p className="ml-2 mt-0 item-promotion-price hyperlink-color">
                        <small className="hyperlink-color">{`${
                          table_detail?.currency ?? ""
                        }`}</small>
                        <span>
                          {showPostTaxPrice
                            ? selected_item_detail?.dinein_price?.DineInPostTax
                              ? parseFloat(
                                  selected_item_detail?.dinein_price
                                    ?.DineInPostTax
                                ).toFixed(2)
                              : "0.00"
                            : selected_item_detail?.dinein_price?.DineIn
                            ? parseFloat(
                                selected_item_detail?.dinein_price?.DineIn
                              ).toFixed(2)
                            : "0.00"}
                        </span>
                      </p>
                    )}
                    {isPromotion && (
                      <span className="discount d-flex align-items-center ml-2">
                        <StyledSVG
                          className="ml-2"
                          src={badge}
                          width={13}
                          color={
                            table_detail?.QrOrdering?.brand_highlight_color ||
                            "#8b8b8b"
                          }
                        />
                        <p className="ml-2 my-0">
                          {(cal_discount() * 100).toFixed(2) + "% Discount"}
                        </p>
                      </span>
                    )}
                  </div>

                  {selected_item_detail?.allergy_tags &&
                  selected_item_detail?.allergy_tags.length ? (
                    <div className="labels  d-flex align-items-center ml-0 mt-0">
                      {<StyledSVG src={tags} width={"13px"} className="mr-2" />}
                      {getItemFilterTags(selected_item_detail)}
                    </div>
                  ) : (
                    <></>
                  )}

                  {isContainDiscountArr && getItemDiscountModifiers()}
                </div>
                <div className="mt-3 bottom-margin">
                  {selected_item_detail?.BundledOptionSeries?.length > 0 &&
                    !showModifiers &&
                    getBundleItems()}
                  {selected_item_detail?.BundledOptionSeries?.length > 0 &&
                    !showModifiers &&
                    getModifierBundleItems()}
                  {selected_item_detail?.StandardModifier !== undefined &&
                    selected_item_detail?.StandardModifier.length > 0 &&
                    !showModifiers && (
                      <Fragment>
                        <h5 className="sm-title bg-white">
                          {" "}
                          Standard Modifiers
                        </h5>
                        <div className="selectors Px-15 py-0">
                          {getItemStandardModifiers()}
                        </div>
                      </Fragment>
                    )}
                  {selected_item_detail?.BundledOptionSeries?.length > 0 &&
                    showModifiers &&
                    getItemModifiers()}
                  {checkExtraSpace() && (
                    <div className="extra-padding bg-white" />
                  )}
                </div>
              </div>
            </section>
          </div>
          <section className="bottom-sec-add-item py-3 center-btn default-bg">
            <div className="d-flex align-items-center default-bg Px-15">
              <div className="add-item-count shadow col-5 px-2 d-flex align-items-center justify-content-evenly bg-white">
                <button
                  className="remove-btn bg-white  align-items-center justify-content-center"
                  onClick={decreaseItem}
                >
                  <StyledSVG
                    color={
                      table_detail?.QrOrdering?.brand_highlight_color ||
                      "#8b8b8b"
                    }
                    src={minus}
                    width={"21px"}
                    height={"21px"}
                  />{" "}
                </button>
                <input
                  ref={qytEditInputRef}
                  type="number"
                  step={1}
                  max={999}
                  pattern="[0-9]*"
                  inputmode="numeric"
                  onBlur={() => {
                    if (isNaN(currentItemQyt) || currentItemQyt <= 0) {
                      editItemQyt(1);
                    }
                    setCurrentItemQytEdit(false);
                  }}
                  onChange={(e) => {
                    const qytVal = parseInt(
                      String(e.target.value ?? 1).slice(0, 3)
                    );
                    editItemQyt(qytVal);
                  }}
                  className="items-num bg-transparent"
                  value={currentItemQyt}
                  style={{ display: currentItemQytEdit ? "block" : "none" }}
                />
                <input
                  type="text"
                  className="items-num bg-transparent"
                  value={currentItemQyt}
                  onClick={() => {
                    setCurrentItemQytEdit(true);
                  }}
                  readOnly
                  style={{ display: currentItemQytEdit ? "none" : "block" }}
                />

                <button
                  className="add-btn d-flex   bg-white align-items-center justify-content-center"
                  onClick={increaseItem}
                >
                  <StyledSVG
                    color={
                      table_detail?.QrOrdering?.brand_highlight_color ||
                      "#8b8b8b"
                    }
                    src={plus}
                    width={"21px"}
                    height={"21px"}
                  />{" "}
                </button>
              </div>
              <div className="col-7 pl-2 pr-0">
                <CustomButton
                  onClick={() => {
                    if (
                      checkArray(pos_cart_detail?.payment_type) &&
                      pos_cart_detail?.payment_type.findIndex(
                        (p) => p.isPaid == true
                      ) > -1
                    ) {
                      showNotificationWithTimeout(
                        `Order has been split can not add items now`,
                        "error"
                      );
                    } else if (!isNaN(currentItemQyt)) AddToCartHandler();
                  }}
                  color={"white"}
                  title={"Add to Basket"}
                  className={"cart-button shadow  "}
                />
              </div>
            </div>
          </section>
        </div>
      );
    } else {
      return (
        <div className="wrapper error_container">
          <UnableToLoadData />
        </div>
      );
    }
  }

  return <>{getAddItemDetail()}</>;
}
const mapStateToProps = (state) => ({
  selected_item_detail: state.tableDetailReducer.selected_item_detail,
  items_86_detail: state.tableDetailReducer.items_86_detail,
  edited_item_detail: state.tableDetailReducer.edited_item_detail,
  table_detail: state.tableDetailReducer.table_detail,
  filters: state.tableDetailReducer.filters,
  filter_tag_detail: state.tableDetailReducer.filter_tag_detail,
  cart_detail: state.cartDetailReducer.cart_detail,
  show_complete_page: state.cartDetailReducer.show_complete_page,
  loading: state.loaderReducer.loading,
  recent_order_detail: state.cartDetailReducer.recent_order_detail,
  pos_cart_detail: state.cartDetailReducer?.pos_cart_detail,
  show_session_expired_page: state.tableDetailReducer.show_session_expired_page,
  is_payment_cleared: state.paymentDetailReducer.is_payment_cleared,
  server_detail: state.tableDetailReducer.server_detail,
  server_user_detail: state.authDetailReducer.server_user_detail,
});

const mapDispatchToProps = {
  addItemCartDetail: addItemCartDetail,
  updateItemCartDetail: updateItemCartDetail,
  removeItemCartDetail: removeItemCartDetail,
  getTableDetailByFilter: getTableDetailByFilter,
  showNotificationWithTimeout: showNotificationWithTimeout,
  emptyEditedItemDetail: emptyEditedItemDetail,
};

BundleAddItem = connect(mapStateToProps, mapDispatchToProps)(BundleAddItem);
export default BundleAddItem;

{
  /* <img
        className="background-image"
        id="header-background-img"
        alt=""
        // src={salad}
        src={table_detail?.QrOrdering?.hero_image}

      /> */
}
