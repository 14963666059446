import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

export const Terms = () => {
  return (
    <div>
      <h1>Terms of Use</h1>
      <p className="my-2">
        These terms of use (including all other terms, policies, notices,
        guidelines, and forms referenced herein or otherwise available on the
        Platform or by hyperlink) (“Terms”) govern your use of the platform and
        mobile applications provided by GoGMGo Pte. Ltd. (or referred to as
        “GoGMGo”, “we” or “us”) (collectively the “Platform”). The “Services” we
        provide or make available include (a) the Platform, and (b) the services
        provided by the Platform and/or by GoGMGo and made available through the
        Platform. Any new features added to the Services are also subject to
        these Terms. These Terms govern your use of Services provided by GoGMGo.
      </p>
      <p className="my-2">
        Please read these Terms carefully. By accessing and using the Platform,
        you agree that you have read, understood and accepted the Terms and
        consent to the processing of your personal data in accordance with the
        terms set out in the Privacy Policy. If you do not agree with the Terms,
        please do not access or use the Platform, or the Services. The Platform
        may be used by (i) natural persons who have reached 18 years of age and
        (ii) corporate legal entities, e.g companies. Where applicable, these
        Terms shall be subject to country-specific provisions as set out herein.
      </p>
      <p className="my-2">
        Users below the age of 18 must obtain consent from parent(s) or legal
        guardian(s), who by accepting these Terms shall agree to take
        responsibility for your actions and any charges associated with your use
        of the Platform and/or purchase of Goods (as defined below). If you do
        not have consent from your parent(s) or legal guardian(s), you must stop
        using/accessing the Platform immediately.
      </p>
      <p className="my-2">
        GoGMGo reserves the right to change or modify these Terms (including any
        additional terms and conditions or policies, which are published on the
        Platform and incorporated into these Terms) at any time. It is your
        responsibility to review the Terms regularly and you are strongly
        recommended to do so. You will be deemed to have agreed to the amended
        Terms (whether or not reviewed by you) by your continued use of the
        Platform following the date on which the amended Terms are posted.
      </p>
      <p className="my-2">
        All photos and images of the Goods shown on the Platform are also for
        illustrative purposes only and may differ from the actual Goods.
      </p>
      <h1>1. GoGMGo</h1>
      <p className="my-2">
        1.1 Who we are GoGMGo is a service provider for AI-Powered onsite and
        offsite technology solutions that leverage data and relevant algorithms
        for the F&B industry. We provide an engaging dining experience for
        restaurants’ customers while helping restaurants survive and thrive in
        the digital economy.
      </p>
      <p className="my-2">
        1.2 What we do Through our Platform, GoGMGo links you to vendors
        (“Vendors”) for you to order prepared meals, catering, beverages, and
        non-prepared food (hereinafter collectively referred to as (“Goods”) to
        be served and delivered to you. When you place an order for Goods from
        our Vendors (“Order”), GoGMGo acts as an agent on behalf of that Vendor
        to facilitate, process and conclude the Order and subsequently for
        either us or the Vendor to serve or deliver your Order to you. Vendors
        may be owned and operated by third party vendors, our affiliate
        companies, or us. Where the Vendors are operated by third party vendors,
        we are authorised by such Vendors to accept payment and any goods and
        services tax (“GST”) amounts (applicable to GST registered Vendors only)
        on their behalf.
      </p>
      <p className="my-2">
        1.3 How to contact us For customer support, you may reach out to us via
        the GoGMGo Help Center available on the Platform or drop us an email at
        support@gogmgo.com.
      </p>
      <h1>2. Use of the Platform</h1>
      <p className="my-2">
        2.1. In order to use the Platform and purchase an Order, depending on
        which login method you opt for, you may need to provide us with your
        mobile number or email address. You may also choose to login as a guest
        without providing your personal information by choosing the “Continue As
        Guest” login method.
      </p>
      <p className="my-2">
        Depending on the Vendor’s preference, there are times the Platform
        provides single sign on with your Facebook or Google account.
      </p>
      <p className="my-2">
        Save for cases of fraud or abuse which are not your fault, you accept
        that all Orders you placed on the Platform are your sole responsibility.
      </p>
      <p className="my-2">
        2.2. GoGMGo shall not be liable for Orders that encounter issues due to
        incomplete, incorrect or missing information provided by you. You are
        obliged to provide information that is complete, accurate and truthful
        for the proper processing of the Order, including your delivery address
        and contact information, if applicable.
      </p>
      <p className="my-2">
        2.3. Please refer to GoGMGo’s Privacy Policy if you wish to delete your
        personal data collected by GoGMGo, please send us an email requesting
        the same. We may restrict, suspend or terminate your use of the
        Platform, if we reasonably believe that you are suspected or discovered
        to have been involved in any activity or conduct that is in breach of
        these Terms, or involved in activity or conduct which we deem in our
        sole discretion to be an abuse of the Platform.
      </p>
      <h1>3. Restrictions</h1>
      <p className="my-2">
        3.1 Activities Prohibited on the Platform The following is a
        non-exhaustive list of the types of conduct that are illegal or
        prohibited on the Platform. GoGMGo reserves the right to investigate and
        take appropriate legal action against anyone who, in GoGMGo’s sole
        discretion, engages in any of the prohibited activities. Prohibited
        activities include, but are not limited to the following:
      </p>
      <p className="my-2">
        3.1.1. using the Platform for any purpose in violation of local, state,
        or federal laws or regulations, including any false, deceptive,
        misleading, or fraudulent purpose; 3.1.2. posting any content that
        infringes the intellectual property rights, privacy rights, publicity
        rights, trade secret rights, or any other rights of any party; 3.1.3.
        posting content that is unlawful, obscene, defamatory, threatening,
        harassing, abusive, slanderous, hateful, or embarrassing to any other
        person or entity as determined by GoGMGo in its sole discretion or
        pursuant to local community standards; 3.1.4. posting content that
        constitutes cyber-bullying, as determined by GoGMGo in its sole
        discretion; 3.1.5. posting content that depicts any dangerous,
        life-threatening, or otherwise risky behaviour; 3.1.6. posting telephone
        numbers, street addresses, or last names of any person; 3.1.7. posting
        URLs to external websites or any form of HTML or programming code;
        3.1.8. posting anything that may be “spam,” as determined by GoGMGo in
        its sole discretion; 3.1.9. impersonating another person when posting
        content using the Platform; 3.1.10. harvesting or otherwise collecting
        information about others, including email addresses, without their
        consent; 3.1.11. allowing any other person or entity to use your
        identification for posting or viewing comments; 3.1.12. harassing,
        threatening, stalking, or abusing any person on the Platform; 3.1.13.
        engaging in any other conduct that restricts or inhibits any other
        person from using or enjoying the Platform, or which, in the sole
        discretion of GoGMGo, exposesGoGMGo or any of its customers, suppliers,
        or any other parties to any liability or detriment of any type; or
        3.1.14. encouraging other people to engage in any prohibited activities
        as described herein. 3.2 GoGMGo reserves the right but is not obligated
        to do any or all of the following: 3.2.1. investigate an allegation that
        any violation of the Platform does not conform to these Terms; 3.2.2.
        remove content which is abusive, illegal, or disruptive, or that
        otherwise fails to conform with these Terms; 3.2.3. suspend or terminate
        a user’s access to the Platform upon any breach of these Terms; 3.2.4.
        monitor, edit, or disclose any content on the Platform; and 3.2.5. edit
        or delete any content posted on the Platform, regardless of whether such
        content violates these standards.
      </p>
      <h1>4. Intellectual Property</h1>
      <p className="my-2">
        All trademarks, logos, images, and service marks, including these Terms
        as displayed on the Platform or in our marketing material, whether
        registered or unregistered, are the intellectual property of GoGMGo
        and/or third parties who have authorised us with the use (collectively
        the “Trademarks”). You may not use, copy, reproduce, republish, upload,
        post, transmit, distribute, or modify these Trademarks in any way
        without our prior express written consent. The use of the Trademarks on
        any other website not approved by us is strictly prohibited. GoGMGo will
        aggressively enforce its intellectual property rights to the fullest
        extent of the law, including criminal prosecution. GoGMGo neither
        warrants nor represents that your use of materials displayed on the
        Platform will not infringe rights of third parties not owned by or
        affiliated with GoGMGo. Use of any materials on the Platform is at your
        own risk.
      </p>
      <h1>5. Restrictions on Goods</h1>
      <p className="my-2">
        5.1. Some of the Goods offer on the Platform are subject to restrictions
        for purchase (“Restricted Goods”), depending on the applicable laws of
        the country you purchase the Restricted Goods from. These restrictions
        include minimum age requirements for alcohol/alcoholic products and any
        other goods that we reserve the right not to serve or deliver to you
        based on the relevant statutory requirements of the time being in force.
        By placing an order for Restricted Goods, you confirm that you meet the
        legal requirements to purchase them. 5.2. Alcohol / Alcoholic Products
        (“Alcohol”)To purchase Alcohol, you must be of the statutory legal age.
        GoGMGo, the Vendor and their delivery riders, as the case may be,
        reserve the right in their sole discretion:
      </p>
      <p className="my-2">
        5.2.1. to ask for valid proof of age (e.g. ID card) to any persons
        before they serve or deliver Alcohol; 5.2.2. to refuse serving or
        delivery if you are unable to prove you are of legal age; and/or 5.2.3.
        to refuse serving or delivery to any persons for any reason whatsoever.
      </p>
      <h1>6. Orders</h1>
      <p className="my-2">
        6.1. When you place an Order on the Platform offsite from the Vendor’s
        location, GoGMGo will confirm your Order by sending you a confirmation
        email containing the Order receipt. Where applicable, Orders will
        include delivery fees and any applicable tax (e.g. GST etc.).
      </p>
      <p className="my-2">
        6.2. Minimum Order Value – Some of our Vendors require a minimum order
        value (“MOV”) before an Order can be placed and delivered to you. Where
        an applicable Order fails to meet the MOV, you will have the option of
        paying the difference to meet the MOV or to add more Goods to your
        Order.
      </p>
      <p className="my-2">
        6.3. Special Instructions – GoGMGo and the Vendor (as the case may be)
        reasonably endeavour to comply with your special instructions for an
        Order. However, in some cases where this is not feasible, possible or
        commercially reasonable, GoGMGo and/or the Vendor reserve the right to
        proceed to prepare the Order in accordance with standard operating
        procedures. Neither GoGMGo nor the Vendor shall be responsible to
        replace or refund an Order which does not conform to special
        instructions provided by you.
      </p>
      <p className="my-2">
        6.4. Allergens –GoGMGo is not obligated to provide ingredient
        information or allergen information on the Platform. Further, GoGMGo
        does not guarantee that the Goods sold by Vendors are free of allergens,
        unless otherwise required by applicable laws. If you have allergies,
        allergic reactions or dietary restrictions and requirements, please
        contact the Vendor before placing an Order on the Platform.
      </p>
      <p className="my-2">
        6.5. Please note that your Order may be subject to additional terms and
        conditions provided by the Vendor.
      </p>
      <p className="my-2">
        6.6. For delivery services: Prior to placing the Order, you are required
        to provide the delivery address in order for the Platform to display the
        Vendors available in your delivery area.
      </p>
      <p className="my-2">
        6.7. Placing the Order To complete an Order, please follow the onscreen
        instructions after clicking ‘Checkout’. You may be required to provide
        additional details for us to complete your Order. You are required to
        review and confirm that all the information you provide, including the
        amounts, delivery details, personal details, payment information, and
        voucher codes (if applicable) is true, accurate and complete before you
        click“PLACE ORDER”. An Order is successfully placed when you receive an
        email confirmation containing your Order receipt from us.
      </p>
      <p className="my-2">
        6.8. Cancelling an Order By placing an Order on the Platform, you accept
        and agree to the relevant cancellation policy of the Vendor and to any
        additional terms and conditions of the Vendor that may apply to you.
        Please contact the Vendor directly if you wish to cancel your Order
        after it has been placed. It is at the Vendor’s discretion as to whether
        they will accept an Order cancellation.
      </p>
      <p className="my-2">
        The Vendor may cancel an Order at any time. The Vendor may, for example,
        reject an Order if they are too busy or the Goods you have ordered are
        unavailable.
      </p>
      <p className="my-2">
        6.9. Refunds By accepting the Goods provided by the Vendor on the
        Platform, you accept and agree that the Vendor’s relevant refund policy
        may apply to you. Please check the terms and conditions of a Vendor’s
        service (if any) thoroughly for any such conditions prior to placing an
        Order or making a purchase through the Platform.
      </p>
      <p className="my-2">
        Where you request for a refund (Refund Request), the approval of the
        Refund Request will be determined by the Vendor in accordance with the
        Vendor’s cancellation and refunds policy.
      </p>
      <p className="my-2">
        You acknowledge and agree that we have no control of any Vendor’s
        cancellation and refunds policy and that we will not be responsible for
        any result or consequence of a request for a cancellation or refund from
        the Vendor.
      </p>
      <p className="my-2">
        Where there is a dispute in relation to the application of the Vendor’s
        cancellation or refund policy, we may intervene and assist in resolving
        any such dispute upon being notified by you or the Vendor. We may, at
        our discretion, implement a cancellation and refund policy and, by
        continuing the use the Platform after receiving notice of a cancellation
        and refund policy, you agree to be bound by any such policy. In the
        event of any inconsistency between our policy and a Provider’s policy,
        our policy will prevail. We may require you to provide additional
        information prior to processing any refund. 6.10. GoGMGo reserves the
        right to cancel any Order and/or suspend, deactivate or terminate your
        use of the Platform in its sole discretion if it reasonably suspects or
        detects fraudulent behaviour or activity associated with your Order.
      </p>
      <h1>7. Prices and Payments</h1>
      <p className="my-2">
        7.1. Prices and additional charges (including but not limited to
        delivery, small order or service fees) indicated on the Platform are as
        at the time of each Order and may be subject to change.
      </p>
      <p className="my-2">
        7.2. A breakdown of the prices and additional charges (including but not
        limited to delivery, small order or service fees) are displayed before
        checkout. When you place an Order, you agree to all amounts, additional
        charges and the final ‘Total’ amount which is displayed to you.
      </p>
      <p className="my-2">
        7.3. You can choose to pay for an Order using any of the different
        payment methods offered on the Platform including:
      </p>
      <p className="my-2">
        7.3.1. Our payment partners: Visa, Mastercard, American Express, Google
        Pay, PayPal, Apple Pay; 7.3.2. Cash-on-Delivery; or 7.3.3. Such other
        payment method we offer from time to time.
      </p>
      <p className="my-2">
        7.4. After an Order is successfully placed, you will see a confirmation
        page display along with your Order receipt.
      </p>
      <p className="my-2">
        7.5. Payment Methods GoGMGo reserves the right to offer additional
        payment methods and/or remove existing payment methods at any time in
        its sole discretion. If you choose to pay using an online payment
        method, the payment shall be processed by our third party payment
        service provider(s). With your consent, your credit card / payment
        information will be stored with our third party payment service
        provider(s) for future Orders. GoGMGo does not store your credit card or
        payment information.
      </p>
      <p className="my-2">
        7.6. You must ensure that you have sufficient funds on your credit and
        debit card to fulfill payment of an Order.
      </p>
      <h1>8. Delivery, Pick-Up and Vendor Delivery</h1>
      <p className="my-2">
        8.1 Delivery Time The Vendor or GoGMGo (as the case may be) shall
        deliver your Order to the delivery address provided by you. You may
        choose for your Order to be delivered “ASAP” or scheduled for a specific
        time. An estimated delivery time will be provided to you in your email
        confirmation but delivery times shall vary depending on factors that are
        not controlled by us (e.g. order quantity, distance, time of day (peak
        periods), weather conditions, traffic conditions, etc.). You can view
        the remaining delivery time of an Order when you click on ‘My Orders’ on
        the Platform. You acknowledge that the delivery time we provide is only
        an estimate and Orders may arrive earlier or later. To ensure that you
        do not miss a delivery of an Order, you should ensure that either you or
        someone is at the delivery location to receive the Order once an Order
        is placed. If your Order contains Alcohol (if applicable) and you or the
        recipient is or appears to be below the legal age, or fails to provide a
        valid proof of ID, the Vendor or GoGMGo reserves the right not to
        deliver your Order to you.
      </p>
      <p className="my-2">
        8.2 Unsuccessful or Failed Deliveries 8.2.1 Without prejudice to any
        other clauses in these Terms, in cases where we attempt to deliver an
        Order but we are unable to do so due to the reasons caused by you,
        including but not limited to: (i) no one was present or available to
        receive the Order; (ii) customer was uncontactable despite attempts to
        reach the customer via the phone number provided; (iii) lack of
        appropriate or sufficient access to deliver the Order successfully; (iv)
        lack of a suitable or secure location to leave the Order; or (v) in the
        case of Restricted Goods, customer did not meet the statutory age
        requirements or delivery did not deem it safe or appropriate for the
        customer to receive the Restricted Goods, we will contact you via, among
        others, call or message, or email, to inform the unsuccessful delivery
        and next steps. In such cases, GoGMGo reserves the right to cancel the
        Order without refund or remedy to you. 8.2.2. No-show Cancellations If
        you remain uncontactable or fail to receive the Order within ten (10)
        minutes from the time the Order arrives at your delivery address, the
        Vendor reserves the right to cancel the Order without refund or remedy
        to you.
      </p>
      <p className="my-2">
        8.3. Wrong Order, Missing Items, Defective Goods Upon receipt of your
        Order, if you discover that there are issues with your Order (e.g. wrong
        order, defective order, or missing items) please immediately contact the
        Vendor.In some cases, GoGMGo may request for photographic proof and/or
        additional information to properly investigate the issue with your
        Order. If we determine that the Order and/or Goods you received are not
        of satisfactory condition or quality, we will compensate you for your
        Order or parts of your Order.
      </p>
      <p className="my-2">
        8.4. Pick-Up 8.4.1. Where available, you will have the option of
        collecting your Order in-person directly from the Vendor’s premises.
        Your confirmation page will indicate the time for you to Pick-Up the
        Order (“Collection Time”). The Vendor will prepare the Order by the
        Collection Time. In some cases, a reasonable delay may be expected. The
        Vendor agrees to hold the Order for you at the Vendor’s premises for no
        more than a reasonable period of twenty (20) minutes from the Collection
        Time (“Holding Time”) and shall not be obliged to provide the Order to
        you if you fail to Pick-Up your Order within the Holding Time. 8.4.2. In
        the event of unreasonable delays in Pick-Up attributable to you, you
        bear the risk of any damage or loss of Goods or any deterioration in
        quality or change in condition of the Goods (e.g. changes in the
        temperature fit for consumption). In this case, you shall not be
        entitled to a replacement, refund or replacement of the Goods. You alone
        are responsible for inspecting the Goods/Order when you Pick-Up your
        Order and shall report any issues and/or defects to the Vendor before
        leaving the Vendor’s premises.
      </p>
      <h1>9. Vouchers, Discounts and Promotions</h1>
      <p className="my-2">
        9.1. From time to time, GoGMGo may run marketing and promotional
        campaigns which offer voucher codes, discounts, and other promotional
        offers to be used on the Platform (“Vouchers”). Vouchers are subject to
        validity periods, redemption periods, use restrictions, limits and/or
        other availability. 9.2. Vouchers may not be valid when used in
        conjunction with other promotions, discounts or other vouchers.
        Additional terms and conditions (including vendor-specific terms) may
        apply to Vouchers. 9.3. Unless otherwise stated, Vouchers can only be
        used on our Platform. 9.4. Vouchers are non-transferable,
        non-exchangeable, non-refundable and cannot be exchanged for cash. 9.5.
        GoGMGo may withdraw, amend and/or alter any applicable terms and
        conditions of the Vouchers at any time without prior notice. GoGMGo
        further reserves the right to void, discontinue or reject the use of any
        Voucher without prior notice. 9.6. GoGMGo may at its sole and absolute
        discretion forfeit, exclude, disqualify or refuse you or certain Vendors
        from the use of Vouchers at any time without prior notice.
      </p>
      <h1>10. Representations, Warranties and Limitation of Liabilities</h1>
      <p className="my-2">
        10.1. Representations and Warranties You acknowledge and agree that the
        content on the Platform is provided on an “as is” and “as available”
        basis, and that your use of or reliance upon the Platform and any
        content, goods, products or services accessed or obtained thereby is at
        your sole risk and discretion.
      </p>
      <p className="my-2">
        All photos and images of the Goods are also for illustrative purposes
        only and may differ from the actual Goods.
      </p>
      <p className="my-2">
        While GoGMGo makes reasonable efforts to ensure the provision of the
        Platform and the Services we offer, are available at all times, we do
        not warrant or represent that the Platform shall be provided in a manner
        which is secure, timely, uninterrupted, error- free, free of technical
        difficulties, defects or viruses. Please expect temporary interruptions
        of the Platform due to scheduled or regular system maintenance work,
        downtimes attributable to internet or electronic communications or
        events of force majeure.
      </p>
      <p className="my-2">
        10.2. Limitation of Liability To the extent permitted by law, GoGMGo
        (which shall include its employees, directors, agents, representatives,
        affiliates and parent company) exclude all liability (whether arising in
        contract, in negligence or otherwise) for loss or damage which you or
        any third party may incur in connection with our Platform, our Services,
        and any website linked to our Platform and any content or material
        posted on it (if any). Your exclusive remedy with respect to your use of
        the Platform is to discontinue your use of the Platform. The GoGMGo
        entities, their agents, representatives, and service providers shall not
        be liable for any indirect, special, incidental, consequential, or
        exemplary damages arising from your use of the Platform or for any other
        claim related in any way to your use of the Platform. These exclusions
        for indirect, special, consequential, and exemplary damages include,
        without limitation, damages for lost profits, lost data, loss of
        goodwill, work stoppage, computer failure, or malfunction, or any other
        commercial damages or losses, even if the GoGMGo entities, their agents,
        representatives, and service providers have been advised of the
        possibility thereof and regardless of the legal or equitable theory upon
        which the claim is based. Because some states or jurisdictions do not
        allow the exclusion or the limitation of liability for consequential or
        incidental damages, in such states or jurisdictions, GoGMGo, the GoGMGo
        entities, its agents, representatives and service providers’ liability
        shall be limited to the extent permitted by law.
      </p>
      <p className="my-2">
        10.3. Vendor’s representations GoGMGo shall neither be liable for
        actions or omissions of the Vendor nor you in regard to provision of the
        Goods. GoGMGo does not assume any liability for the quantity, quality,
        condition or other representations of the Goods and/or services provided
        by Vendors or guarantee the accuracy or completeness of the information
        (including menu information, photographs and images of the Goods)
        displayed on the Vendor’s listing/offering on the Platform. Nothing in
        these Terms shall exclude Vendor’s liability for death or personal
        injury arising from Vendor’s gross negligence or willful misconduct.
      </p>
      <h1>11. Vendor Liability</h1>
      <p className="my-2">
        Vendors are solely responsible for the preparation, condition and
        quality of Goods sold to you. In cases of Vendor Delivery, Vendors are
        responsible for delivery of the Goods and/or Orders. GoGMGo shall not be
        responsible or liable for any loss, damage, expense, cost or fees
        arising from your contractual relationship with the Vendor.
      </p>
      <h1>12. Personal Data (Personal Information) Protection</h1>
      <p className="my-2">
        You agree and consent to GoGMGo and any of its affiliate companies
        collecting, using, processing and disclosing your Personal Data in
        accordance with these Terms and as further described in our Privacy
        Policy. Our Privacy Policy is incorporated by reference herein and
        accessible here (link for privacy policy) or via the links on our
        Platform.
      </p>
      <h1>13. Indemnity</h1>

      <p className="my-2">
        You agree to indemnify us for all reasonable losses, damages,
        liabilities, claims and expenses (including reasonable legal costs)
        incurred by us arising out of or in connection with your use of the
        Platform, your breach of the Terms or any rights of third parties,
        except to the extent we directly caused or contributed to the loss,
        damage, liability, claim or expense. We reserve the right, at our own
        expense, to assume the exclusive control of any matter otherwise subject
        to indemnification by you, and in such case, you agree to cooperate with
        our defence of such claim.
      </p>
      <h1>14. Third Party Links and Websites</h1>

      <p className="my-2">
        The Platform may contain links to other third party products, websites,
        services and offers and by clicking on these third party links, you
        agree to do so at your own risk. These third party links, products,
        websites and services not owned or controlled by us.We do not control or
        endorse these third party websites or links and shall not be responsible
        for the content, functionality, security, services, privacy policies, or
        other practices of these third party linked pages. We accept no
        liability or responsibility for any loss or damage which may be suffered
        by you in relation to your access and use of these third party links and
        websites.
      </p>
      <h1>15. Termination</h1>
      <p className="my-2">
        GoGMGo has the right to terminate, suspend or delete your access to or
        use of the Platform, including any services we provide to you in respect
        of an Order, for any reason, including, without limitation, if GoGMGo in
        its sole discretion: 1. considers your use to be unacceptable; 2. is not
        satisfied with the results of the Background Checks, conducted on an
        ongoing basis; or 3. suspects or discovers you to have been involved in
        any activity or conduct that is in breach of any of the Terms. GoGMGo
        may, but shall be under no obligation to, provide you a warning prior to
        termination of your use of the Platform.
      </p>
      <h1>16. Amendments</h1>
      <p className="my-2">
        GoGMGo may amend these Terms at any time in its sole discretion, and the
        amended Terms shall be effective immediately upon posting, subject to
        applicable laws. It is your responsibility to check the Terms regularly.
        If you do not agree with the amended Terms, whether in whole or in part,
        you must stop using the Platform immediately.
      </p>
      <h1>17. Severability</h1>
      <p className="my-2">
        If any provision of these Terms is found to be invalid by any court
        having competent jurisdiction, the invalidity of such provision shall
        not affect the validity of the remaining provisions of these Terms,
        which shall remain in full force and effect. No waiver of any provision
        in these Terms shall be deemed a further or continuing waiver of such
        provision or any other provision.
      </p>
      <h1>18. Governing Law</h1>

      <p className="my-2">
        These Terms shall be governed by and construed in accordance with the
        laws of the country or state in which GoGMGo entity that is party to the
        Terms is domiciled, without regard to choice of law principles. You
        agree that any legal actions or proceedings arising out of or in
        connection with these Terms shall be submitted to the exclusive
        jurisdiction of the courts of the country in which the GoGMGo entity
        that is party to the Terms is domiciled.
      </p>
      <h1>19. Prevailing Language</h1>

      <p className="my-2">
        In the event of a dispute as to the Terms, the English version shall
        prevail. The English language version of these Terms shall control in
        all respects and shall prevail in case of any inconsistencies with
        translated versions.
      </p>
    </div>
  );
};
