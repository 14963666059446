import React, { useState } from "react";

const CartDropDown = ({ data }) => {
    const [open,setOpen] = useState(false)
//   console.log(data, "data inside the custom Drop Down");
  return (
    <div 
    // class="dropdown w-100  dropdown-add"
    className="cartDropDownView"
     >
      <button
      onClick={()=>{setOpen(!open)}}
        disabled={data.length == 0}
        // class="btn p-2 px-4 d-flex w-100 justify-content-between align-items-center  dropdown-toggle dropdown-cart"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {data.reduce((sum, item) => sum + item.quantity, 0) +
          "  Items in basket"}
      </button>
      {open&&<ul 
    //   class="dropdown-menu dropdown-menu-cart text-start px-2 "
      >
        {data.map((item, index) => (
          <li>
            <a
              class={
                index != data.length - 1
                  ? "dropdown-item p-4"
                  : "dropdown-item p-4 border-0"
              }
            >
              {item?.quantity + " x " + item?.name}
            </a>
          </li>
        ))}
      </ul>}
    </div>
  );
};

export default CartDropDown;
