import React, { useRef, forwardRef } from "react";
import { userIcon } from "../../../Assets";
import QrReader from "react-qr-reader";

const QrInput = forwardRef((props, ref) => {
  const qrInputRef = useRef("qrInput");

  const openImageDialog = () => {
    console.log("ref=>", qrInputRef.current.openImageDialog());
    // this.refs.qrReader1.openImageDialog();
  };
  return (
    <>
      <QrReader
        ref={qrInputRef}
        delay={300}
        onError={props.handleError}
        onScan={props.handleScan}
        legacyMode
      />
      <input
        type="button"
        ref={ref}
        {...props}
        value="Submit QR Code"
        onClick={openImageDialog}
      />
    </>
  );
});

export default QrInput;
