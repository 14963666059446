import React, { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { checkArray, checkVoucherAvailable, pluckArray } from "../../helpers";
import {
  addPromocodeDetail,
  emptyPromocodeDetail,
  getOrderHistoryDetail,
  showNotificationWithTimeout,
} from "../../Redux/actions";
import ImageTag from "./ImageTag";

const PromocodePopup = ({ show, onCancel, tabType }) => {
  const dispatch = useDispatch();
  const defaultActiveTab = tabType !== undefined ? tabType : 0;

  const { table_detail } = useSelector((state) => state.tableDetailReducer);
  const { cart_detail, applied_promocode } = useSelector(
    (state) => state.cartDetailReducer
  );
  const merchantId = table_detail?.Merchant?.id;

  const [promoInput, setPromoInput] = useState(applied_promocode?.code ?? "");

  // console.log("table_detail", table_detail);

  useEffect(() => {
    if (document.getElementById(`root`)) {
      if (show) {
        document.getElementById(`root`).classList.add("modal_active");
      } else {
        document.getElementById(`root`).classList.remove("modal_active");
      }
    }
  }, [show]);

  const findValidPromoIndex = () =>
    table_detail?.Promotions.filter((p) => p?.auto_apply !== "Yes").findIndex(
      (promo) => {
        let res = false;
        if (
          promo?.code == promoInput &&
          checkVoucherAvailable(
            promo?.datetime,
            table_detail,
            table_detail?.Merchant?.timezone_offset ?? "00:00"
          )
        ) {
          res = true;
        }
        return res;
      }
    );

  const checkAppliedPromo = () => {
    if (
      checkArray(table_detail?.Promotions) &&
      checkArray(
        table_detail?.Promotions.filter((p) => p?.auto_apply !== "Yes")
      ) &&
      findValidPromoIndex() > -1
    ) {
      const validPromo = table_detail?.Promotions.filter(
        (p) => p?.auto_apply !== "Yes"
      )?.[findValidPromoIndex()];
      if (validPromo.qualification_type == "order") {
        dispatch(
          showNotificationWithTimeout(
            `${validPromo?.promotion_name ?? ""} successfully applied`,
            "success"
          )
        );
        
        dispatch(addPromocodeDetail(validPromo));
        closePopup();
      } else {
        let isFoundItem = false;
        if (checkArray(validPromo.MenuItem)) {
          const menuItemIds = pluckArray(validPromo.MenuItem, "id");
          let newCart = [...cart_detail];
          newCart = newCart.map((promoItem) => {
            if (
              promoItem?.is_added === false &&
              promoItem?.promotions === undefined &&
              promoItem.id.includes(menuItemIds)
            ) {
              isFoundItem = true;
              const accutalPrice = parseFloat(
                promoItem?.actual_menu_price ?? 0
              );
              let promoPrice = 0;
              let discountPrice = 0;
              if (validPromo.type == "Amount") {
                discountPrice = parseFloat(validPromo.amount);
                promoPrice = accutalPrice - discountPrice;
              } else if (validPromo.type == "Percentage") {
                discountPrice =
                  (accutalPrice * parseFloat(validPromo.amount)) / 100;
                promoPrice = accutalPrice - discountPrice;
              } else if (validPromo.type == "Re-Price") {
                discountPrice = parseFloat(validPromo.amount);
                promoPrice = discountPrice;
              }
              promoItem.promotions = {
                id: validPromo.id,
                type: validPromo.type,
                code: validPromo.code,
                name: validPromo.promotion_name,
                auto_apply: validPromo.auto_apply,
                accutalPrice: accutalPrice,
                promotionTotaldiscount: discountPrice,
                amount: validPromo.amount,
                discountPerItem: parseFloat(discountPrice),
                combo_allowed: validPromo.combo_allowed,
                isLoyaltyVoucher:
                  validPromo?.loyalty_only_promotion == "1" ? "yes" : "no",
                voucherName: "",
              };
              if (promoItem.menu_item_type == "bundled") {
                promoItem.actual_menu_price = promoPrice;
                promoItem.amount = promoPrice;
              }
            }
            return promoItem;
          });
          console.log("checkItmes", newCart);
        }
        if (isFoundItem) {
          dispatch(
            showNotificationWithTimeout(
              `${validPromo?.promotion_name ?? ""} successfully applied`,
              "success"
            )
          );
          dispatch(addPromocodeDetail(validPromo));
          closePopup();
        } else {
          dispatch(
            showNotificationWithTimeout(`Please add valid items!`, "error")
          );
          dispatch(emptyPromocodeDetail());
        }
      }
    } else {
      dispatch(showNotificationWithTimeout(`Invalid code!`, "error"));
      dispatch(emptyPromocodeDetail());
    }
  };

  const closePopup = () => {
    // setPromoInput("");
    onCancel();
  };

  return (
    <div className={`promoModal loyality_ms ${show ? "show" : ""}`}>
      <section className="varification-sec">
        <a href="#" className="cross-icon" onClick={() => closePopup()}></a>
        <div className="row mx-0 px-3 justify-content-start">
          <div className="naming-sec col-12 px-0">
            <h4>Apply Promotion</h4>
            <p className="mt-2 md-txt h5">
              {" "}
              Please provide the valid promotion code and submit.
            </p>
          </div>
        </div>
        <div className="row mx-3 justify-content-end">
          <input
            type="text"
            className="input-text mt-5 mb-3"
            placeholder="Promotion Code"
            value={promoInput}
            onChange={(e) => setPromoInput(e?.target?.value || "")}
          />
          <button
            type="button"
            className="btn green-btn my-3"
            onClick={() => {
              if (promoInput !== "") {
                checkAppliedPromo();
              } else {
                dispatch(emptyPromocodeDetail());
              }
            }}
          >
            Apply
          </button>
        </div>
      </section>
    </div>
  );
};

export default PromocodePopup;
