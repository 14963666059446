import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCartNewItemCount } from "../../helpers";
import { StyledSVG } from "../../Utils/style";
import { back, cart, left_arrow_green } from "../../Assets";

function CartHeader({ backTitle, rtComponent, backPath, onTabProfile, style }) {
  const history = useHistory();
  const cart_detail = useSelector(
    (state) => state.cartDetailReducer?.cart_detail
  );
  const table_detail = useSelector(
    (state) => state.tableDetailReducer?.table_detail
  );
  const loyaltyEnabled = table_detail?.Merchant?.loyalty_enabled;
  const cartCount = getCartNewItemCount(cart_detail);
  return (
    <header style={style}>
      <div className="container bg-white Px-15 pb-2">
        <div className="row">
          <div onClick={() => backPath()} className="col-6 d-flex align-items-center back-padding   ">
            <StyledSVG
                color={
                  table_detail?.QrOrdering?.brand_main_color || "#8b8b8b"
                }
                src={back}
                width="20px"
                style={{}}

              />{" "}
            <a
              href="#"
              className="back store-color"
              onClick={() => backPath()}
              // style={{ color: table_detail?.QrOrdering?.brand_color }}
            >
              
              {backTitle}{" "}
            </a>
          </div>
          
        </div>
        {rtComponent ? rtComponent : null}
      </div>
    </header>
  );
}

export default CartHeader;
