export const LOADING_STATUS = "LOADING_STATUS";
export const START_NEW_SESSION = "START_NEW_SESSION";


// USER_DETAIL

export const UPDATE_AUTHTOKEN = "UPDATE_AUTHTOKEN";
export const USER_INITIAL_STATE = "USER_INITIAL_STATE";
export const GET_USER_DETAIL = "GET_USER_DETAIL";
export const EMPTY_USER_DETAIL = "EMPTY_USER_DETAIL";
export const USER_DETAIL_RECEIVED = "USER_DETAIL_RECEIVED";
export const EMAIL_INPUT_RECEIVED = "EMAIL_INPUT_RECEIVED";
export const MEMBER_ID_INPUT_RECEIVED = "MEMBER_ID_INPUT_RECEIVED";
export const EXPIRATION_AUTHTOKEN_TIME = "EXPIRATION_AUTHTOKEN_TIME";




export const OTP_INPUT_RECEIVED = "OTP_INPUT_RECEIVED";
export const SHOW_OTP_INPUT_TOGGLE = "SHOW_OTP_INPUT_TOGGLE";
export const SHOW_PHONE_PIN_INPUT_TOGGLE = "SHOW_PHONE_PIN_INPUT_TOGGLE";
export const SHOW_PHONE_AGREEMENT_INPUT_TOGGLE =
  "SHOW_PHONE_AGREEMENT_INPUT_TOGGLE";
export const PHONE_AGREEMENT_DETAIL_RECEIVED =
  "PHONE_AGREEMENT_DETAIL_RECEIVED";
export const PHONE_AGREEMENT_INPUT_RECEIVED = "PHONE_AGREEMENT_INPUT_RECEIVED";
export const PHONE_PIN_INPUT_RECEIVED = "PHONE_PIN_INPUT_RECEIVED";
export const PHONE_INPUT_RECEIVED = "PHONE_INPUT_RECEIVED";
export const SEND_PHONE_DETAIL = "SEND_PHONE_DETAIL";
export const PHONE_CODE_INPUT_RECEIVED = "PHONE_CODE_INPUT_RECEIVED";
export const SEND_RATING_INPUT = "SEND_RATING_INPUT";
export const CHECK_ACTIVE_ORDER = "CHECK_ACTIVE_ORDER";
export const GET_OPEN_ORDERS = "GET_OPEN_ORDERS";
export const RATING_INPUT_RECEIVED = "RATING_INPUT_RECEIVED";
export const XGATE_INPUT_RECEIVED = "XGATE_INPUT_RECEIVED";
export const XGATE_INPUT_EMPTY = "XGATE_INPUT_EMPTY";
export const GET_XGATE_CUSTOMER = "GET_XGATE_CUSTOMER";
export const XGATE_DETAILS_RECEIVED = "XGATE_DETAILS_RECEIVED";
export const XGATE_DETAILS_EMPTY = "XGATE_DETAILS_EMPTY";
export const GET_XGATE_COUPON = "GET_XGATE_COUPON";
export const XGATE_COUPON_EMPTY = "XGATE_COUPON_EMPTY";
export const XGATE_COUPON_RECEIVED = "XGATE_COUPON_RECEIVED";
export const GET_XGATE_POINTS = "GET_XGATE_POINTS";
export const XGATE_POINTS_EMPTY = "XGATE_POINTS_EMPTY";
export const XGATE_POINTS_RECEIVED = "XGATE_POINTS_RECEIVED";
export const TOGGLE_XGATE_POINTS_POPUP = "TOGGLE_XGATE_POINTS_POPUP";
export const GET_COMO_CUSTOMER = "GET_COMO_CUSTOMER";
export const COMO_DETAILS_RECEIVED = "COMO_DETAILS_RECEIVED";
export const EBER_DETAILS_RECEIVED = "EBER_DETAILS_RECEIVED";

export const COMO_DETAILS_EMPTY = "COMO_DETAILS_EMPTY";
export const LOGINPOPUP = "LOGINPOPUP";
export const GET_CUSTOMER_DETAILS = "GET_CUSTOMER_DETAILS";
export const REDIRECT_TO_TABLE = "REDIRECT_TO_TABLE";
export const ORDER_OBJECT = "ORDER_OBJECT";
export const RESET_ORDER_OBJECT = "RESET_ORDER_OBJECT";
export const EBER_LOGIN = "EBER_LOGIN";
export const CHECK_ORDER_SUBMITTED = "CHECK_ORDER_SUBMITTED";







// PDF_MENU
export const GET_PDF_MENU = "GET_PDF_MENU";
export const EMPTY_PDF_MENU = "EMPTY_PDF_MENU";
export const PDF_MENU_RECEIVED = "PDF_MENU_RECEIVED";
// TOAST_MSG
export const EMPTY_TOAST_MSG = "EMPTY_TOAST_MSG";
export const TOAST_MSG = "TOAST_MSG";
export const HIDE_TOAST_MSG = "HIDE_TOAST_MSG";
export const SHOW_NOTIFICATION_WITH_TIMEOUT = "SHOW_NOTIFICATION_WITH_TIMEOUT";
// TABLE DETAIL
export const TABLE_INITIAL_STATE = "TABLE_INITIAL_STATE";
export const GET_TABLE_DETAIL = "GET_TABLE_DETAIL";
export const TABLE_DETAIL_RECEIVED = "TABLE_DETAIL_RECEIVED";
export const EMPTY_TABLE_DETAIL = "EMPTY_TABLE_DETAIL";
export const FILTER_TAG_DETAIL_RECEIVED = "FILTER_TAG_DETAIL_RECEIVED";
export const ADD_FILTER_TAG = "ADD_FILTER_TAG";
export const ADD_FILTER_TAG_RECEIVED = "ADD_FILTER_TAG_RECEIVED";
export const ADD_TRENDING_POPULAR_TAGS = "ADD_TRENDING_POPULAR_TAGS";

export const ADD_SEARCH_TAG_RECEIVED = "ADD_SEARCH_TAG_RECEIVED";
export const ADD_SEARCH_TEXT = "ADD_SEARCH_TEXT";
export const GET_SELECTED_ITEM_DETAIL = "GET_SELECTED_ITEM_DETAIL";
export const SELECTED_ITEM_DETAIL_RECEIVED = "SELECTED_ITEM_DETAIL_RECEIVED";
export const CHANGE_TABLE_NOT_FOUND_MODAL = "CHANGE_TABLE_NOT_FOUND_MODAL";
export const CHANGE_TABLE_ALREADY_INUSE_MODAL =
  "CHANGE_TABLE_ALREADY_INUSE_MODAL";
export const EDITED_ITEM_DETAIL_RECEIVED = "EDITED_ITEM_DETAIL_RECEIVED";
export const EMPTY_EDITED_ITEM_DETAIL = "EMPTY_EDITED_ITEM_DETAIL";
export const CHANGE_CLEAR_CACHE_STATUS = "CHANGE_CLEAR_CACHE_STATUS";
export const CHANGE_REDIRECT_HOME_PAGE = "CHANGE_REDIRECT_HOME_PAGE";
export const CHANGE_SESSION_EXPIRED_PAGE = "CHANGE_SESSION_EXPIRED_PAGE";
export const ITEM_86_DETAIL_RECIEVED = "ITEM_86_DETAIL_RECIEVED";
export const EMPTY_ITEM_86_DETAIL = "EMPTY_ITEM_86_DETAIL";
export const SERVER_ORDER_ID = "SERVER_ORDER_ID";
export const GET_SERVER_ORDER_ID = "GET_SERVER_ORDER_ID";
export const COMBINE_ORDER_STATUS = "COMBINE_ORDER_STATUS";


// OTP DETAIL
export const SEND_OTP_DETAIL = "SEND_OTP_DETAIL";
export const SEND_OTP_DETAIL_RECEIVED = "SEND_OTP_DETAIL_RECEIVED";
export const EMPTY_OTP_DETAIL = "EMPTY_OTP_DETAIL";
// Cart DETAIL
export const SET_VOID_CANCLE_MENU_ITEM = "SET_VOID_CANCLE_MENU_ITEM";
export const EMPTY_VOID_CANCLE_MENU_ITEM = "EMPTY_VOID_CANCLE_MENU_ITEM";
export const CART_INITIAL_STATE = "CART_INITIAL_STATE";
export const ADD_ITEM_CART_DETAIL = "ADD_ITEM_CART_DETAIL";
export const UPDATE_ITEM_CART_DETAIL = "UPDATE_ITEM_CART_DETAIL";
export const REMOVE_ITEM_CART_DETAIL = "REMOVE_ITEM_CART_DETAIL";
export const REMOVE_86_ITEM_CART_DETAIL = "REMOVE_86_ITEM_CART_DETAIL";
export const QUANTITY_CHECK_CART_ITEMS_DETAIL = "QUANTITY_CHECK_CART_ITEMS_DETAIL";


export const CART_DETAIL_RECEIVED = "CART_DETAIL_RECEIVED";
export const EMPTY_CART_DETAIL = "EMPTY_CART_DETAIL";
export const POS_CART_DETAIL_RECEIVED = "POS_CART_DETAIL_RECEIVED";
export const TEMPORARY_POS_DATA = "TEMPORARY_POS_DATA";

export const EMPTY_POS_CART_DETAIL = "EMPTY_POS_CART_DETAIL";
export const TOGGLE_CART_PAGE = "TOGGLE_CART_PAGE";
export const CART_TAX_DETAIL_RECEIVED = "CART_TAX_DETAIL_RECEIVED";
export const EMPTY_CART_TAX_DETAIL = "EMPTY_CART_TAX_DETAIL";
export const CART_DETAIL_STATUS_CHANGED = "CART_DETAIL_STATUS_CHANGED";
export const CHANGE_ORDER_SOCKET_EMIT = "CHANGE_ORDER_SOCKET_EMIT";
export const DELETE_RECENT_AND_CART_ORDER = "DELETE_RECENT_AND_CART_ORDER";
export const ACTIVE_ITEM_UNAVALIABLE_MODAL = "ACTIVE_ITEM_UNAVALIABLE_MODAL";
export const INACTIVE_ITEM_UNAVALIABLE_MODAL =
  "INACTIVE_ITEM_UNAVALIABLE_MODAL";
export const SHOW_COMPLETE_PAGE_TOGGLE = "SHOW_COMPLETE_PAGE_TOGGLE";
// Order DETAIL
export const ORDER_PLACE_DETAIL = "ORDER_PLACE_DETAIL";
export const ORDER_PLACE_DETAIL_RECEIVED = "ORDER_PLACE_DETAIL_RECEIVED";
export const EMPTY_ORDER_PLACE_DETAIL = "EMPTY_ORDER_PLACE_DETAIL";
export const POS_SOCKET_ORDER_RECIEVE = "POS_SOCKET_ORDER_RECIEVE";
export const REMOVE_COMBINED_ORDER = "REMOVE_COMBINED_ORDER";
export const UPDATE_ORDER_SUB_MENUS = "UPDATE_ORDER_SUB_MENUS";

export const SAVE_CART_DETAILS = "SAVE_CART_DETAILS";//save cart Details locally for every tickets 


// Loyality
export const GET_LOYALITY_DETAIL = "GET_LOYALITY_DETAIL";
export const CARD_DETAIL_RECEIVED = "CARD_DETAIL_RECEIVED";
export const EMPTY_CARD_DETAIL = "EMPTY_CARD_DETAIL";
export const MEMBER_DETAIL_RECEIVED = "MEMBER_DETAIL_RECEIVED";
export const EMPTY_MEMBER_DETAIL = "EMPTY_MEMBER_DETAIL";
// Avocado Loyality
export const AVOCADO_DETAIL_RECEIVED = "AVOCADO_DETAIL_RECEIVED";
export const AVOCADO_MEMBER_LIST_RECEIVED = "AVOCADO_MEMBER_LIST_RECEIVED";
// Order History
export const GET_ORDER_HISTORY_DETAIL = "GET_ORDER_HISTORY_DETAIL";
export const ORDER_HISTORY_DETAIL_RECEIVED = "ORDER_HISTORY_DETAIL_RECEIVED";
export const EMPTY_ORDER_HISTORY_DETAIL = "EMPTY_ORDER_HISTORY_DETAIL";
export const CHANGE_ORDER_TIP = "CHANGE_ORDER_TIP";
// ORDER ID
export const RECENT_ORDER_ID_RECEIVED = "RECENT_ORDER_ID_RECEIVED";
export const EMPTY_RECENT_ORDER_ID = "EMPTY_RECENT_ORDER_ID";
export const COMBINED_TICKET_DATA = "COMBINED_TICKET_DATA";
export const RECIVED_OPEN_ORDER_DATA = "RECIVED_OPEN_ORDER_DATA";
export const GET_OPER_ORDER_DATA = "GET_OPER_ORDER_DATA";



// REORDER IDS
export const REORDER_ORDER_IDS_RECEIVED = "REORDER_ORDER_IDS_RECEIVED";
export const EMPTY_REORDER_ORDER_IDS = "EMPTY_REORDER_ORDER_IDS";
// Voucher List
export const GET_VOUCHER_LIST_DETAIL = "GET_VOUCHER_LIST_DETAIL";
export const VOUCHER_LIST_DETAIL_RECEIVED = "VOUCHER_LIST_DETAIL_RECEIVED";
export const EMPTY_VOUCHER_LIST_DETAIL = "EMPTY_VOUCHER_LIST_DETAIL";
// Apply Voucher
export const ADD_DETAIL_CARD_TOKEN = "ADD_DETAIL_CARD_TOKEN";
export const GET_APPLY_VOUCHER_DETAIL = "GET_APPLY_VOUCHER_DETAIL";
export const REMOVE_ITEM_VOUCHER_DETAIL = "REMOVE_ITEM_VOUCHER_DETAIL";
export const APPLY_VOUCHER_DETAIL_RECEIVED = "APPLY_VOUCHER_DETAIL_RECEIVED";
export const EMPTY_APPLY_VOUCHER_DETAIL = "EMPTY_APPLY_VOUCHER_DETAIL";
export const ADD_PROMOCODE = "ADD_PROMOCODE";
export const EMPTY_PROMOCODE = "EMPTY_PROMOCODE";
export const UPDATE_CALL_SERVER_DATA = "UPDATE_CALL_SERVER_DATA";
export const ACKNOWLEDGE_CALL_SERVER = "ACKNOWLEDGE_CALL_SERVER";




// Server Concept
export const GET_SERVER_DETAIL = "GET_SERVER_DETAIL";
export const SET_MERCHANT_SERVER_ID = "SET_MERCHANT_SERVER_ID";

export const SERVER_DETAIL_RECEIVED = "SERVER_DETAIL_RECEIVED";
export const EMPTY_SERVER_DETAIL = "EMPTY_SERVER_DETAIL";
export const SERVER_USER_DETAIL_RECEIVED = "SERVER_USER_DETAIL_RECEIVED";
export const EMPTY_SERVER_USER_DETAIL = "EMPTY_SERVER_USER_DETAIL";
export const BOOL_QR_ORDER = "BOOL_QR_ORDER";

// Payment
export const PAYMENT_INITIAL_STATE = "PAYMENT_INITIAL_STATE";
export const PAYMENT_INTENT_REQUESTED = "PAYMENT_INTENT_REQUESTED";
export const PAYMENT_INTENT_SUCCEEDED = "PAYMENT_INTENT_SUCCEEDED";
export const PAYMENT_INTENT_FAILED = "PAYMENT_INTENT_FAILED";

export const PAYMENT_TOKEN_REQUESTED = "PAYMENT_TOKEN_REQUESTED";
export const PAYMENT_TOKEN_SUCCEEDED = "PAYMENT_TOKEN_SUCCEEDED";
export const PAYMENT_TOKEN_FAILED = "PAYMENT_TOKEN_FAILED";

export const CONFIRM_PAYMENT_REQUESTED = "CONFIRM_PAYMENT_REQUESTED";
export const CONFIRM_PAYMENT_SUCCEEDED = "CONFIRM_PAYMENT_SUCCEEDED";
export const CONFIRM_PAYMENT_FAILED = "CONFIRM_PAYMENT_FAILED";

export const WHERE_IS_MY_ORDER_REQUESTED = "WHERE_IS_MY_ORDER_REQUESTED";
export const WHERE_IS_MY_ORDER_FAILED = "WHERE_IS_MY_ORDER_FAILED";
export const CLEAR_DATA = "CLEAR_DATA";
export const CHANGE_PAYMENT_DETAIL = "CHANGE_PAYMENT_DETAIL";
export const PAYMENT_INITIAL_DETAIL = "PAYMENT_INITIAL_DETAIL";
export const GET_PAYMENT_DETAIL = "GET_PAYMENT_DETAIL";
export const UPDATE_PAYMENT_DETAIL = "UPDATE_PAYMENT_DETAIL";
export const SEND_PAYMENT_MAIL = "SEND_PAYMENT_MAIL";




//CHECK_QUANTITY
export const CHECK_QUANTITY_REQUESTED = "CHECK_QUANTITY_REQUESTED";
export const CHECK_QUANTITY_SUCCEEDED = "CHECK_QUANTITY_SUCCEEDED";
export const CHECK_QUANTITY_FAILED = "CHECK_QUANTITY_FAILED";

export const CHECK_QUANTITY_MODAL_CLOSE = "CHECK_QUANTITY_MODAL_CLOSE";

// REPEATE_CHECK
export const ADD_QUANTITY = "ADD_QUANTITY";
export const ACTIVE_ITEM_DATA = "ACTIVE_ITEM_DATA";

// APPLE_PAYMENT
export const SESSION_CREATE = "SESSION_CREATE";
export const FINSERVE_PAYMENT = "FINSERVE_PAYMENT";
export const APPLE_PAY_SUCCED = "APPLE_PAY_SUCCED";
export const GOOGLE_PAYMENT = "GOOGLE_PAYMENT";





export const APPLE_PAY_SESSION_DATA = "APPLE_PAY_SESSION_DATA";

//MENU_ITEMS
export const GET_QR_MENU_ITEMS = "GET_QR_MENU_ITEMS";
export const UPDATE_OUTLET_ID = "UPDATE_OUTLET_ID";
export const UPDATE_COURSE_LIST = "UPDATE_COURSE_LIST";

export const CALL_SERVER_ACTION = "CALL_SERVER_ACTION";














