import React, { useEffect } from "react";

const CustomPopup = ({ show, onCancel, children }) => {
  useEffect(() => {
    if (document.getElementById(`root`)) {
      if (show) {
        document.getElementById(`root`).classList.add("modal_active");
      } else {
        document.getElementById(`root`).classList.remove("modal_active");
      }
    }
  }, [show]);

  return (
    <div
      className={`accountModal newPopupmodal loyality_ms ${show ? "show" : ""}`}
    >
      <section className="varification-sec">
        <a href="#" className="cross-icon" onClick={() => onCancel()}></a>
        {children || null}
      </section>
    </div>
  );
};

export default CustomPopup;
