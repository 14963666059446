import OutletMenu from "../Scenes/AuthScreens/OutletMenu";
import GetBundleDeatails from "../Scenes/Screens/GetBundleDeatails";
import GetItemsDetails from "../Scenes/Screens/GetItemsDetails";
  
  export const showMenuRoutes = [
   
    {
      path: "/qr-menu/:id",
      component: OutletMenu,
    },
    {
        path: "/get-details",
        component: GetItemsDetails,
      },
      {
        path: "/get-bundle-details",
        component: GetBundleDeatails,
      },
  ];
  