import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import Header from "../Components/Header";
import UnableToLoadData from "../Components/UnableToLoadData";
import ImageTag from "../Components/ImageTag";
import Modal from "../Components/Modal";
import { Motion, spring, presets } from "react-motion";

import {} from "../../Assets";
import {
  checkArray,
  checkNull,
  deepCopy,
  // getCartBody,
  getCartSubmittedItems,
  getSessionStorageOrDefault,
  parseRound,
} from "../../helpers";
import moment from "moment";
import {
  changePaymentDetail,
  changeStatusCartDetail,
  getOpenOrdersByTable,
  getPaymentDetail,
  orderPlaceDetail,
  showNotificationWithTimeout,
  updateRatingDetail,
} from "../../Redux/actions";
import RatingInput from "../Components/RatingInput";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import Payment from "./Payment";
import PaymentConfirm from "./PaymentConfirm";
import { getCartBody } from "../../Utils/orderHelper";

function PaymentDetail() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const table_detail = useSelector(
    (state) => state.tableDetailReducer?.table_detail
  );
  const { server_detail } = useSelector((state) => state.tableDetailReducer);
  const { void_menu_ticket,Cancelmenu_item,printjobCount } = useSelector((state) => state.cartDetailReducer);

  const { server_user_detail } = useSelector(
    (state) => state.authDetailReducer
  );
  const user_detail = useSelector(
    (state) => state.authDetailReducer.user_detail
  );

  const { paymentOpenOrders, bill_not_found, bill_not_found_msg } = useSelector(
    (state) => state.paymentDetailReducer
  );

  console.log("paymentOpenOrders=======================",paymentOpenOrders)
  const location = useLocation();
  const merchantId = table_detail?.Merchant?.id;
  const tableNumber = table_detail?.PosTableMaster?.table_number ?? "";
  const deviceId = "not-valid";

  const [selectedBillIndex, setSelectedBillIndex] = useState(-1);
  const [selectedBillArr, setSelectedBillArr] = useState([]);
  const [billItems, setBillItems] = useState([]);

  const [slidePaymentPage, setSlidePaymentPage] = useState(false);
  const [showPaymentPage, setShowPaymentPage] = useState(false);

  const [slidePayConfirmPage, setSlidePayConfirmPage] = useState(false);
  const [showPayConfirmPage, setShowPayConfirmPage] = useState(false);

  const calculateIsToolBarStatus = () => {
    let res = false;
    let maxHeight = window.outerHeight - window.innerHeight;
    if (maxHeight > 120) {
      res = true;
    }
    return res;
  };

  const togglePaymentPopUp = useCallback(
    (event) => {
      // event.preventDefault();
      if (showPaymentPage) {
        setSlidePaymentPage(false);
        setTimeout(() => {
          setShowPaymentPage(!showPaymentPage);
        }, 500);
      } else {
        setShowPaymentPage(!showPaymentPage);
        setTimeout(() => {
          setSlidePaymentPage(true);
        }, 100);
      }
    },
    [showPaymentPage, slidePaymentPage]
  );

  const togglePayConfirmPopUp = useCallback(
    (event) => {
      // event.preventDefault();
      if (showPayConfirmPage) {
        setSlidePayConfirmPage(false);
        setTimeout(() => {
          setShowPayConfirmPage(!showPayConfirmPage);
        }, 500);
      } else {
        setShowPayConfirmPage(!showPayConfirmPage);
        setTimeout(() => {
          setSlidePayConfirmPage(true);
        }, 100);
      }
    },
    [showPayConfirmPage, slidePayConfirmPage]
  );

  const [totalHeight, setTotalHeight] = useState(window.innerHeight);
  const totalHeightRef = useRef(totalHeight);
  totalHeightRef.current = totalHeight;
  const [isToolBarHide, setIsToolBarHide] = useState(
    calculateIsToolBarStatus()
  );

  useEffect(() => {
    setIsToolBarHide(calculateIsToolBarStatus());
  }, [totalHeight]);

  useEffect(() => {
    dispatch(getOpenOrdersByTable(id));
  }, [slidePayConfirmPage]);

  useEffect(() => {
    window.addEventListener("resize", function (event) {
      setTotalHeight(window.innerHeight);
    });
    dispatch(
      changePaymentDetail({
        is_payment_started: true,
      })
    );

    return () => {
      window.removeEventListener("resize", (e) => {});
      dispatch(
        changePaymentDetail({
          is_payment_started: false,
        })
      );
    };
  }, []);

  const getBillingHeading = (billItem, billItemIdx) => {
    const billItemTotal = checkArray(billItem?.menu_item)
      ? billItem?.menu_item.reduce(
          (partialSum, billMenu) =>
            partialSum +
            parseFloat(billMenu?.doubleActualAmout) *
              parseFloat(billMenu?.menu_item_quantity),
          0
        )
      : 0;
    return (
      <>
        <div
          className="h5 d-flex align-items-center justify-content-between pr-4"
          onClick={() => {
            const copySelectedBillArr = deepCopy(selectedBillArr);
            const selectInx = copySelectedBillArr.indexOf(billItemIdx);
            console.log("tesfdsfsdfsdf", selectInx, copySelectedBillArr);
            if (selectInx === -1) {
              copySelectedBillArr.push(billItemIdx);
            } else {
              copySelectedBillArr.splice(selectInx, 1);
            }
            setSelectedBillArr(copySelectedBillArr);
          }}
        >
          <p>
            {checkArray(billItem?.menu_item) ? billItem?.menu_item.length : 0}{" "}
            Items
          </p>
          <strong className="mr-5">
            {" "}
            {`${table_detail?.currency ?? ""}`}
            {parseRound(billItemTotal)}{" "}
          </strong>
        </div>
        <div
          aria-expanded={`${
            selectedBillIndex != billItemIdx ? "true" : "false"
          }`}
          className={`accordion_button${
            selectedBillIndex != billItemIdx ? " active" : ""
          }`}
          onClick={() =>
            selectedBillIndex != billItemIdx
              ? setSelectedBillIndex(billItemIdx)
              : setSelectedBillIndex(-1)
          }
        ></div>
      </>
    );
  };

  useEffect(() => {
    const newBillItems = [];
    const newSelectedBillArr = [];
    if (checkArray(paymentOpenOrders)) {
      paymentOpenOrders.map((billItem, billItemIdx) => {
        const billItemTotal = checkArray(billItem?.menu_item)
          ? billItem?.menu_item.reduce(
              (partialSum, billMenu) =>
                partialSum +
                parseFloat(billMenu?.doubleActualAmout) *
                  parseFloat(billMenu?.menu_item_quantity),
              0
            )
          : 0;
        newBillItems.push({
          uuid: billItemIdx + 1,
          billItem: billItem,
          billItemIdx: billItemIdx,
          content: (
            <ul class="billing-list pay-detail mx-3 px-4 py-2 my-0 bg-white">
              <p className="item-payment">Items</p>
              {checkArray(billItem?.menu_item) &&
                billItem?.menu_item.map((billMenu) => {
                  const itemTotalAmt =
                    parseFloat(billMenu?.doubleActualAmout) *
                    parseFloat(billMenu?.menu_item_quantity);
                  return (
                    <li>
                      <span>
                        {" "}
                        {billMenu?.menu_item_quantity} x {billMenu?.pos_name}{" "}
                      </span>
                      <strong>
                        {`${table_detail?.currency ?? ""}`}
                        {parseRound(itemTotalAmt)}
                      </strong>
                    </li>
                  );
                })}
            </ul>
          ),
        });
        newSelectedBillArr.push(billItemIdx);
      });
    }
    setBillItems(newBillItems);
    setSelectedBillArr(newSelectedBillArr);
  }, [paymentOpenOrders]);

  const selectedOrderHandler = () => {

    if (
      selectedBillArr.length === 1 &&
      paymentOpenOrders?.[selectedBillArr?.[0]]
    ) {
      const selctedPayment = [] ;
      selctedPayment.push(paymentOpenOrders[selectedBillArr?.[0]]);
      const createCartDetail = getCartSubmittedItems(
        selctedPayment,
        table_detail
      );
      console.log("inside the selectedOrderHandler of the payment details page ",createCartDetail)

      if (createCartDetail?.length > 0) {
        dispatch(changeStatusCartDetail(createCartDetail));
      }
      console.log("createCartDetaillll", createCartDetail);
      const body = getCartBody({
        recent_order_id: selctedPayment?.order_offline_id,
        cart_detail: createCartDetail,
        pos_cart_detail: [],
        uuid: getSessionStorageOrDefault("deviceId", ""),
        qr_customer_id: selctedPayment?.online_customer_id,
        table_detail,
        newTip: selctedPayment?.tips ?? 0,
        applied_voucher: [],
        totalDiscount: 0,
        promoDiscount: selctedPayment?.discount ?? 0,
        finalTotalAmt: selctedPayment?.finalTotalAmt ?? 0,
        applied_promocode: null,
        loyaltyToken: selctedPayment?.loyaltyToken ?? "",
        xgateDetails: null,
        newOrderItems: true,
        server_detail,
        server_user_detail,
        void_menu_ticket,
        Cancelmenu_item,
        printjobCount,

      });
      console.log("body =======>>>>>>",body)
      if (checkArray(selctedPayment?.payment_type)) {
        dispatch(
          changePaymentDetail({
            paymentBody: body,
            paymentType: "split",
            splitPaymentArr: selctedPayment?.payment_type,
            wayToSplit: selctedPayment?.payment_type.length,
            splitType:
              selctedPayment?.split_type_name == "Split Evenly"
                ? 1
                : selctedPayment?.split_type_name == "Split By Item"
                ? 2
                : 3,
          })
        );
        // history.push("/payment-confirm");
        togglePayConfirmPopUp();
      } else {
        // history.push("/payment");
        togglePaymentPopUp();
        dispatch(changePaymentDetail({ paymentBody: body }));
      }
    } else {
    

      dispatch(
        showNotificationWithTimeout(`Please select atleast 1 bill!`, "error")
      );
    }
  };

  const combineOrderHandler = () => {
    const selectedOrders = paymentOpenOrders.filter(
      (p, pIdx) => selectedBillArr.indexOf(pIdx) > -1
    );
   
    if (checkArray(selectedOrders) && selectedOrders.length > 1) {
      if (selectedOrders.findIndex((p) => checkArray(p.promotions)) > -1) {
        dispatch(
          showNotificationWithTimeout(
            `Unable to combine! Selected bills contain order level promotions.`,
            "error"
          )
        );
        return;
      }
     
      let combinedItems = [];

      selectedOrders.map((selctedPayment, selctedPaymentInx) => {
        const data = [] ;
        data.push(selctedPayment);
        const createCartDetail = getCartSubmittedItems(
          data,
          table_detail
        );
        combinedItems = [...combinedItems, ...createCartDetail];
      console.log("check Positions")

        // cancel other orders
        if (selctedPaymentInx !== 0) {
          const canceledBody = getCartBody({
            recent_order_id: selctedPayment?.order_offline_id,
            cart_detail: [],
            pos_cart_detail: [],
            uuid: getSessionStorageOrDefault("deviceId", ""),
            qr_customer_id: selctedPayment?.online_customer_id,
            table_detail,
            newTip: 0,
            applied_voucher: [],
            totalDiscount: 0,
            promoDiscount: 0,
            finalTotalAmt: 0,
            applied_promocode: null,
            loyaltyToken: selctedPayment?.loyaltyToken ?? "",
            xgateDetails: null,
            server_detail,
            server_user_detail,
          });
          canceledBody.order_status = "cancelled";
          canceledBody.last_updated = moment().format("yyyy/MM/DD HH:mm:ss");
          console.log("canceledBodyyyyyy", canceledBody);
          dispatch(orderPlaceDetail(canceledBody, false));
        }
        // cancel other orders END
      });
     
      if (combinedItems?.length > 0) {
        dispatch(changeStatusCartDetail(combinedItems));
      }

      console.log("createCartDetaillll", combinedItems);
      const selctedPayment = selectedOrders[0];
      const totalFinalAmount = checkArray(selectedOrders)
        ? parseFloat(
            selectedOrders.reduce(function (sum, current) {
              return sum + parseFloat(current.finalTotalAmt);
            }, 0)
          )
        : 0;
      const totalDiscountAmount = checkArray(selectedOrders)
        ? parseFloat(
            selectedOrders.reduce(function (sum, current) {
              return sum + parseFloat(current.discount);
            }, 0)
          )
        : 0;
      const totalTip = checkArray(selectedOrders)
        ? parseFloat(
            selectedOrders.reduce(function (sum, current) {
              return sum + parseFloat(current.tips);
            }, 0)
          )
        : 0;
      const body = getCartBody({
        recent_order_id: selctedPayment?.order_offline_id,
        cart_detail: combinedItems,
        pos_cart_detail: [],
        uuid: getSessionStorageOrDefault("deviceId", ""),
        qr_customer_id: selctedPayment?.online_customer_id,
        table_detail,
        newTip: totalTip,
        applied_voucher: [],
        totalDiscount: 0,
        promoDiscount: totalDiscountAmount,
        finalTotalAmt: totalFinalAmount,
        applied_promocode: null,
        loyaltyToken: selctedPayment?.loyaltyToken ?? "",
        xgateDetails: null,
        newOrderItems: true,
        server_detail,
        server_user_detail,
        void_menu_ticket,
        Cancelmenu_item,
        printjobCount
      });
      console.log("newBodyyyyyyyyy", body);
      dispatch(orderPlaceDetail(body, false));
      // history.push("/payment");
      togglePaymentPopUp();
      dispatch(changePaymentDetail({ paymentBody: body }));

    } else {
      dispatch(
        showNotificationWithTimeout(`Please select atleast 2 bill!`, "error")
      );
    }
  };
  console.log("billItems==============",billItems)

  function getCartDetail() {
    if (table_detail?.PosTableMaster && table_detail?.Merchant) {
      return (
        <div
          className={`wrapper cart_wrapper cart_sidebar order-summary ${
            isToolBarHide ? "" : "toolbar-vh"
          }`}
        >
          <Header
            backTitle={""}
            backPath={""}
            hideHeader={true}
            rtComponent={
              <>
                <div className="hotel_name px-4">
                  <div className="circle-img">
                    <figure className="">
                      <ImageTag src={table_detail?.Merchant?.logo} alt="" />
                    </figure>
                  </div>
                  <div className="naming-sec text-center">
                    <h4> {table_detail?.Merchant?.account_name} </h4>
                    <p>
                      Table #{table_detail?.PosTableMaster?.table_number} Basket
                    </p>
                  </div>
                </div>
              </>
            }
          />
          <section className="middle-container p-4">
            <h3 class="title"> Pick Your Bill </h3>
            <p> Which bill(s) are you paying on your table? </p>
            <div className="d-flex align-items-stretch flex-wrap acordion-wrap">
              {checkArray(billItems) && (
                <Accordion allowZeroExpanded>
                  {billItems.map((item, itemInx) => (
                    
                    <AccordionItem
                      key={item.uuid}
                      dangerouslySetExpanded={selectedBillIndex === itemInx}
                      className={`AccordionItems${
                        selectedBillArr.indexOf(itemInx) === -1 ? " active" : ""
                      }`}
                    >
                      <AccordionItemHeading>
                        <AccordionItemButton className="">
                          {getBillingHeading(item.billItem, item.billItemIdx)}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>{item.content}</AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              )}

              <div className="btm-buttons">
                {/* {selectedBillArr.length > 1 && (
                  <button
                    className="input-btn mt-5 mb-0 hollow"
                    onClick={() => combineOrderHandler()}
                  >
                    {" "}
                    Combine Selected Bills
                  </button>
                )}
                {selectedBillArr.length === 1 && (
                  <button
                    className="input-btn mt-5 mb-0"
                    onClick={() => selectedOrderHandler()}
                  >
                    {" "}
                    Pay Selected Bill(s)
                  </button>
                )} */}
                 <button
                    className="input-btn mt-5 mb-0 hollow"
                    onClick={() => combineOrderHandler()}
                    disabled={selectedBillArr.length <= 1 }
                  >
                    {" "}
                    Combine Selected Bills
                  </button>
                {/* )} */}
                {/* {selectedBillArr.length === 1 && ( */}
                  <button
                    className="input-btn mt-5 mb-0"
                    onClick={() => selectedOrderHandler()}
                    disabled={selectedBillArr.length > 1 || selectedBillArr.length==0}
                  >
                    {" "}
                    Pay Selected Bill(s)
                    
                  </button>
              </div>
            </div>
          </section>
          <Modal show={bill_not_found} title={bill_not_found_msg} />
          <Motion
            defaultStyle={{ x: 100 }}
            style={{
              x: spring(slidePaymentPage === true ? 0 : 100, {
                precision: 1,
                stiffness: 300,
                damping: 44,
              }),
            }}
          >
            {({ x }) => {
              return showPaymentPage ? (
                <Payment
                  style={{
                    right: `-${x}%`,
                  }}
                  setShowPaymentPage={togglePaymentPopUp}
                />
              ) : null;
            }}
          </Motion>
          <Motion
            defaultStyle={{ x: 100 }}
            style={{
              x: spring(slidePayConfirmPage === true ? 0 : 100, {
                precision: 1,
                stiffness: 300,
                damping: 44,
              }),
            }}
          >
            {({ x }) => {
              return showPayConfirmPage ? (
                <PaymentConfirm
                  style={{
                    right: `-${x}%`,
                  }}
                  setShowPayConfirmPage={togglePayConfirmPopUp}
                />
              ) : null;
            }}
          </Motion>
        </div>
      );
    } else {
      return (
        <div className="wrapper error_container">
          <UnableToLoadData />
        </div>
      );
    }
  }

  return <>{getCartDetail()}</>;
}

export default PaymentDetail;
