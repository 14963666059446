import React, { useCallback, useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { useSelector, useDispatch } from "react-redux";
import { checkArray, checkVoucherAvailable, pluckArray } from "../../helpers";
import {
  addPromocodeDetail,
  emptyPromocodeDetail,
  getOrderHistoryDetail,
  getXgatePoints,
  showNotificationWithTimeout,
} from "../../Redux/actions";
import ImageTag from "./ImageTag";

const XgatePointsPopup = ({ show, onCancel, tabType }) => {
  const dispatch = useDispatch();
  const defaultActiveTab = tabType !== undefined ? tabType : 0;

  const { table_detail } = useSelector((state) => state.tableDetailReducer);
  const { cart_detail, applied_promocode } = useSelector(
    (state) => state.cartDetailReducer
  );
  const { xgate_details: xgateDetails, xgate_coupons: xgateCoupons } =
    useSelector((state) => state?.authDetailReducer);
  const merchantId = table_detail?.Merchant?.id;

  const [pointsInput, setPointsInput] = useState("");

  // console.log("table_detail", table_detail);

  useEffect(() => {
    if (document.getElementById(`root`)) {
      if (show) {
        document.getElementById(`root`).classList.add("modal_active");
      } else {
        document.getElementById(`root`).classList.remove("modal_active");
      }
    }
  }, [show]);

  const closePopup = () => {
    // setPointsInput("");
    onCancel();
  };

  return (
    <div className={`promoModal loyality_ms ${show ? "show" : ""}`}>
      <section className="varification-sec">
        <a href="#" className="cross-icon" onClick={() => closePopup()}></a>
        <div className="row mx-0 px-3 justify-content-start">
          <div className="naming-sec col-12 px-0">
            <h4>Redeem Points</h4>
            <p className="mt-2 md-txt h5">
              {" "}
              Please enter number of points to be redeemed and submit.
            </p>
          </div>
        </div>
        <div className="row mx-3 justify-content-end">
          <NumberFormat
            prefix={""}
            // displayType="text"
            className="input-text mt-5 mb-3"
            placeholder="Enter the points"
            value={pointsInput > 0 ? pointsInput : ""}
            inputmode="decimal"
            decimalScale={2}
            fixedDecimalScale={true}
            onValueChange={(values) => {
              const { formattedValue, value } = values;
              // formattedValue = $2,223
              // value ie, 2223
              setPointsInput(value);
            }}
          />
          <button
            type="button"
            className="btn green-btn my-3"
            onClick={() => {
              if (pointsInput > 0) {
                dispatch(
                  getXgatePoints({
                    merchant_id: merchantId,
                    code: xgateDetails?.membership?.code ?? "",
                    points_to_redeem: parseFloat(pointsInput),
                  })
                );
              }
            }}
          >
            Submit
          </button>
        </div>
      </section>
    </div>
  );
};

export default XgatePointsPopup;
