import { combineReducers } from "redux";
import tableDetailReducer from "./table_detail";
import pdfMenuReducer from "./pdf_menu";
import toastReducer from "./toast";
import authDetailReducer from "./auth_detail";
import cartDetailReducer from "./cart_detail";
import paymentDetailReducer from "./payment_detail";
import loaderReducer from "./loader";
const appReducer = combineReducers({
  tableDetailReducer,
  loaderReducer,
  pdfMenuReducer,
  toastReducer,
  authDetailReducer,
  cartDetailReducer,
  paymentDetailReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'START_NEW_SESSION') {
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer;
