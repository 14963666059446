import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getPdfMenu, emptyPdfMenu } from "../../Redux/actions";
// import PDFViewer from "pdf-viewer-reactjs";
import UnableToLoadData from "../Components/UnableToLoadData";
import PleaseWait from "../Components/PleaseWait";
import samplePDF from "../../Assets/sample123.pdf";
import { connect } from "react-redux";
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}>/pdf.worker.js`




// Create new plugin instance


let PdfViewer = ({ getPdfMenu, pdf_menu_data, loading, emptyPdfMenu }) => {
  let { id } = useParams();
  console.log("id:", id);
  console.log("loading:", loading);
  console.log("pdf_menu_data:", pdf_menu_data?.qr_code_id);
  useEffect(() => {
    console.log("inside the useEffect")
    if (
      loading === false &&
      (pdf_menu_data === null || pdf_menu_data === undefined)
    ) {
      getPdfMenu(id);
    } else if (
      loading === false &&
      pdf_menu_data?.qr_code_id &&
      pdf_menu_data?.qr_code_id !== id
    ) {
      emptyPdfMenu();
    }
  }, []);

  const [numPages, setNumPages] = useState(5);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
console.log("pdf_menu_data?.qr_code_id}",pdf_menu_data?.pdf_file_path)
  function getPdfPage() {
    if (pdf_menu_data?.pdf_file_path) {
      return (
        <Document
        renderMode={"svg"}
        file={pdf_menu_data?.pdf_file_path}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(error)=>console.log(error,"console for the on Load Error ")}

        // className="pdf-document"
      >
         {Array.from(new Array(numPages), (el, index) => (
        <Page scale="0.5" size="A4" key={`page_${index + 1}`} pageNumber={index + 1} />
      ))}
      </Document>
   
      );
    } else if (loading === false) {
      return <UnableToLoadData />;
    } else {
      return <PleaseWait />;
    }
  }

  return <div className="wrapper pdf_container">{getPdfPage()}</div>;
};
const mapStateToProps = (state) => ({
  pdf_menu_data: state.pdfMenuReducer.pdf_menu_data,
  loading: state.loaderReducer.loading,
});

const mapDispatchToProps = {
  getPdfMenu: getPdfMenu,
  emptyPdfMenu: emptyPdfMenu,
};
PdfViewer = connect(mapStateToProps, mapDispatchToProps)(PdfViewer);
export default PdfViewer;
