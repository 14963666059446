import * as actionTypes from "../actions/types";

const initialState = {
  loading: false,
  paymentTokenData: {},
  paymentTokengenerated: false,
  paymentIntent: null,
  paymentType: "", // whole/split
  splitType: 0, // evenly = 1, item = 2, custom = 3
  wayToSplit: 2, // How many ways would you like to split the bill
  manyTimesToPay: 2, // How many ways will you be paying for
  splitPaymentArr: [],
  paymentBody: null,
  paymentDetailData: null,
  paymentOpenOrders: [],
  bill_not_found: false,
  is_payment_started: false,
  is_payment_cleared: false,
  is_next_portion: false,
  bill_not_found_msg: "",
  selected_payment_method: null,
  mail_order_detail: null,
  paymentReq: null,
  updatedPaymentReq: false,
  pausePaymentProcess: false,
  recallMethodNotification: false,
  methodNotificationInx: 0,
};

const paymentDetailReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case actionTypes.PAYMENT_INITIAL_STATE:
      return initialState;
    case actionTypes.CLEAR_DATA:
      return initialState;
    case actionTypes.PAYMENT_INTENT_SUCCEEDED:
    case actionTypes.PAYMENT_TOKEN_SUCCEEDED:
    case actionTypes.CHANGE_PAYMENT_DETAIL:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case actionTypes.PAYMENT_INITIAL_DETAIL:
      return {
        ...state,
        paymentIntent: null,
        paymentReq: null,
        updatedPaymentReq: false,
        pausePaymentProcess: false,
        recallMethodNotification: false,
        methodNotificationInx: 0,
      };
    default:
      return state;
  }
};
export default paymentDetailReducer;
