import React, { useCallback, useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { LoyalityAccountScreen, LoyalityHistoryScreen } from "../Screens/.";
import { getOrderHistoryDetail } from "../../Redux/actions";
import Vouchers from "../Screens/Vouchers";
import { Tabbar } from "./Tabbar";
import ImageTag from "../Components/ImageTag";
import { serverDetailsLogo, starLoyality } from "../../Assets";
import { StyledSVG } from "../../Utils/style";

const AccountPopup = ({show,onCancel,tabType,addBundlePopUp,addItemPopUp}) => {
  const dispatch = useDispatch();
  const defaultActiveTab = tabType !== undefined ? tabType : 0;
  const [activeTab, setActiveTab] = useState(defaultActiveTab);

  const tableReducer = useSelector((state) => state.tableDetailReducer);
  const { server_detail } = useSelector((state) => state.tableDetailReducer);

  const merchantId = tableReducer?.table_detail?.Merchant?.id;
  const {
    avocado_detail: avocadoDetail,
    card_detail: cardDetail,
    xgate_details: xgateDetails,
    como_details: comoDetails,
    eber_details,
    user_detail
  } = useSelector((state) => state?.authDetailReducer);
  // const avocadoDetail = useSelector(
  //   (state) => state?.authDetailReducer?.avocado_detail
  // );
  // const cardDetail = useSelector(
  //   (state) => state?.authDetailReducer?.card_detail
  // );
  // const xgateDetails = useSelector(
  //   (state) => state?.authDetailReducer?.xgate_details
  // );
  // const comoDetails = useSelector(
  //   (state) => state?.authDetailReducer?.como_details
  // );

  const switchTab = useCallback((value) => {
    setActiveTab(value);
  }, []);

  useEffect(() => {
    setActiveTab(defaultActiveTab);
  }, [defaultActiveTab]);

  useEffect(() => {
    if (document.getElementById(`root`)) {
      if (show) {
        document.getElementById(`root`).classList.add("modal_active");
      } else {
        document.getElementById(`root`).classList.remove("modal_active");
      }
    }

    // if (show === 1) {
    //   dispatch(getOrderHistoryDetail(merchantId));
    // }
  }, [show]);

  useEffect(() => {
    if (show === 1) {
      dispatch(getOrderHistoryDetail(merchantId));
    }
  }, [show]);

  function getTabData() {
    let res = null;
    if (activeTab == 0) {
      res = <LoyalityAccountScreen  addBundlePopUp={addBundlePopUp}
      addItemPopUp={addItemPopUp} onCancel={onCancel} />;
    } else if (activeTab == 1) {
      res = <Vouchers />;
    } else if (activeTab == 2) {
      res = <LoyalityHistoryScreen />;
    }
    return res;
  }

  const getmemberNumber = () => {
    let res = "";
    if (xgateDetails?.membership?.code) {
      res = xgateDetails?.membership?.code;
    }
    if (comoDetails?.membership) {
      res =
        "  " +
        comoDetails?.membership?.firstName +
        " " +
        comoDetails?.membership?.lastName;
    } else if (cardDetail?.CardInfo?.CardNo) {
      res = cardDetail?.CardInfo?.CardNo;
    } else if (eber_details?.first_name) {
      res = eber_details?.first_name;
    }
    else if (user_detail?.first_name
      ) {
      res = user_detail?.first_name;
    }
    return res.length?", "+res:res;
  };
  return (
    <div className={`accountModal loyality_ms ${show ? "show" : ""}`}>
      <section className="varification-sec p-3 pt-5">
        <a href="#" className="cross-icon" onClick={() => onCancel()}></a>
        {/* <div className="row mx-0 px-3 justify-content-start">
         {!server_detail?<div className="circle-img col-2 mx-0">
            <figure className="">
              <ImageTag
                src={tableReducer?.table_detail?.Merchant?.logo}
                alt=""
              />
            </figure>
          </div>:
          <ImageTag src={serverDetailsLogo} alt="" className="px-0 col-4"/>}
           {!server_detail?<div className="naming-sec col-10">
            <h4>
              {" "}
              {tableReducer?.table_detail?.Merchant?.account_name ?? ""}{" "}
            </h4>
            <p className="md-txt h5">
              {" "}
              Member #
              <span className="h3">
                {getmemberNumber()}
              </span>
            </p>
          </div>:null}
        </div> */}
        <span className=" loyality-header">
          <StyledSVG src={starLoyality} />
          <span className="ml-2">Loyalty</span>
          <p className="mt-2">Welcome back{getmemberNumber()}!</p>
        </span>
      </section>

      <Tabbar activeTab={activeTab} switchTab={switchTab} />

      {getTabData()}
    </div>
  );
};

export default AccountPopup;
