import { finservePayment, getPaymentDetail, google_fiserve_payment } from "../../../Redux/actions";
import { getRandomString } from "../../../helpers";
import { mailOrderDetail } from "../ApplePay/helper";

export const handleGooglePayClick = async (
  amount,
  payWith,
  setPayWith,
  selectedTip,
  splitPayIndex,
  paymentTip,
  cartAmount,
  customSplitInput,
  paymentBody,
  recent_order_detail,
  paymentType,
  splitPaymentArr,
  splitType,
  table_detail,
  googlePayLoaded,
  setGooglePayLoaded,
  dispatch
) => {
  console.log(
    amount,
    table_detail,
    "amount and table Details ================== "
  );
  const merchant_id =  table_detail?.Merchant?.id;
  if (!googlePayLoaded) {
    console.log("Google Pay API not loaded yet.");
    return;
  }

  // Initialize Google Pay
  const paymentsClient = new window.google.payments.api.PaymentsClient({
    environment: "TEST", // Change to 'PRODUCTION' for production 'TEST' for Testing
  });

  const paymentDataRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: "CARD",
        parameters: {
          allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
          allowedCardNetworks: ["MASTERCARD", "VISA"],
        },
        tokenizationSpecification: {
          type: "PAYMENT_GATEWAY",
          parameters: {
            gateway: "firstdata",
            gatewayMerchantId: table_detail?.QrOrdering?.merchant_partnerid,
          },
        },
      },
    ],
    merchantInfo: {
      merchantId: "BCR2DN4TRL47F5RH",
      merchantName:  "GoGMGo Pte Ltd",
    },
    transactionInfo: {
      totalPriceStatus: "FINAL",
      totalPrice: String(amount), // Replace with your actual amount
      currencyCode: "SGD",
    },
  };

  // Show the Google Pay payment sheet
  console.log("supported cards ========================",paymentDataRequest)
  const paymentData = await paymentsClient
    .loadPaymentData(paymentDataRequest)
    console.log("payment data of google pay ",paymentData)
    // .then((paymentData) => {
      const paymentResponseData =
        paymentData?.paymentMethodData?.tokenizationData?.token;
        if(paymentResponseData)
        {
          const parsedData = JSON.parse(paymentResponseData);
          const payload = {
            temp_transaction_id: getRandomString(),
            order_detail: mailOrderDetail(
              merchant_id,
              amount,
              table_detail,
              paymentBody,
              recent_order_detail,
              selectedTip,
              paymentType,
              splitPaymentArr,
              splitPayIndex,
              paymentTip,
              cartAmount,
              splitType,
              payWith,
              setPayWith,
              customSplitInput
            ),
            merchant_id: table_detail?.Merchant?.id,
            store_id: table_detail?.QrOrdering?.merchant_partnerid,
            transactionAmount:{
              "total": amount,
              "currency": "SGD"
           },
          
            paymentData:{
              signature: parsedData.signature,
              version: parsedData.protocolVersion,
              data: JSON.parse(parsedData?.signedMessage),
           }
          };
          dispatch(google_fiserve_payment(payload,(data)=>{
           console.log("data of google payment ",data)
           const getPaymentBody = {
            merchant_id: table_detail?.Merchant?.id,
            storeId: table_detail?.QrOrdering?.merchant_partnerid,
            transactionId: data?.ipgTransactionId,
          };
          dispatch(getPaymentDetail(getPaymentBody, true));
          }))
        }
     
      
      // dispatch(updateGoogle_payData({data:payload}))


      // Handle the payment data and complete the transaction
    // })
    // .catch((error) => {
    //   console.error("Error loading payment data:", error);
    // });
};
