import React from "react";
import { bigLogo } from "../../Assets";

const PleaseWait = () => {
  return (
    <div className="container test-cont ">
      <div className="row">
        <div className="col-md-12">
          <div className="not-found-menu d-flex align-items-center justify-content-center vh-100">
            <figure>
              <img src={bigLogo} />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PleaseWait;
