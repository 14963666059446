import React from "react";
import { StyledSVG } from "../../../Utils/style";

const CustomButton = ({ title, onClick, className, icon, color }) => {
  return (
    <>
      <span
        className={className ? className : "custom-btn btn px-4"}
        // className={[className,"custom-btn btn px-4"]}
        onClick={onClick}
      >
        {icon ? <StyledSVG color={color} src={icon} /> : null}
        <span className={`${icon ? "ml-2 " : ""} button-text`}>{title}</span>
      </span>
    </>
  );
};

export default CustomButton;
