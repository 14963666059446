import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Motion, spring, presets } from "react-motion";
import { useSwipeable, config } from "react-swipeable";
import DraggableItem from "../Components/DraggableItem";
import { loyaltyHeart, serverDetailsLogo, shoppingcart } from "../../Assets";
import Header from "../Components/Header";
import CartHeader from "../Components/CartHeader";
import UnableToLoadData from "../Components/UnableToLoadData";
import ImageTag from "../Components/ImageTag";
import AccountPopup from "../Components/AccountPopup";
import {
  removeItemCartDetail,
  getSelectedItemDetail,
  dismissItemAvailableModal,
  quantityModalClose,
  quantityCheckedDetails,
} from "../../Redux/actions";
import {
  checkArray,
  checkItemAvailable,
  checkNull,
  checkSubCatAvailable,
  countDecimals,
  getCartNewItemCount,
  parseRound,
  parseTwoDigitNumber,
  sortCartItems,
} from "../../helpers";
import { TOGGLE_CART_PAGE } from "../../Redux/actions/types";
import PromocodePopup from "../Components/PromocodePopup";
import Modal from "../Components/Modal";
import TipPopup from "../Components/TipPopup";
import { Terms } from "../Components/Terms";
import Payment from "./Payment";
import PaymentConfirm from "./PaymentConfirm";
import CustomButton from "../Components/CustomButton";

function Cart(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const table_detail = useSelector(
    (state) => state.tableDetailReducer?.table_detail
  );
  const {
    show_item_unavailable_modal,
    show_item_unavailable_text,
    server_detail,
  } = useSelector((state) => state.tableDetailReducer);
  const cart_detail = useSelector(
    (state) => state.cartDetailReducer?.cart_detail
  );
  // console.log("cart_detail",cart_detail)
  const pos_cart_detail = useSelector(
    (state) => state.cartDetailReducer?.pos_cart_detail
  );
  // console.log("pos_cart_detail",pos_cart_detail)
  const applied_promocode = useSelector(
    (state) => state.cartDetailReducer?.applied_promocode
  );
  const applied_voucher = useSelector(
    (state) => state.cartDetailReducer?.applied_voucher
  );
  const show_cart_popup = useSelector(
    (state) => state.cartDetailReducer?.show_cart_popup
  );
  // console.log("cart_detail", JSON.stringify(cart_detail));
  const recent_order_detail = useSelector(
    (state) => state.cartDetailReducer?.recent_order_detail
  );
  const recent_order_id = useSelector(
    (state) => state.cartDetailReducer?.recent_order_id
  );
  const user_detail = useSelector(
    (state) => state.authDetailReducer?.user_detail
  );
  const showPostTaxPrice =
  table_detail?.Merchant?.MerchantFinanceInfo?.menu_pricing_default == "2"
    ? true
    : false;


  const [accountTabType, setAccountTabType] = useState(0);
  const [isCartChanged, setIsCartChanged] = useState(false);
  const [showSubmittedItem, setShowSubmittedItem] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);
  const [isHeaderAllUp, setIsHeaderAllUp] = useState(true);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const loyaltyEnabled = table_detail?.Merchant?.loyalty_enabled;
  const showPayPopUp =
    table_detail?.QrOrdering?.payment_accepted == "yes" &&
    table_detail?.QrOrdering?.payment_required_upfront != "yes"
      ? true
      : false;
  const isUpFrontPayment =
    table_detail?.QrOrdering?.payment_required_upfront == "yes" &&
    !server_detail
      ? true
      : false;
  const {
    slidePaymentPage,
    showPaymentPage,
    slidePayConfirmPage,
    showPayConfirmPage,
    cartBgWhite,
    togglePaymentPopUp,
    togglePayConfirmPopUp,
    totalCartAmount,
    totalServiceCharge,
    totalSaleTax,
    totalDiscount,
    promoDiscount,
    totalTip,
    getCartBasketTotalAmount,
    setShowAccountPopUp,
    showAccountPopUp,
    showPromocodePopup,
    setShowPromocodePopup,
    submitOrderHandler,
    tipChangeHandler,
    showTipPopup,
    setShowTipPopup,
    totalItemLevelDiscount,
    promotionArr,
    addItemPopUp,
    addBundlePopUp
  } = props;
  // const [slidePaymentPage, setSlidePaymentPage] = useState(false);
  // const [showPaymentPage, setShowPaymentPage] = useState(false);

  // const [slidePayConfirmPage, setSlidePayConfirmPage] = useState(false);
  // const [showPayConfirmPage, setShowPayConfirmPage] = useState(false);
  // const [cartBgWhite, setCartBgWhite] = useState(false);

  // const togglePaymentPopUp = useCallback(
  //   (event) => {
  //     // event.preventDefault();
  //     if (showPaymentPage) {
  //       setSlidePaymentPage(false);
  //       setCartBgWhite(false);
  //       setTimeout(() => {
  //         setShowPaymentPage(!showPaymentPage);
  //       }, 500);
  //     } else {
  //       setShowPaymentPage(!showPaymentPage);
  //       setTimeout(() => {
  //         setSlidePaymentPage(true);
  //       }, 100);
  //       setTimeout(() => {
  //         setCartBgWhite(true);
  //       }, 500);
  //     }
  //   },
  //   [showPaymentPage, slidePaymentPage]
  // );

  // const togglePayConfirmPopUp = useCallback(
  //   (event) => {
  //     // event.preventDefault();
  //     if (showPayConfirmPage) {
  //       setSlidePayConfirmPage(false);
  //       setCartBgWhite(false);
  //       setTimeout(() => {
  //         setShowPayConfirmPage(!showPayConfirmPage);
  //       }, 500);
  //     } else {
  //       setShowPayConfirmPage(!showPayConfirmPage);
  //       setTimeout(() => {
  //         setSlidePayConfirmPage(true);
  //       }, 100);
  //       setTimeout(() => {
  //         setCartBgWhite(true);
  //       }, 500);
  //     }
  //   },
  //   [showPayConfirmPage, slidePayConfirmPage]
  // );

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      console.log("User Swiped!", eventData.dir);
      if (
        hideHeader == true &&
        eventData.dir == "Down" &&
        isHeaderAllUp === true
      ) {
        setHideHeader(false);
      }
      if (hideHeader == false && eventData.dir == "Up") {
        setHideHeader(true);
      }
    },
    ...config,
  });

  function scrolledElement(element) {
    const headerPageOffset = element.target?.scrollTop;
    const headerPageScrollHeight = element.target?.scrollHeight;
    const headerPageClientHeight =
      element.target?.parentNode?.parentNode?.clientHeight;
    if (headerPageOffset === 0 && hideHeader === true) {
      // setIsHeaderAllUp(true);
    }
    if (headerPageOffset > 0 && isHeaderAllUp === true) {
      // setIsHeaderAllUp(false);
    }
  }

  function removeItemHandler(index) {
    const itemIndx = cart_detail.findIndex((p) => p.offline_id == index);
    if (itemIndx > -1) {
      dispatch(removeItemCartDetail(itemIndx));
    }
    setIsCartChanged(true);
  }
  function getCartItems() {
    if (cart_detail && cart_detail.length > 0) {
      return sortCartItems(cart_detail)
        .filter(
          (p) =>
            showSubmittedItem
              ? p.is_added == true || p.is_fired == true
              : p.is_added == false
          // showSubmittedItem ? p.is_added == true && p.is_fired == true   : p.is_added == false ||p.is_fired == false
          // showSubmittedItem ? p.is_added == true && p.is_fired == true : p.is_added == false && p.is_fired==false
        )
        .map(function (item, itemIndex) {
          return (
            <DraggableItem
              item={item}
              itemIndex={item.offline_id}
              defaultimage={table_detail?.Merchant?.logo}
              removeItemHandler={removeItemHandler}
              key={item.offline_id}
              addBundlePopUp={addBundlePopUp}
              addItemPopUp={addItemPopUp}

            />
          );
        });
    }
    return null;
  }

  function getQRCartItems() {
    if (cart_detail && cart_detail.length > 0) {
      return sortCartItems(cart_detail)
        .filter((p) =>
          showSubmittedItem ? p.is_added == true : p.is_added == false
        )
        .map(function (item, itemIndex) {
          return (
            <DraggableItem
              item={item}
              itemIndex={item.offline_id}
              defaultimage={table_detail?.Merchant?.logo}
              removeItemHandler={removeItemHandler}
              key={item.offline_id}
              addBundlePopUp={addBundlePopUp}
              addItemPopUp={addItemPopUp}
            />
          );
        });
    }
    return null;
  }

  function getPopularItems() {
    let res = [];
    if (table_detail?.SubCategory?.length > 0) {
      table_detail?.SubCategory.filter(
        (subCat) =>
          checkSubCatAvailable(subCat, table_detail) &&
          subCat?.MenuItem.findIndex((p) => p.is_popular == 1) > -1
      ).map(function (subCat, index) {
        if (subCat?.MenuItem) {
          subCat?.MenuItem.filter(
            (item) =>
              checkItemAvailable(
                item,
                table_detail?.Merchant?.timezone_offset ?? "00:00",
                table_detail
              ) && item.is_popular == 1
          ).map(function (item, itemKey) {
            const checkAutoPay =
            promotionArr.length > 0 && item?.promotions?.id === undefined
              ? promotionArr.findIndex(
                  (p) => p.id == item.id && p?.promotions?.auto_apply == "Yes"
                )
              : -1;
          if (checkAutoPay > -1) {
            item = promotionArr[checkAutoPay];
          }
         
            res.push(item);
          });
        }
      });
    }

    if (res.length > 0) {
      return res.slice(0, 4).map((item) => {
        return (
          <div
            className="col-6"
            onClick={() => {
              dispatch(getSelectedItemDetail(item));
              dispatch({ type: TOGGLE_CART_PAGE, payload: true });
              // if (item?.menu_item_type == "bundled")
              //   history.push("/bundle-add-item");
              // else history.push("/add-item");
              if (item?.menu_item_type == "bundled") {
                addBundlePopUp();
              } else {
                addItemPopUp();
              }
            }}
          >
            <div className="mb-3">
              <figure className="menu-img mx-auto mb-3">
                <ImageTag
                  src={item?.image ? item?.image : table_detail?.Merchant?.logo}
                  defaultimage={table_detail?.Merchant?.logo}
                  alt=""
                />
              </figure>
              <h5 className="sm-h5 text-left">
                {" "}
                {item?.menu_item_name ?? ""}{" "}
              </h5>
              {/* <p className="text-left">
                {" "}
                @
                {showPostTaxPrice
                  ? item?.dinein_price?.DineInPostTax !== undefined
                    ? parseRound(item?.dinein_price?.DineInPostTax)
                    : "0.00"
                  : item?.dinein_price?.DineIn !== undefined
                  ? parseRound(item?.dinein_price?.DineIn)
                  : "0.00"}
              </p> */}
             

                <div className="label_row d-flex">
                  <p className={`pricing ${item?.promotions?.id ? "strike-off" : ""}`}>
                    {" "}
                    <small className="fw-bolder" >{`${table_detail?.currency ?? ""}`}</small>
                    {item?.promotions?.id
                      ? showPostTaxPrice
                        ? item?.promotions?.accutalPostTaxPrice
                          ? parseFloat(
                              item?.promotions?.accutalPostTaxPrice
                            ).toFixed(2)
                          : "0.00"
                        : item?.promotions?.accutalPrice
                        ? parseFloat(item?.promotions?.accutalPrice).toFixed(2)
                        : "0.00"
                      : showPostTaxPrice
                      ? item?.dinein_price?.DineInPostTax
                        ? parseFloat(item?.dinein_price?.DineInPostTax).toFixed(
                            2
                          )
                        : "0.00"
                      : item?.dinein_price?.DineIn
                      ? parseFloat(item?.dinein_price?.DineIn).toFixed(2)
                      : "0.00"}
                  </p>
                  {item?.promotions?.id && (
                    <p className="ml-2 promotion-price">
                      <small className="fw-bolder">{`${table_detail?.currency ?? ""}`}</small>
                      {showPostTaxPrice
                        ? item?.dinein_price?.DineInPostTax
                          ? parseFloat(
                              item?.dinein_price?.DineInPostTax
                            ).toFixed(2)
                          : "0.00"
                        : item?.dinein_price?.DineIn
                        ? parseFloat(item?.dinein_price?.DineIn).toFixed(2)
                        : "0.00"}
                    </p>
                  )}
                </div>
            </div>
          </div>
        );
      });
    } else {
      return null;
    }
  }

  function getBasketTotalQuantity() {
    let res = getCartNewItemCount(cart_detail);
    return (
      <div
        className="items-count"
        style={{ backgroundColor: table_detail?.QrOrdering?.brand_main_color }}
      >
        {" "}
        {res}{" "}
      </div>
    );
  }

  function getCartDetail() {
    // console.log(
    //   totalCartAmount,
    //   totalDiscount,
    //   promoDiscount,
    //   totalItemLevelDiscount,
    //   "-------1212121112212-------------------------------------------------------------------------121212121212121212121212121212121212122------"
    // );
    if (table_detail?.PosTableMaster && table_detail?.Merchant) {
      return (
        <>
          <div
            className={`wrapper cart_wrapper custom_pb cart_sidebar hasAnotherSlide${
              cartBgWhite ? " white-bg" : ""
            }`}
            onScroll={scrolledElement}
            {...handlers}
            style={props.style}
          >
            {/* <Motion
              defaultStyle={{ x: 270 }}
              style={{
                x: spring(
                  hideHeader === false
                    ? cart_detail.findIndex((p) => p.is_added == true) > -1
                      ? 120
                      : 120
                    : 0,
                  {
                    precision: 0.1,
                    stiffness: 300,
                    damping: 44,
                  }
                ),
              }}
            >
              {({ x }) => ( */}
                <CartHeader
                  backTitle={"Back"}
                  onTabProfile={() => {
                    if (user_detail) {
                      setShowAccountPopUp(1);
                    } else {
                      history.push("/login");
                    }
                  }}
                  backPath={() => {
                    props.setShowCartPage();
                    document.getElementsByTagName('html')[0].style.overflow = "auto";
                    // setHideHeader(true);
                  }}
                  style={{
                    height: "120px",
                  }}
                  rtComponent={
                    <>
                      <div className="hotel_name pt-2 pb-2 mb-2">
                        <div className="hotel_name_icon mb-3" >
                          <div className="circle-img ">
                            <figure >
                              <ImageTag
                                src={table_detail?.Merchant?.logo}
                                alt=""
                              />
                            </figure>
                          </div>

                          <div className="naming-sec ">
                            <h4> {table_detail?.Merchant?.account_name} </h4>
                            <p>
                            View Basket | Table <strong>{
                                table_detail?.PosTableMaster?.table_number
                              }</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                />
              {/* )}
            </Motion> */}
            {/* <Motion
              defaultStyle={{ x: 270 }}
              style={{
                x: spring(hideHeader === false ? 0 : 60, {
                  precision: 0.1,
                  stiffness: 300,
                  damping: 44,
                }),
              }}
            >
              {({ x }) => (
                <CartHeader
                  backTitle={"Back"}
                  onTabProfile={() => {
                    if (user_detail) {
                      setShowAccountPopUp(1);
                    } else {
                      history.push("/login");
                    }
                  }}
                  backPath={() => {
                    props.setShowCartPage();
                    // setHideHeader(true);
                  }}
                  style={{
                    height: `${x}px`,
                    position: "fixed",
                    top: "0px",
                    width: "100%",
                    zIndex: 9,
                    right: `${props.style.right}`,
                    backgroundColor: "#f5f6f7",
                  }}
                  rtComponent={<></>}
                />
              )}
            </Motion> */}
            <div 
            className={`outerScroll`}
            >
              {table_detail?.SubCategory &&
                table_detail.SubCategory.findIndex(
                  (subCat) =>
                    subCat?.MenuItem.findIndex((p) => p.is_popular == 1) > -1
                ) > -1 && (
                  <section className="recommended">
                    <h3 className="basket-title px-3 mt-3 tt-p bg-white fw-none ">
                      {" "}
                      You Might Also Like{" "}
                    </h3>
                    <div className="row also-like-sec">{getPopularItems()}</div>
                  </section>
                )}
              
              <section className="middle-sec cart-sec-ms new_items_page recommended">
                <h3 class="title Px-15 tt-p">
                  {" "}
                  {showSubmittedItem
                    ? "Previously Submitted Items"
                    : "Items in Basket"}{" "}
                </h3>
                {cart_detail.findIndex((p) => p.is_added == true) > -1 && (
                <div className="col-12  px-3 pb-5 pt-0">
                  <h5
                    className={`green-title ${
                      cart_detail.findIndex((p) => p.is_added == true) > -1
                        ? ""
                        : "grey"
                    }`}
                    onClick={() => {
                      if (cart_detail.findIndex((p) => p.is_added == true) > -1)
                        setShowSubmittedItem(!showSubmittedItem);
                    }}
                    style={{
                      color: table_detail?.QrOrdering?.brand_color,
                    }}
                  >
                    {showSubmittedItem
                      ? "View Items In Basket"
                      : "View Previously Submitted Items"}
                  </h5>
                </div>
              )}

                {server_detail ? getCartItems() : getQRCartItems()}
              </section>
              

              <section className="billing-details mb-5 ">
                <ul className="billing-list bg-white Px-15">
                  <li className="px-0">
                    {" "}
                    <span> Sub Total </span>{" "}
                    <strong>
                      {" "}
                      <small className="fw-bolder">{`${table_detail?.currency ?? ""}`}</small>
                      {/* {`${parseFloat(totalCartAmount).toFixed(3).slice(0, -1)}`} */}
                      {parseRound(
                        totalCartAmount+
                        totalDiscount +
                        promoDiscount +
                        totalItemLevelDiscount,
                        2,
                        false
                      )}
                    </strong>
                  </li>
                  <li className="px-0">
                    {" "}
                    <span> Discounts </span>{" "}
                    <strong>
                      (<small className="fw-bolder">{`${table_detail?.currency ?? ""}`}</small>
                      {/* {`${parseFloat(totalDiscount).toFixed(3).slice(0, -1)}`}) */}
                      {`${parseRound(
                        totalDiscount + promoDiscount + totalItemLevelDiscount
                      )}`}
                      )
                    </strong>
                  </li>
                  <li className="px-0">
                    {" "}
                    <span> Service Charges </span>{" "}
                    <strong>
                      {" "}
                      <small className="fw-bolder" >{`${table_detail?.currency ?? ""}`}</small>
                      {/* {parseFloat(totalServiceCharge).toFixed(3).slice(0, -1)} */}
                      {parseRound(totalServiceCharge)}
                    </strong>
                  </li>

                  <li className="px-0">
                    {" "}
                    <span> Tax </span>{" "}
                    <strong>
                      {" "}
                      <small className="fw-bolder">{`${table_detail?.currency ?? ""}`}</small>
                      {/* {parseFloat(totalSaleTax).toFixed(3).slice(0, -1)} */}
                      {parseRound(totalSaleTax)}
                    </strong>
                  </li>
                  {/* <li className="px-0">
                    {" "}
                    <span> Gratuity </span>{" "}
                    <input
                      type="text"
                      className="type-text"
                      value={`$${parseTwoDigitNumber(totalTip)}`}
                      onClick={() => {
                        setShowTipPopup(1);
                      }}
                      readOnly={true}
                    />
                  </li> */}
                  <li className="total px-0">
                    {" "}
                    <span> Total </span>{" "}
                    <strong> {getCartBasketTotalAmount()}</strong>
                  </li>
                </ul>
                {/* <div className="row mx-0 justify-content-between px-4">
                  <div
                    onClick={() => {
                      setShowPromocodePopup(1);
                    }}
                    className="link"
                    style={{
                      color: table_detail?.QrOrdering?.brand_highlight_color,
                    }}
                  >
                    {" "}
                    Use Promo Code{" "}
                  </div>
                  {loyaltyEnabled && (
                    <div
                      onClick={() => {
                        if (user_detail) {
                          setAccountTabType(1);
                          setShowAccountPopUp(1);
                        } else {
                          history.push("/login");
                        }
                      }}
                      className="link"
                      style={{
                        color: table_detail?.QrOrdering?.brand_highlight_color,
                      }}
                    >
                      {" "}
                      Use Voucher{" "}
                    </div>
                  )}
                  {showPayPopUp && (
                    <div class="pay-bill">
                      <h3 class="title"> Pay your bill </h3>
                      <p>
                        {" "}
                        All finished and ready to go? You can view, split, pay
                        your bill and add a tip by clicking below.{" "}
                      </p>
                      <a
                        onClick={() => orderPlaceHandler(false, totalTip, true)}
                        className="btn pink-btn"
                      >
                        {" "}
                        <strong> Continue to Pay </strong>{" "}
                      </a>
                    </div>
                  )}
                </div> */}
              </section>
            </div>

            <section
              className={`bottom-sec ${
                cart_detail?.filter(
                  (p) => p?.is_added == false || p.is_fired == false
                )?.length <= 0
                  ? "disabled"
                  : ""
              }`}
              style={props.style}
            >
              {/* <div
                onClick={
                  () =>
                    isUpFrontPayment
                      ? dispatch(quantityCheckedDetails())
                      : getCartNewItemCount(cart_detail) > 0
                      ? dispatch(quantityCheckedDetails())
                      : props.setShowCartPage()
                }
                className="green-sec py-3"
                style={{
                  backgroundColor: table_detail?.QrOrdering?.brand_main_color,
                }}
              >
                {isUpFrontPayment ? (
                  <></>
                ) : getCartNewItemCount(cart_detail) > 0 ? (
                  <div className="col-2">{getBasketTotalQuantity()}</div>
                ) : (
                  <></>
                )}

                <div
                  className={`${
                    isUpFrontPayment
                      ? "col-12"
                      : getCartNewItemCount(cart_detail) > 0
                      ? "col-8"
                      : "col-12"
                  } text-center`}
                >
                  <strong>
                    {" "}
                    {isUpFrontPayment
                      ? "Go To Pay"
                      : getCartNewItemCount(cart_detail) > 0
                      ? "Submit New Items"
                      : "Go Back And Add New Items"}
                  </strong>
                </div>
              </div> */}
              <div>
                <div className="row mx-0">
                  {getCartNewItemCount(cart_detail) > 0 ? (
                    <>
                      <div className={!loyaltyEnabled?"display_Hidden":"col-6 px-2"}>
                        <CustomButton
                          icon={loyaltyHeart}
                          color={table_detail?.QrOrdering?.brand_main_color}
                          title={"Loyalty"}
                          className={`cart-button-loyality shadow  ${
                            cart_detail?.length <= 0 ? "disabled" : ""
                          }`}
                          onClick={() => {
                            if (user_detail) {
                              setShowAccountPopUp(1);
                            } else {
                              history.push("/login");
                            }
                          }}
                        />
                      </div>
                      <div className={!loyaltyEnabled?"col-12 px-2":"col-6 px-2"}>
                        <CustomButton
                          onClick={() => {
                            isUpFrontPayment
                              ? dispatch(quantityCheckedDetails())
                              : dispatch(quantityCheckedDetails());
                          }}
                          color={"white"}
                          title={isUpFrontPayment ? "Go To Pay" : "Submit"}
                          // icon={shoppingcart}
                          className={"cart-button shadow  "}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="col-12 px-2">
                      <CustomButton
                        onClick={() => {
                          props.setShowCartPage()
                    document.getElementsByTagName('html')[0].style.overflow = "auto";


                        }}
                        color={"white"}
                        title={"Go Back And & New Items"}
                        className={"cart-button shadow  "}
                      />
                    </div>
                  )}
                </div>
              </div>
            </section>
            <AccountPopup
              show={showAccountPopUp}
              tabType={accountTabType}
              onCancel={() => {
                setShowAccountPopUp(0);
                setAccountTabType(0);
              }}
            />
            {/* )} */}
            <PromocodePopup
              show={showPromocodePopup}
              onCancel={() => setShowPromocodePopup(0)}
            />
            <TipPopup
              show={showTipPopup}
              totalTip={totalTip}
              onCancel={() => setShowTipPopup(0)}
              tipChangeHandler={tipChangeHandler}
            />
            <Modal
              show={show_item_unavailable_modal}
              title={show_item_unavailable_text}
              OnSubmit={() => dispatch(dismissItemAvailableModal())}
              submitBtnText="ok"
            />
            <Modal
              show={showTermsPopup}
              title={""}
              extraClass={"top50"}
              modalbody={<Terms />}
              OnSubmit={() => setShowTermsPopup(false)}
              submitBtnText="ok"
            />
          </div>
          <Motion
            defaultStyle={{ x: 100 }}
            style={{
              x: spring(slidePaymentPage === true ? 0 : 100, {
                precision: 1,
                stiffness: 300,
                damping: 44,
              }),
            }}
          >
            {({ x }) => {
              return showPaymentPage ? (
                <Payment
                  style={{
                    right: `-${x}%`,
                  }}
                  setShowPaymentPage={togglePaymentPopUp}
                />
              ) : null;
            }}
          </Motion>
          <Motion
            defaultStyle={{ x: 100 }}
            style={{
              x: spring(slidePayConfirmPage === true ? 0 : 100, {
                precision: 1,
                stiffness: 300,
                damping: 44,
              }),
            }}
          >
            {({ x }) => {
              return showPayConfirmPage ? (
                <PaymentConfirm
                  style={{
                    right: `-${x}%`,
                  }}
                  setShowPayConfirmPage={togglePayConfirmPopUp}
                />
              ) : null;
            }}
          </Motion>
        </>
      );
    } else {
      return (
        <div className="wrapper error_container">
          <UnableToLoadData />
        </div>
      );
    }
  }

  return <>{getCartDetail()}</>;
}

export default Cart;
