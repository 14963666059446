import React, { useState } from "react";
import Select from "react-select";

const SearchableSelect = ({
  options,
  selectedOption,
  handleSelectChange,
  isSearchable = true,
  color,
  ...props
}) => {
  return (
    <Select
      className="table-selectBtn"
      classNamePrefix="table-PrefixControl"
      styles={{
        control: (provided, state) => ({
          ...provided,
          boxShadow: "none",
        }),
        option: (provided, state) => ({
          ...provided,
          ":active": {
            backgroundColor: color,
          },
          backgroundColor: state.isSelected && color,
        }),
      }}
      options={options}
      value={selectedOption}
      onChange={handleSelectChange}
      isSearchable={isSearchable}
      {...props}
    />
  );
};

export default SearchableSelect;
