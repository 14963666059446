import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import Header from "../Components/Header";
import UnableToLoadData from "../Components/UnableToLoadData";
import ImageTag from "../Components/ImageTag";

import {} from "../../Assets";
import {
  changeLoadingStatus,
  changeSessionExpiredPage,
  clearCacheStatus,
} from "../../Redux/actions";

function SessionExpired() {
  const dispatch = useDispatch();
  const history = useHistory();

  const table_detail = useSelector(
    (state) => state.tableDetailReducer?.table_detail
  );

  useEffect(() => {
    // dispatch(changeLoadingStatus(false));
    // dispatch(changeSessionExpiredPage(false));
    dispatch(clearCacheStatus(true));
    document.body.classList.add("completed");
    return () => {
      history.push("/session-expired");
      document.body.classList.remove("completed");
    };
  }, []);

  function getCartDetail() {
    if (table_detail?.PosTableMaster && table_detail?.Merchant) {
      return (
        <div
          className="wrapper cart_wrapper custom_pb"
          // onScroll={scrolledElement}
          // {...handlers}
        >
          <div className="d-flex item-align-center">
            <Header
              backTitle={"Add New Items"}
              onTabProfile={() => {}}
              hideHeader={true}
              // style={{
              //   height: `220px`,
              // }}
              backPath={undefined}
              rtComponent={
                <>
                  <div className="hotel_name Px-15">
                    <div className="circle-img " >
                      <figure className="">
                        <ImageTag src={table_detail?.Merchant?.logo} alt="" />
                      </figure>
                    </div>
                    <div className="naming-sec text-center">
                      <h4> {table_detail?.Merchant?.account_name} </h4>
                    </div>

                    {/* <div className="desc-sec-top"> */}
                    {/* <h4> Your session has expired! </h4>
                      <p>
                        {" "}
                        Your session has expired. Please start a new session by
                        scanning the Qr code.{" "}
                      </p> */}
                    {/* </div> */}
                  </div>
                </>
              }
            />
          </div>
          <div className="mid-sec-des">
            <div class="desc-sec-top">
              <h4 style={{textAlign:"center",display:"block"}}> Your session has expired! </h4>
              <p>
                {" "}
                Your session has expired. Please start a new session by scanning
                the Qr code.{" "}
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="wrapper error_container">
          <UnableToLoadData />
        </div>
      );
    }
  }

  return <>{getCartDetail()}</>;
}

export default SessionExpired;
