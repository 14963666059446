import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Motion, spring } from "react-motion";
import {
  cameraIcon,
  imagePlaceholderIcon,
  qrCodeIcon,
  userImgIcon,
  serverDetailsLogo,
} from "../../Assets";
import { checkArray, getMerchantDateTime } from "../../helpers";
import {
  getServerDetail,
  recieveUserDetail,
  resetOrderData,
  serverUserDetai,
  setMarchentServerID,
  showNotificationWithTimeout,
} from "../../Redux/actions";
import ImageTag from "../Components/ImageTag";
import TableViewer from "./TableViewer";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const LoginServer = ({}) => {
  const dispatch = useDispatch();
  const {server_user_detail} = useSelector(state=>state.authDetailReducer)
  const { order_id,order_offline_id } = useSelector((state) => state.tableDetailReducer);

  const { merchantId, serverId } = useParams();
  const history = useHistory();

  useEffect(() => {
    const rootEl = document.getElementById("root");
    // console.log(rootEl, "rootEl");
    if (rootEl) {
      rootEl.classList.add("scroll-phone");
    }
    dispatch(
      getServerDetail({
        merchant_id: merchantId,
        server_code: serverId,
      })
    );
    const serverData ={
      merchantId: merchantId,
      serverId: serverId,

    }
    localStorage.setItem("serverData", JSON.stringify(serverData));

    
    dispatch(
      setMarchentServerID({
        merchantId: merchantId,
        serverId: serverId,
      })
    );

    return () => {
      if (rootEl) {
        rootEl.classList.remove("scroll-phone");
      }
    };
  }, []);

  const { server_detail } = useSelector((state) => state.tableDetailReducer);
  const inputOneRef = useRef(null);
  const inputTwoRef = useRef(null);
  const inputThreeRef = useRef(null);
  const inputFourRef = useRef(null);

  const initialVals = {
    first: "",
    second: "",
    third: "",
    fourth: "",
  };
  const [inpVals, setInpVals] = useState(initialVals);

  const [showTablePage, setShowTablePage] = useState(false);
  const [slideTablePage, setSlideTablePage] = useState(false);

  const toggleTablePopUp = useCallback(
    (event) => {
      // event.preventDefault();
      if (showTablePage) {
        setSlideTablePage(false);
        setTimeout(() => {
          setShowTablePage(!showTablePage);
        }, 500);
      } else {
        setShowTablePage(!showTablePage);
        setTimeout(() => {
          setSlideTablePage(true);
        }, 100);
      }
    },
    [slideTablePage, showTablePage]
  );
  // console.log("server_user_detail ",server_user_detail)

  // useEffect(()=>{
  //   // console.log("inside the new useEffect",server_detail)

  //   if(server_user_detail)
  //   {
  //     setShowTablePage(true)
  //   }
  // },[server_user_detail])

  const pinChangeHandler = useCallback(
    (val, type) => {
      setInpVals({ ...inpVals, [type]: val });
    },
    [inpVals]
  );
  const pinValHandler = useCallback(
    (val) => {
      if (val == "back") {
        if (inpVals.fourth !== "") {
          pinChangeHandler("", "fourth");
        } else if (inpVals.third !== "") {
          pinChangeHandler("", "third");
        } else if (inpVals.second !== "") {
          pinChangeHandler("", "second");
        } else if (inpVals.first !== "") {
          pinChangeHandler("", "first");
        }
      } else {
        if (inpVals.first === "") {
          pinChangeHandler(val, "first");
        } else if (inpVals.second === "") {
          pinChangeHandler(val, "second");
        } else if (inpVals.third === "") {
          pinChangeHandler(val, "third");
        } else {
          pinChangeHandler(val, "fourth");
        }
      }
    },
    [inpVals]
  );

  const pinSubmitHandler = (el) => {
    el.preventDefault();
    if (Object.keys(inpVals).findIndex((p) => inpVals[p] === "") > -1) {
      return dispatch(showNotificationWithTimeout("Enter the pin!", "error"));
    }
    const pinVal = Object.keys(inpVals)
      .map((p) => inpVals[p])
      .join("");
    const serverUserIndx =
      server_detail?.User && checkArray(server_detail?.User)
        ? server_detail?.User.findIndex((p) => p?.user_pin === pinVal)
        : -1;
    if (serverUserIndx < 0) {
      return dispatch(showNotificationWithTimeout("Invalid Pin!", "error"));
    }

    // check EOD
      const currDate = moment(new Date())
      .utc()
      .utcOffset(server_detail?.Merchant?.timezone_offset ?? "+08:00")
      // .subtract(1, "day")
      .format("yyyy-MM-DD");
    if (currDate == server_detail?.Merchant?.eod_date) {
      return dispatch(
        showNotificationWithTimeout("Day is closed!", "error")
      );
    }
    // dispatch(recieveUserDetail(server_detail?.User?.[serverUserIndx]))
    console.log("serverUserIndx",serverUserIndx)
    console.log("serverUserDetails",server_detail?.User?.[serverUserIndx])
    dispatch(serverUserDetai(server_detail?.User?.[serverUserIndx]));
    // if(!redirect_to_table)
    // { history.push(
    //   `/table-Viewer`
    // );}
    toggleTablePopUp();

  };
  return (
    <>
      <div
        className="wrapper new-Height"
        style={{
          background: "#f5f6f6",
        }}
      >
        {/* <div className="overlay"> </div> */}
        {/* <div className="text-right">
       <ImageTag src={serverDetailsLogo} alt="" style={{width:"100px",paddingRight:"15px",paddingTop: "12px",transform: "translateY(15px)"}}/>
       </div> */}
        <section
          className="varification-sec default middleClass new-log-screen container-flex"
          style={{ background: "none", paddingTop: "0px" }}
        >
          {/* <a href="#_" className="cross-icon"></a> */}
          <div className="flex-top">
            <div className="text-right">
              <ImageTag
                src={serverDetailsLogo}
                alt=""
                style={{
                  width: "85px",
                  paddingRight: "15px",
                  paddingTop: "12px",
                  transform: "translateY(15px)",
                }}
              />
            </div>
            <div className="container row pb-3">
              <div className="circle-img default col-3">
                <figure className="">
                  <ImageTag src={server_detail?.Merchant?.logo} alt="" />
                </figure>
              </div>

              <div className="circle-img default col-9">
                <h3 className="boat-text">
                  {server_detail?.Merchant?.account_name}
                </h3>
              </div>
            </div>
          </div>
          <div className="login-form pt-4  px_15 new-log-form">
            <div className="group-sec mt-2">
              <div className="title-heading bold text-dark text-green">
                Open Session
              </div>
              <div className="pin-text">Please enter your pin</div>
            </div>
            <div className="key-pad">
              <div className="d-flex pin-sec">
                <button
                  className="round-button shadow-none"
                  onClick={(e) => pinValHandler(1)}
                >
                  1
                </button>
                <button
                  className="round-button shadow-none"
                  onClick={(e) => pinValHandler(2)}
                >
                  2
                </button>
                <button
                  className="round-button shadow-none"
                  onClick={(e) => pinValHandler(3)}
                >
                  3
                </button>
              </div>
              <div className="d-flex pin-sec">
                <button
                  className="round-button shadow-none"
                  onClick={(e) => pinValHandler(4)}
                >
                  4
                </button>
                <button
                  className="round-button shadow-none"
                  onClick={(e) => pinValHandler(5)}
                >
                  5
                </button>
                <button
                  className="round-button shadow-none"
                  onClick={(e) => pinValHandler(6)}
                >
                  6
                </button>
              </div>
              <div className="d-flex pin-sec">
                <button
                  className="round-button shadow-none"
                  onClick={(e) => pinValHandler(7)}
                >
                  7
                </button>
                <button
                  className="round-button shadow-none"
                  onClick={(e) => pinValHandler(8)}
                >
                  8
                </button>
                <button
                  className="round-button shadow-none"
                  onClick={(e) => pinValHandler(9)}
                >
                  9
                </button>
              </div>
              <div className="d-flex pin-sec">
                <button className="round-button shadow-none border-0"></button>
                <button
                  className="round-button shadow-none"
                  onClick={(e) => pinValHandler(0)}
                >
                  0
                </button>
                <button
                  className="round-button shadow-none"
                  onClick={(e) => pinValHandler("back")}
                >
                  {"<"}
                </button>
              </div>
            </div>
          </div>
          <form
            onSubmit={pinSubmitHandler}
            className="login-form fixedBottom new-log-form"
          >
            <div className="d-flex pin-input-con">
              <input
                type="password"
                className="pin-input"
                ref={inputOneRef}
                value={inpVals?.first}
                readOnly={true}
                // className="star-input"
              />
              <input
                type="password"
                className="pin-input"
                ref={inputTwoRef}
                value={inpVals?.second}
                readOnly={true}
              />
              <input
                type="password"
                className="pin-input"
                ref={inputThreeRef}
                value={inpVals?.third}
                readOnly={true}
              />
              <input
                type="password"
                className="pin-input"
                ref={inputFourRef}
                value={inpVals?.fourth}
                readOnly={true}
              />
            </div>
            <div className="">
              <div className="resend_btn_row">
                <button type="submit" className="btn green-btn new-green">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
      <Motion
        defaultStyle={{ x: 100 }}
        style={{
          x: spring(slideTablePage === true ? 0 : 100, {
            precision: 1,
            stiffness: 300,
            damping: 44,
          }),
        }}
      >
        {({ x }) => {
          return showTablePage ? (
            <TableViewer
              style={{
                right: `-${x}%`,
              }}
              server_detail={server_detail}
              server_user_detail={server_user_detail}
            />
          ) : null;
        }}
      </Motion>
    </>
  );
};
export default LoginServer;






