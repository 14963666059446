import {
  GET_TABLE_DETAIL,
  TABLE_DETAIL_RECEIVED,
  EMPTY_TABLE_DETAIL,
  FILTER_TAG_DETAIL_RECEIVED,
  ADD_SEARCH_TAG_RECEIVED,
  ADD_SEARCH_TEXT,
  ADD_FILTER_TAG_RECEIVED,
  SELECTED_ITEM_DETAIL_RECEIVED,
  CHANGE_TABLE_NOT_FOUND_MODAL,
  CHANGE_TABLE_ALREADY_INUSE_MODAL,
  EDITED_ITEM_DETAIL_RECEIVED,
  EMPTY_EDITED_ITEM_DETAIL,
  CHANGE_CLEAR_CACHE_STATUS,
  ITEM_86_DETAIL_RECIEVED,
  EMPTY_ITEM_86_DETAIL,
  ACTIVE_ITEM_UNAVALIABLE_MODAL,
  INACTIVE_ITEM_UNAVALIABLE_MODAL,
  CHANGE_SESSION_EXPIRED_PAGE,
  CHANGE_REDIRECT_HOME_PAGE,
  SERVER_DETAIL_RECEIVED,
  EMPTY_SERVER_DETAIL,
  TABLE_INITIAL_STATE,
  SERVER_ORDER_ID,
  BOOL_QR_ORDER,
  ORDER_OBJECT,
  RESET_ORDER_OBJECT,
  COMBINE_ORDER_STATUS,
  ADD_TRENDING_POPULAR_TAGS,
} from "../actions/types";

const initialState = {
  page_title: "Gogmgo",
  isLogin: false,
  table_detail: null,
  filter_tag_detail: null,
  selected_item_detail: null,
  items_86_detail: [],
  edited_item_detail: null,
  filters: "",
  trending_popular: "",
  search_input: "",
  clear_cache: false,
  redirect_home_page: false,
  show_table_not_found_modal: false,
  show_table_already_inuse_modal: false,
  show_item_unavailable_modal: false,
  show_item_unavailable_text: "",
  show_session_expired_page: false,
  server_detail: null,
  server_order_id: "",
  qr_order: false,
  order_id: "",
  order_offline_id: "",
  combine_order_status:false
};

const tableDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case TABLE_INITIAL_STATE:
      return initialState;
    case GET_TABLE_DETAIL:
      return {
        ...state,
        table_detail: null,
        show_table_not_found_modal: false,
        show_table_already_inuse_modal: false,
      };
      case COMBINE_ORDER_STATUS:
      return {
        ...state,
        combine_order_status: action.payload,
      };
    case EMPTY_TABLE_DETAIL:
      return {
        ...state,
        table_detail: null,
        filter_tag_detail: null,
        selected_item_detail: null,
        edited_item_detail: null,
        filters: "",
        search_text: "",
        search_input: "",
        show_table_not_found_modal: false,
        show_table_already_inuse_modal: false,
      };
    case TABLE_DETAIL_RECEIVED:
      return { ...state, table_detail: action.json, isLogin: true };
    case FILTER_TAG_DETAIL_RECEIVED:
      return { ...state, filter_tag_detail: action.json };
    case ADD_FILTER_TAG_RECEIVED:
      return { ...state, filters: action.payload };
      case ADD_TRENDING_POPULAR_TAGS:
      return { ...state, trending_popular: action.id };
    case ADD_SEARCH_TAG_RECEIVED:
      return { ...state, search_input: action.input };
    case ADD_SEARCH_TEXT:
      return { ...state, search_text: action.input };
    case SELECTED_ITEM_DETAIL_RECEIVED:
      return { ...state, selected_item_detail: action.payload };
    case EDITED_ITEM_DETAIL_RECEIVED:
      return { ...state, edited_item_detail: action.payload };
    case EMPTY_EDITED_ITEM_DETAIL:
      return { ...state, edited_item_detail: null };
    case ITEM_86_DETAIL_RECIEVED:
      return { ...state, items_86_detail: action.payload };
    case EMPTY_ITEM_86_DETAIL:
      return { ...state, items_86_detail: [] };
    case CHANGE_TABLE_NOT_FOUND_MODAL:
      return { ...state, show_table_not_found_modal: action.payload };
    case CHANGE_TABLE_ALREADY_INUSE_MODAL:
      return { ...state, show_table_already_inuse_modal: action.payload };
    case CHANGE_CLEAR_CACHE_STATUS:
      return { ...state, clear_cache: action.payload };
    case CHANGE_REDIRECT_HOME_PAGE:
      return { ...state, redirect_home_page: action.payload };
    case ACTIVE_ITEM_UNAVALIABLE_MODAL:
      return {
        ...state,
        show_item_unavailable_modal: true,
        show_item_unavailable_text: action.payload,
      };
    case INACTIVE_ITEM_UNAVALIABLE_MODAL:
      return {
        ...state,
        show_item_unavailable_modal: false,
        show_item_unavailable_text: "",
      };
    case CHANGE_SESSION_EXPIRED_PAGE:
      return { ...state, show_session_expired_page: action.payload };
    case BOOL_QR_ORDER:
      return { ...state, qr_order: action.payload };
    case SERVER_DETAIL_RECEIVED:
      return { ...state, server_detail: action.payload };
    case EMPTY_SERVER_DETAIL:
      return { ...state, server_detail: null };
    case ORDER_OBJECT:
      console.log("setting thje order object ", action.orderObject)
      return {
        ...state,
        order_id: action.orderObject.order_id,
        order_offline_id: action.orderObject.order_offline_id,
      };
    case RESET_ORDER_OBJECT:
      return { ...state, order_id: "", order_offline_id: "" };

    case SERVER_ORDER_ID:
      return {
        ...state,
        server_order_id: action.payload,
      };
    default:
      return state;
  }
};
export default tableDetailReducer;
