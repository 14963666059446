import React, { useState, useCallback, useEffect } from "react";
import { default as ImageViewerCom } from "react-simple-image-viewer";
import { useSwipeable, config } from "react-swipeable";
import { imageExists } from "../../helpers";
import { NoPreviewAvailable } from "../../Assets";
const ImageViewer = (props) => {
  const imageArr = props?.images ?? [];
  // const [imageArr, setImageArr] = useState(props?.images ?? []);
  const [currentImage, setCurrentImage] = useState(props.currentIndex);
  const [isViewerOpen, setIsViewerOpen] = useState(props?.isOpen ?? false);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      console.log("User Swiped!", eventData.dir);
      if (imageArr.length > 1) {
        setIsViewerOpen(false);
        if (eventData.dir == "Left") {
          if (currentImage >= 0 && currentImage < imageArr.length - 1) {
            setCurrentImage(currentImage + 1);
          } else if (currentImage >= 0 && currentImage == imageArr.length - 1) {
            setCurrentImage(currentImage - 1);
          }
        }
        if (eventData.dir == "Right") {
          if (currentImage > 0) {
            setCurrentImage(currentImage - 1);
          } else if (currentImage == 0) {
            setCurrentImage(imageArr.length - 1);
          }
        }
        setIsViewerOpen(true);
      }
    },
    ...config,
  });

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    props.onClose();
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div {...props} {...handlers}>
      <img
        src={imageArr?.[currentImage]}
        onClick={() => openImageViewer(0)}
        width="300"
        key={0}
        style={{ margin: "2px" }}
        alt=""
      />
      {console.log("imageArr", imageArr)}
      {console.log("currentImage", currentImage)}
      {isViewerOpen && (
        <ImageViewerCom
          src={imageArr}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </div>
  );
};
export default ImageViewer;
