import { useEffect, useState } from "react";
import { back, handWave, salad } from "../../../Assets";
// import { STRINGS } from "../../../Shared/Constant";
import { StyledSVG } from "../../../Utils/style";

const AppHeader = ({
  table_detail,
  navigate,
  backButton,
  left = false,
  backHandler,
  hideHeader
}) => {
  const [originalHeight, setOriginalHeight] = useState(0);

  // useEffect(() => {
  //   console.log("inside the Scroll useEffect of add-items ===================================")
  //   const headerImg = document.getElementById("header-img");
  //   const header = document.getElementById("header");

  //   if (header && headerImg) {
  //     // Set initial height of the image
  //     setOriginalHeight(header.clientHeight);

  //     const handleScroll = () => {
  //       const scrollPosition = window.scrollY;
  //       if (headerImg) {
  //         headerImg.style.transform = `scale(${
  //           1 + scrollPosition / originalHeight
  //         })`;
  //       }

  //       // Reset image size when scrolling back to the top
  //       if (scrollPosition === 0 && headerImg) {
  //         headerImg.style.transform = "scale(1)";
  //       }
  //     };

  //     // Add scroll event listener
  //     window.addEventListener("scroll", handleScroll);

  //     return () => {
  //       // Clean up event listener on unmount
  //       window.removeEventListener("scroll", handleScroll);
  //     };
  //   }
  // }, [originalHeight]);
  return (
    <>
      <img
        className="background-image"
        id="header-background-img"
        alt=""
        // src={salad}
        src={table_detail?.QrOrdering?.hero_image}

      />
      <div className={`height-normal ${!hideHeader?"height-max":"height-min"} container image-border landing-header`}>
        <div className="row align-items-center mx-3">
          <div className="col-4 mx-0 px-0">
            {backButton ? (
              // <p className="back-text" onClick={() => navigate(-1)}>
              //   <img alt="" src={back} />
              //   {STRINGS.BACK}
              // </p>
              <span className="back-text" onClick={() => backHandler()}>
                <StyledSVG
                  color={
                    table_detail?.QrOrdering?.brand_main_color || "#8b8b8b"
                  }
                  src={back}
                  width="26"
                />
                {"Back"}
              </span>
            ) : left && table_detail?.QrOrdering?.enable_call_server=="yes" ? (
              <span className="d-flex align-items-center">
                <StyledSVG  color={
                    table_detail?.QrOrdering?.brand_main_color || "#8b8b8b"
                  } className="mr-2" src={handWave} width={20} />
                <p className="appheader-call">Call Server</p>
              </span>
            ) : (
              <span></span>
            )}
          </div>
          <div className="col-4">
            <div className="bg-white translate-image">
              <img
                className="logo mx-auto d-block"
                src={table_detail?.Merchant?.logo}
                alt=""
              />
            </div>
          </div>
          {<div className="col-4">
            <p className="text-end table-name">
              {"Table"} <b>{table_detail?.PosTableMaster?.table_number}</b>
            </p>
          </div>}
        </div>
      </div>
    </>
  );
};

export default AppHeader;
