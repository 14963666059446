import moment from "moment";
import { toast } from "react-toastify";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { generateMessageUuid, generateQuickGuid } from "../../helpers";
import {
  changeLoadingStatus,
  paymentInitialState,
  showNotificationWithTimeout,
} from "../actions";
import * as actionTypes from "../actions/types";

const base_url = process?.env?.REACT_APP_API_URL;
const fiserv_base_url = process?.env?.REACT_APP_FISERV_API_URL;
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  // "Access-Control-Allow-Origin": "*",
};
let myHeaders = new Headers(headers);
const defaultErrorMsg = "Something went wrong! try again later.";

function* paymentIntent(action) {
  yield put(changeLoadingStatus(true));
  const newHeader = {
    ...headers,
    ...action?.payload?.headers,
  };
  console.log("myHeaders:", myHeaders);
  const json = yield fetch(
    `https://cert.api.firstdata.com/gateway/v2/payments`,
    {
      method: "POST",
      mode: "cors",
      headers: new Headers(newHeader),
      body: JSON.stringify(action.payload?.body ?? {}),
    }
  )
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("payment_intents failed : " + error.message));
  if (json?.status) {
    yield put({
      type: actionTypes.PAYMENT_INTENT_SUCCEEDED,
      payload: {
        paymentIntent: json.data,
      },
    });
  } else {
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : defaultErrorMsg}`,
        "error"
      )
    );
    console.log(
      `updateRating error : ${json?.message ? json.message : defaultErrorMsg}`
    );
  }
  yield put(changeLoadingStatus(false));
}
function* paymentToken(action) {
  yield put(changeLoadingStatus(true));
  const token = yield select(
    (state) => state?.authDetailReducer?.user_detail?.token
  );
  myHeaders.token = token;
  const json = yield fetch(`${base_url}/ordrr_online_apis/payment_tokens`, {
    method: "POST",
    mode: "cors",
    headers: new Headers(myHeaders),
    body: JSON.stringify(action.payload),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("payment_tokens failed : " + error.message));
  if (json?.status) {
    yield put({
      type: actionTypes.PAYMENT_TOKEN_SUCCEEDED,
      payload: {
        paymentTokenData: json,
        paymentTokengenerated: true,
      },
    });
  } else {
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : defaultErrorMsg}`,
        "error"
      )
    );
    console.log(
      `updateRating error : ${json?.message ? json.message : defaultErrorMsg}`
    );
  }
  yield put(changeLoadingStatus(false));
}

function* confirmPayment(action) {
  yield put(changeLoadingStatus(true));
  const token = yield select(
    (state) => state?.authDetailReducer?.user_detail?.token
  );
  myHeaders.token = token;
  const json = yield fetch(`${base_url}/ordrr_online_apis/confirm_payment`, {
    method: "POST",
    mode: "cors",
    headers: new Headers(myHeaders),
    body: JSON.stringify(action.payload),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("confirm_payment failed : " + error.message));
  if (json?.status) {
    yield put({
      type: actionTypes.CONFIRM_PAYMENT_REQUESTED,
      payload: {
        paymentTokenData: json,
        paymentTokengenerated: false,
      },
    });
  } else {
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : defaultErrorMsg}`,
        "error"
      )
    );
    console.log(
      `updateRating error : ${json?.message ? json.message : defaultErrorMsg}`
    );
  }
  yield put(changeLoadingStatus(false));
}
function* getPaymentDetail(action) {
  yield put(changeLoadingStatus(true));
  let newPatmentReq = null;
  const token = yield select(
    (state) => state?.authDetailReducer?.user_detail?.token
  );
  const methodNotificationInx = yield select(
    (state) => state?.paymentDetailReducer?.methodNotificationInx
  );
  const recallMethodNotification = yield select(
    (state) => state?.paymentDetailReducer?.recallMethodNotification
  );
  // myHeaders.token = token;
  let url = action?.getDetail
    ? `${fiserv_base_url}/finserve/get_payment_detail`
    : `${fiserv_base_url}/finserve/create_payment`;
  if (!action?.getDetail) {
    yield put(paymentInitialState());
  }
  const json = yield fetch(url, {
    method: "POST",
    mode: "cors",
    headers: new Headers(myHeaders),
    body: JSON.stringify(action.payload),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("confirm_payment failed : " + error.message));
  if (json?.status) {
  // json.data
    newPatmentReq = json.data;
  } else {
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : defaultErrorMsg}`,
        "error"
      )
    );
    console.log(
      `updateRating error : ${json?.message ? json.message : defaultErrorMsg}`
    );
  }
  const newMethodNotificationInx = recallMethodNotification
    ? methodNotificationInx + 1
    : methodNotificationInx;
  yield put({
    type: actionTypes.PAYMENT_INTENT_SUCCEEDED,
    payload: {
      paymentReq: newPatmentReq,
      methodNotificationInx: newMethodNotificationInx,
      // paymentIntent: newPatmentIntent,
    },
  });
  if (!recallMethodNotification) yield put(changeLoadingStatus(false));
}

function* updatePaymentDetail(action) {
  yield put(changeLoadingStatus(true));
  let newPatmentReq = null;
  const token = yield select(
    (state) => state?.authDetailReducer?.user_detail?.token
  );
  // myHeaders.token = token;
  const json = yield fetch(
    `${fiserv_base_url}/finserve/update_payment_detail`,
    {
      method: "POST",
      mode: "cors",
      headers: new Headers(myHeaders),
      body: JSON.stringify(action.payload),
    }
  )
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) =>
      console.log("update_payment_detail failed : " + error.message)
    );
  if (json?.status) {
    newPatmentReq = json.data;
  } else {
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : defaultErrorMsg}`,
        "error"
      )
    );
    console.log(
      `updateRating error : ${json?.message ? json.message : defaultErrorMsg}`
    );
  }
  yield put({
    type: actionTypes.PAYMENT_INTENT_SUCCEEDED,
    payload: {
      paymentReq: newPatmentReq,
      updatedPaymentReq: true,
      pausePaymentProcess: false,
      recallMethodNotification: false,
      methodNotificationInx: 0
      // paymentIntent: newPatmentIntent,
    },
  });

  yield put(changeLoadingStatus(false));
}

function* sendPaymentMail(action) {
  yield put(changeLoadingStatus(true));
  const token = yield select(
    (state) => state?.authDetailReducer?.user_detail?.token
  );
  // myHeaders.token = token;
  const json = yield fetch(`${fiserv_base_url}/finserve/payment_mail`, {
    method: "POST",
    mode: "cors",
    headers: new Headers(myHeaders),
    body: JSON.stringify(action.payload),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("confirm_payment failed : " + error.message));
  // if (!json?.status) {
  //   yield put(
  //     showNotificationWithTimeout(
  //       `${json?.message ? json.message : defaultErrorMsg}`,
  //       "error"
  //     )
  //   );
  //   console.log(
  //     `sendPaymentMail error : ${json?.message ? json.message : defaultErrorMsg}`
  //   );
  // }
  yield put({
    type: actionTypes.PAYMENT_INTENT_SUCCEEDED,
    payload: {
      mail_order_detail: null,
    },
  });
  yield put(changeLoadingStatus(false));
}

export default function* paymentDetailActionWatcher() {
  yield takeLatest(actionTypes.PAYMENT_INTENT_REQUESTED, paymentIntent);
  yield takeLatest(actionTypes.PAYMENT_TOKEN_REQUESTED, paymentToken);
  yield takeLatest(actionTypes.CONFIRM_PAYMENT_REQUESTED, confirmPayment);
  yield takeLatest(actionTypes.GET_PAYMENT_DETAIL, getPaymentDetail);
  yield takeLatest(actionTypes.UPDATE_PAYMENT_DETAIL, updatePaymentDetail);
  yield takeLatest(actionTypes.SEND_PAYMENT_MAIL, sendPaymentMail);
}
