import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
// import { ApplePayButton } from "react-apple-pay-button";
import { Motion, spring, presets } from "react-motion";
import {
  changeLoadingStatus,
  changePaymentDetail,
  getPaymentDetail,
  getPaymentIntentDetail,
  orderPlaceDetail,
  paymentInitialState,
  redirectPaymentDetail,
  sendPaymentMail,
  showNotificationWithTimeout,
  toggleShowCompletePage,
  updatePaymentDetail,
} from "../../Redux/actions";
import {
  CreditCard,
  Dec,
  Inc,
  Settings,
  Square,
  applePay,
  creditcard,
  creditcard1,
  googelPay,
  layer,
  linkHeart,
  moneyBillWave,
  payment,
  plus,
  tipHeart,
  userPayment,
} from "../../Assets";
import { PAYMENT_INTENT_SUCCEEDED } from "../../Redux/actions/types";
import {
  PAYMENT_TYPE_ENUM,
  capitalizeFirstLetter,
  checkArray,
  checkNull,
  deepCopy,
  getRandomString,
  howMuchPercentage,
  parseRound,
  percentage,
} from "../../helpers";
import NumberFormat from "react-number-format";
import moment from "moment";
import MaskedInput from "react-text-mask";
import PaymentSuccess from "./PaymentSuccess";
import creditCardType from "credit-card-type";
import Modal from "../Components/Modal";
import Loading from "../Components/Loading";
import loadingImg from "../../Assets/images/spinner.gif";
import loadingImg2 from "../../Assets/images/shield.png";
import creditCard from "../../Assets/images/credit-card.png";
import ApplePayButton, { applePayButtonClicked } from "../Components/ApplePay";
import GooglePayButton from "@google-pay/button-react";
import ImageTag from "../Components/ImageTag";
import { Terms } from "../Components/Terms";
import GooglePay, {
  googlePayBaseConfiguration,
  handleGooglePayClick,
  processGooglePayPayment,
  processPayment,
} from "../Components/GooglePay";
import { StyledSVG } from "../../Utils/style";

const PaymentInfo = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const table_detail = useSelector(
    (state) => state.tableDetailReducer?.table_detail
  );
  const recent_order_detail = useSelector(
    (state) => state.cartDetailReducer?.recent_order_detail
  );
  const [payWith, setPayWith] = useState(PAYMENT_TYPE_ENUM.CARD_PAY);
  const [payWithApple, setPayWithApple] = useState(false);
  const { googlePayClient } = window;

  const {
    cartTotalAmount = 0,
    cartAmount = 0,
    splitPayIndex,
    togglePaySuccessPopUp,
    setSuccessPageState,
    totalTip,
    setShowPaymentPage,
    wholeBill,
  } = props;

  const {
    splitType,
    paymentIntent,
    updatedPaymentReq,
    paymentReq,
    splitPaymentArr,
    paymentBody,
    paymentType,
    paymentOpenOrders,
    is_next_portion,
    mail_order_detail,
    pausePaymentProcess,
    recallMethodNotification,
    methodNotificationInx,
  } = useSelector((state) => state.paymentDetailReducer);
  const { como_details } = useSelector((state) => state.authDetailReducer);
const checkpaymentData=useSelector((state) => state.paymentDetailReducer);
const [cardDetails,setCardDetails]=useState("")
  const [errorInputs, setErrorInputs] = useState({});
  const [loaderImgVal, setLoaderImgVal] = useState(false);
  const [cardImgClass, setCardImgClass] = useState("card-img");
  const [showTermsPopup, setShowTermsPopup] = useState(false);

  const [selectedTip, setSelectedTip] = useState(
    splitPaymentArr?.[splitPayIndex]?.tips ?? 0
  );
  const [customSplitInput, setCustomSplitInput] = useState(0);
  const [paymentTip, setPaymentTip] = useState(0);

  useEffect(() => {
    console.log("wholeBill.current-------------", wholeBill?.current)
    if (wholeBill?.current) {
      wholeBill?.current.scrollIntoView({ behavior: "smooth" });
    }


  }, [wholeBill])

  const getPaymentSoFarList = () => {
    let res = <li className="no-payment">No payments made yet.</li>;
    if (
      checkArray(splitPaymentArr) &&
      checkArray(
        splitPaymentArr.filter((p) => p.isPaid === 1 || p.isPaid === true)
      )
    ) {
      res = (
        <>
          <li>
            <strong className="bold-txt">PAYMENTS ON ORDER SO FAR</strong>
          </li>
          {splitPaymentArr
            .filter((p) => p.isPaid === 1 || p.isPaid === true)
            .map((prevPay, prevInx) => {
              return (
                <li>
                  <span>
                    {prevInx + 1}{" "}
                    {capitalizeFirstLetter(prevPay.cardHolderName)} -{" "}
                    <span className="txt-blue">Paid</span>
                  </span>
                  <strong> ${parseRound(prevPay?.splitPrice ?? 0)} </strong>
                </li>
              );
            })}
        </>
      );
    }
    return res;
  };
  const getTotalAmount = () => {
    let res = 0;
    if (paymentType == "whole") {
      res = parseFloat(paymentTip) + parseFloat(cartAmount);
    } else if (paymentType == "split") {
      if (splitType == 1) {
        res = parseFloat(paymentTip) + parseFloat(cartAmount);
      } else if (splitType == 2) {
        let totalChargableAmt = 0;
        if (checkArray(splitPaymentArr?.[splitPayIndex]?.split_items)) {
          splitPaymentArr[splitPayIndex].split_items
            .filter((p) => p.quantity > 0)
            .map((item) => {
              totalChargableAmt +=
                parseFloat(item?.quantity) * parseFloat(item?.menu_total_price);
            });
        }
        res = parseFloat(paymentTip) + parseFloat(totalChargableAmt);
      } else if (splitType == 3) {
        res = parseFloat(paymentTip) + parseFloat(customSplitInput);
      }
    }

    return parseRound(res, 2, true);
  };

  const changeSplitPayTip = (val) => {
    setPaymentTip(parseFloat(val));
  };

  const changeErrorInputs = (newErrors) => {
    setErrorInputs((prevErrorInputs) => ({ ...prevErrorInputs, ...newErrors }));
  };

  const getApplePaymentcard_method = (payCardDetails) => {
    const brandToMatch = payCardDetails?.paymentToken?.brand;
    console.log(brandToMatch, "brandToMatch");
    const data = table_detail?.QrPaymentMethod;
    for (let i = 0; i < data.length; i++) {
      if (
        data[i].payment_method_name.toLowerCase().trim() ===
        brandToMatch.toLowerCase().trim()
      ) {
        return i;
      }
    }
  };
  const checkPaymentMethod = (payCardDetails) => {
    console.log("check Method 1");
    
    if (!!table_detail?.QrPaymentMethod[cardDetails]) {
      console.log("check Method 2");
      return table_detail?.QrPaymentMethod[cardDetails];
    } else {
      console.log("check Method 3");
      return getApplePaymentcard_method(payCardDetails);
    }
  };

  const completedPaymentHandler = (payCardDetails) => {
    console.log("check split data",payCardDetails);
    const { paymentMethodDetails, merchantId: merchantId } = payCardDetails;
  const payment_method = checkPaymentMethod(payCardDetails);
   let cardmerchantID=merchantId
    console.log("check split data", { paymentMethodDetails, merchantId: cardmerchantID,payment_method });
    // if (
    //   payment_method === undefined || payment_method===null||
    //   payment_method == ""
    //   || !table_detail?.QrPaymentMethod?.[payment_method]
    //
    // ) {
    //   dispatch(showNotificationWithTimeout("Select payment method", "error"));
    //   return;
    // }
   
    const paymentMethodObj = payment_method;
    console.log("paymentMethodObj------------------------", paymentMethodObj);
    if (checkArray(splitPaymentArr)) {
      let copySplitArr = deepCopy(splitPaymentArr);
      console.log(copySplitArr);
      
      if (paymentType == "whole") {
        // Whole payment
        copySplitArr[splitPayIndex].isPaid = 1;
        copySplitArr[splitPayIndex].cardNumber =
          paymentMethodDetails?.paymentCard?.last4;
        copySplitArr[splitPayIndex].cardType =
          paymentMethodDetails?.paymentCard?.brand;
        copySplitArr[splitPayIndex].cardmerchantID = cardmerchantID;
        copySplitArr[splitPayIndex].cardname =
          paymentMethodDetails?.paymentCard?.brand;
        copySplitArr[splitPayIndex].tips = paymentTip;
        copySplitArr[splitPayIndex].paid_amount = getTotalAmount();
        copySplitArr[splitPayIndex].transaction_id =
          payCardDetails?.ipgTransactionId;
        copySplitArr[splitPayIndex].fiserv_order_id = payCardDetails?.orderId;
        copySplitArr[splitPayIndex].cardHolderName =
          document.getElementById("card-holder-name").value;
        copySplitArr[splitPayIndex].split_finish_time = moment().format(
          "yyyy-MM-DD HH:mm:ss"
        );
      }
      if (paymentType == "split") {
        // Split payment
        if (splitType == 1) {
          copySplitArr[splitPayIndex].isPaid = 1;
          copySplitArr[splitPayIndex].method_type_id =
            paymentMethodObj?.method_type_id ?? "";
          copySplitArr[splitPayIndex].payment_method =
            paymentMethodObj?.payment_method_name ?? "";
          copySplitArr[splitPayIndex].payment_type =
            paymentMethodObj?.payment_method_name ?? "";
          copySplitArr[splitPayIndex].payment_method_id =
            paymentMethodObj?.payment_method_id ?? "";
          copySplitArr[splitPayIndex].cardNumber =
            paymentMethodDetails?.paymentCard?.last4;
          copySplitArr[splitPayIndex].cardType =
            paymentMethodDetails?.paymentCard?.brand;
          copySplitArr[splitPayIndex].cardmerchantID = cardmerchantID;
          copySplitArr[splitPayIndex].cardname =
            paymentMethodDetails?.paymentCard?.brand;
          copySplitArr[splitPayIndex].tips = paymentTip;
          copySplitArr[splitPayIndex].paid_amount = getTotalAmount();
          copySplitArr[splitPayIndex].cardHolderName =
            document.getElementById("card-holder-name").value;
          copySplitArr[splitPayIndex].transaction_id =
            payCardDetails?.ipgTransactionId;
          copySplitArr[splitPayIndex].fiserv_order_id = payCardDetails?.orderId;
          copySplitArr[splitPayIndex].split_finish_time = moment().format(
            "yyyy-MM-DD HH:mm:ss"
          );
        } else if (splitType == 2) {
          const splitItemArr = deepCopy(
            copySplitArr[splitPayIndex].split_items
          );
          /* left out amount */
          let leftOutAmt = 0;
          let leftOutServiceCharge = 0;
          let leftOutSalesTax = 0;
          const spItemArr = splitItemArr
            .filter((p) => p.quantity > 0)
            .map((item) => {
              leftOutAmt += item.quantity * item.menu_total_price;
              leftOutServiceCharge += item.quantity * item.menu_service_charge;
              leftOutSalesTax += item.quantity * item.menu_sales_tax;
              return { ...item };
            });
          const leftOutItemArr = splitItemArr
            .filter((p) => p.quantity < p.max_quantity)
            .map((item) => {
              const leftOutQyt = item.max_quantity - item.quantity;
              return { ...item, quantity: 0, max_quantity: leftOutQyt };
            });
          if (checkArray(leftOutItemArr)) {
            const leftOutPay = {
              ...copySplitArr[splitPayIndex],
              splitPrice: parseRound(
                copySplitArr[splitPayIndex].splitPrice - leftOutAmt
              ),
              split_number: ++copySplitArr[splitPayIndex].split_number,
              splitOfflineID: getRandomString(),
              service_charge:
                copySplitArr[splitPayIndex].service_charge -
                leftOutServiceCharge,
              sales_tax:
                copySplitArr[splitPayIndex].sales_tax - leftOutSalesTax,
              split_items: leftOutItemArr,
              tips: 0,
            };
            copySplitArr.push(leftOutPay);
          }
          /* left out amount END*/
          const paidAmount = leftOutAmt;
          const splitServiceCharge = leftOutServiceCharge;
          const splitSalesTax = leftOutSalesTax;
          copySplitArr[splitPayIndex].isPaid = 1;
          copySplitArr[splitPayIndex].method_type_id =
            paymentMethodObj?.method_type_id ?? "";
          copySplitArr[splitPayIndex].payment_method =
            paymentMethodObj?.payment_method_name ?? "";
          copySplitArr[splitPayIndex].payment_type =
            paymentMethodObj?.payment_method_name ?? "";
          copySplitArr[splitPayIndex].payment_method_id =
            paymentMethodObj?.payment_method_id ?? "";
          copySplitArr[splitPayIndex].splitPrice = paidAmount;
          copySplitArr[splitPayIndex].cardNumber =
            paymentMethodDetails?.paymentCard?.last4;
          copySplitArr[splitPayIndex].cardType =
            paymentMethodDetails?.paymentCard?.brand;
          copySplitArr[splitPayIndex].cardmerchantID = cardmerchantID;
          copySplitArr[splitPayIndex].cardname =
            paymentMethodDetails?.paymentCard?.brand;
          copySplitArr[splitPayIndex].tips = paymentTip;
          copySplitArr[splitPayIndex].paid_amount = getTotalAmount();
          copySplitArr[splitPayIndex].service_charge = splitServiceCharge;
          copySplitArr[splitPayIndex].sales_tax = splitSalesTax;
          copySplitArr[splitPayIndex].split_items = spItemArr;
          copySplitArr[splitPayIndex].transaction_id =
            payCardDetails?.ipgTransactionId;
          copySplitArr[splitPayIndex].fiserv_order_id = payCardDetails?.orderId;
          copySplitArr[splitPayIndex].cardHolderName =
            document.getElementById("card-holder-name").value;
          copySplitArr[splitPayIndex].split_finish_time = moment().format(
            "yyyy-MM-DD HH:mm:ss"
          );
        } else if (splitType == 3) {
          const paidAmount = parseFloat(customSplitInput);
          let leftOutPerc = 0;
          /* left out amount */
          const leftOutAmt =
            paidAmount > cartAmount
              ? paidAmount - cartAmount
              : cartAmount - paidAmount;
          if (leftOutAmt > 0) {
            leftOutPerc = howMuchPercentage(leftOutAmt, cartAmount, 4);
            console.log(
              "tsefsdfasdrweweq:",
              leftOutPerc,
              leftOutAmt,
              paidAmount,
              cartAmount
            );
            const leftOutServiceCharge = percentage(
              leftOutPerc,
              copySplitArr[splitPayIndex].service_charge,
              4
            );
            const leftOutSalesTax = percentage(
              leftOutPerc,
              copySplitArr[splitPayIndex].sales_tax,
              4
            );
            const leftOutPay = {
              ...copySplitArr[splitPayIndex],
              splitPrice: parseRound(leftOutAmt),
              split_number: ++copySplitArr[splitPayIndex].split_number,
              splitOfflineID: getRandomString(),
              service_charge: leftOutServiceCharge,
              sales_tax: leftOutSalesTax,
              tips: 0,
            };
            copySplitArr.push(leftOutPay);
          }
          /* left out amount END*/
          const splitServiceCharge = percentage(
            100 - leftOutPerc,
            copySplitArr[splitPayIndex].service_charge,
            4
          );
          const splitSalesTax = percentage(
            100 - leftOutPerc,
            copySplitArr[splitPayIndex].sales_tax,
            4
          );
          copySplitArr[splitPayIndex].isPaid = 1;
          copySplitArr[splitPayIndex].method_type_id =
            paymentMethodObj?.method_type_id ?? "";
          copySplitArr[splitPayIndex].payment_method =
            paymentMethodObj?.payment_method_name ?? "";
          copySplitArr[splitPayIndex].payment_type =
            paymentMethodObj?.payment_method_name ?? "";
          copySplitArr[splitPayIndex].payment_method_id =
            paymentMethodObj?.payment_method_id ?? "";
          copySplitArr[splitPayIndex].splitPrice = paidAmount;
          copySplitArr[splitPayIndex].cardNumber =
            paymentMethodDetails?.paymentCard?.last4;
          copySplitArr[splitPayIndex].cardType =
            paymentMethodDetails?.paymentCard?.brand;
          copySplitArr[splitPayIndex].cardmerchantID = cardmerchantID;
          copySplitArr[splitPayIndex].cardname =
            paymentMethodDetails?.paymentCard?.brand;
          copySplitArr[splitPayIndex].tips = paymentTip;
          copySplitArr[splitPayIndex].paid_amount = getTotalAmount();
          copySplitArr[splitPayIndex].service_charge = splitServiceCharge;
          copySplitArr[splitPayIndex].sales_tax = splitSalesTax;
          copySplitArr[splitPayIndex].transaction_id =
            payCardDetails?.ipgTransactionId;
          copySplitArr[splitPayIndex].fiserv_order_id = payCardDetails?.orderId;
          copySplitArr[splitPayIndex].cardHolderName =
            document.getElementById("card-holder-name").value;
          copySplitArr[splitPayIndex].split_finish_time = moment().format(
            "yyyy-MM-DD HH:mm:ss"
          );
        }
      }
      // copySplitArr = copySplitArr.map((newSplit) => ({
      //   ...newSplit,
      //   method_type_id: paymentMethodObj?.method_type_id ?? "",
      //   payment_method: paymentMethodObj?.payment_method_name ?? "",
      //   payment_method_id: paymentMethodObj?.payment_method_id ?? "",
      // }));
      dispatch(
        changePaymentDetail({
          splitPaymentArr: copySplitArr,
        })
      );
      const totalTip = checkArray(copySplitArr)
        ? copySplitArr.reduce((a, b) => a + b?.tips ?? 0, 0)
        : 0;
      const newPaymentBody = deepCopy(paymentBody);
      newPaymentBody.tips = totalTip;
      // Whole Bill
      if (paymentType == "whole") {
        newPaymentBody.paid_amount = getTotalAmount();
        newPaymentBody.finishTime = moment().format("yyyy/MM/DD HH:mm:ss");
        newPaymentBody.method_type_id = paymentMethodObj?.method_type_id ?? "";
        newPaymentBody.payment_method =
          paymentMethodObj?.payment_method_name ?? "";
        newPaymentBody.payment_type = [];
        // paymentMethodObj?.payment_method_name ?? ""; //commneted only for apple pay
        newPaymentBody.payment_method_id =
          paymentMethodObj?.payment_method_id ?? "";
        newPaymentBody.cardNumber = paymentMethodDetails?.paymentCard?.last4;
        newPaymentBody.cardType = paymentMethodDetails?.paymentCard?.brand;
        newPaymentBody.cardmerchantID = cardmerchantID;
        newPaymentBody.cardname = paymentMethodDetails?.paymentCard?.brand;
        newPaymentBody.isPrepaid = "yes";
        newPaymentBody.order_status = "complete";
        newPaymentBody.transaction_id = payCardDetails?.ipgTransactionId;
        newPaymentBody.fiserv_order_id = payCardDetails?.orderId;
        newPaymentBody.cardNumber = paymentMethodDetails?.paymentCard?.last4;
        newPaymentBody.cardType = paymentMethodDetails?.paymentCard?.brand;
        newPaymentBody.cardmerchantID = cardmerchantID;
        newPaymentBody.cardname = paymentMethodDetails?.paymentCard?.brand;
        newPaymentBody.tips = paymentTip;
        newPaymentBody.paid_amount = getTotalAmount();
        newPaymentBody.cardHolderName =
          document.getElementById("card-holder-name").value;
        // dispatch(toggleShowCompletePage(true));
      } else if (paymentType == "split") {
        let splitOrderStatus = paymentOpenOrders?.[0]?.order_status ?? "open";
        if (
          checkArray(copySplitArr) &&
          copySplitArr.findIndex(
            (p) => p?.isPaid == 0 || p?.isPaid == false
          ) === -1
        ) {
          splitOrderStatus = "complete";
          // dispatch(toggleShowCompletePage(true));
        }
        const split_type_name =
          splitType == 1
            ? "Split Evenly"
            : splitType == 2
              ? "Split By Item"
              : splitType == 3
                ? "Split Manually"
                : "";

        newPaymentBody.order_status = splitOrderStatus;
        newPaymentBody.payment_type = copySplitArr;
        newPaymentBody.split_type_name = split_type_name;
        newPaymentBody.paid_amount = checkArray(copySplitArr)
          ? copySplitArr.reduce(
            (a, b) => a + parseFloat(b?.paid_amount ?? 0),
            0
          )
          : 0;
      }
      newPaymentBody.isPrepaid = "yes";
      newPaymentBody.transaction_id = payCardDetails?.ipgTransactionId;
      newPaymentBody.fiserv_order_id = payCardDetails?.orderId;
      newPaymentBody.cardNumber = paymentMethodDetails?.paymentCard?.last4;
      newPaymentBody.cardType = paymentMethodDetails?.paymentCard?.brand;
      newPaymentBody.cardmerchantID = cardmerchantID;
      newPaymentBody.cardname = paymentMethodDetails?.paymentCard?.brand;
      newPaymentBody.tips = paymentTip;
      newPaymentBody.paid_amount = getTotalAmount();
      newPaymentBody.cardHolderName =
        document.getElementById("card-holder-name").value;
      newPaymentBody.last_updated = moment().format("yyyy/MM/DD HH:mm:ss");
      dispatch(
        changePaymentDetail({
          paymentBody: newPaymentBody,
          selected_payment_method: paymentMethodObj,
        })
      );
      dispatch(orderPlaceDetail(newPaymentBody, false));
    }
    togglePaySuccessPopUp();
    setSuccessPageState({
      cartAmount,
      totalAmount: getTotalAmount(),
      splitPayIndex,
    });
    dispatch(
      showNotificationWithTimeout(`Payment successfully done.`, "success")
    );
    // const orderId = recent_order_detail?.order_list?.[0]?.order_id ?? undefined;
    // console.log("checkkkkkkkkkasdsd:",checkNull(mail_order_detail, false),orderId);
    // if (checkNull(mail_order_detail, false) && orderId) {
    //   const copyOrderDetail = deepCopy(mail_order_detail);
    //   copyOrderDetail.billId = orderId;
    //   dispatch(sendPaymentMail({ order_detail: copyOrderDetail }));
    // }
    dispatch(paymentInitialState());
  };

  useEffect(() => {
    dispatch(paymentInitialState());

    return () => {
      dispatch(paymentInitialState());
    };
  }, []);
  const [googlePayLoaded, setGooglePayLoaded] = useState(false);

  useEffect(() => {
    // Load the Google Pay API library
    const script = document.createElement("script");
    script.src = "https://pay.google.com/gp/p/js/pay.js";
    script.onload = () => setGooglePayLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // useEffect(()=>{
  //   setCustomSplitInput(parseRound(cartAmount))
  // },[])

  useEffect(() => {
    changeSplitPayTip(selectedTip);
  }, [selectedTip]);
  useEffect(() => {
    if (is_next_portion) {
      if (document.getElementById("card-holder-name"))
        document.getElementById("card-holder-name").value = "";
      if (document.getElementById("card-number-element"))
        document.getElementById("card-number-element").value = "";
      if (document.getElementById("card-expiry-element"))
        document.getElementById("card-expiry-element").value = "";
      if (document.getElementById("card-cvc-element"))
        document.getElementById("card-cvc-element").value = "";

      setSelectedTip(0);
      dispatch(changePaymentDetail({ is_next_portion: false }));
    }
  }, [is_next_portion]);

  const [newPayment, setNewPayment] = useState("");

  const checkCardType = (card_number) => {
    let imgName = "card-img";
    let postImgName = "";
    if (checkNull(card_number, false)) {
      const visaCards = creditCardType(card_number);
      if (checkArray(visaCards)) {
        const cardsType = visaCards?.[0]?.type ?? "";
        // const index = table_detail?.QrPaymentMethod?.findIndex(
        //   (el) => el.payment_method_name.toLowerCase() === cardsType.toLowerCase()
        // );
   
        
        switch (cardsType) {
          case "visa":
            postImgName = "visa";
            setCardDetails(table_detail?.QrPaymentMethod?.findIndex(
              (el) => el.payment_method_name.toLowerCase() === "visa".toLowerCase()
            ))
            
            break;
          case "mastercard":
            postImgName = "mastercard";
          setCardDetails(table_detail?.QrPaymentMethod?.findIndex(
            (el) => el.payment_method_name.toLowerCase() === "MasterCard ".toLowerCase()
          ))
            break;
          case "american-express":
            postImgName = "american-express";
            setCardDetails(table_detail?.QrPaymentMethod?.findIndex(
              (el) => el.payment_method_name.toLowerCase() ===  "AMEX".toLowerCase()
            ))
            break;
          case "diners-club":
            postImgName = "diners-club";
            break;
          case "discover":
            postImgName = "discover";
            break;
          case "jcb":
            postImgName = "jcb";
            break;
          case "unionpay":
            postImgName = "unionpay";
            break;
          default:
            break;
        }
      }
    }
    setCardImgClass(
      `${imgName}${checkNull(postImgName, false) ? " " + postImgName : ""}`
    );
  };

  function validations(currentInput = null) {
    console.log("currentInput--------------", currentInput)
    const first_name =
      document.getElementById("first-name").value || como_details?.first_name;
    const last_name =
      document.getElementById("last-name").value || como_details?.last_name;
    const customer_phone = "";
    const country_code = "";
    const customer_email =
      document.getElementById("user-email").value ||
      como_details?.contact_email;
    const customer_holder_name =
      document.getElementById("card-holder-name").value;
    const card_number = document.getElementById("card-number-element").value;
    const card_expiry = document.getElementById("card-expiry-element").value;
    const card_cvc = document.getElementById("card-cvc-element").value;
    const payment_method = table_detail?.QrPaymentMethod[0] // document.getElementById("payment-method").value;
    const newErrorInputs =
      checkArray(Object.keys(errorInputs)) && currentInput !== null
        ? { ...errorInputs, [currentInput]: false }
        : {
          card_cvc: false,
          card_expiry: false,
          card_number: false,
          customer_email: false,
          customer_holder_name: false,
          first_name: false,
          last_name: false,
          payment_method: false,
        };
    function validateEmail(email) {
      var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      console.log(re.test(email), email);
      if (!re.test(email)) {
        dispatch(
          showNotificationWithTimeout("Incorrect email address", "error")
        );
        return;
      }
      return re.test(email);
    }

    if (
      (payment_method === undefined ||
        payment_method == "" ||
        !table_detail?.QrPaymentMethod?.[payment_method]) &&
      (currentInput == null || currentInput == "payment_method")
    ) {
      // dispatch(showNotificationWithTimeout("Select payment method", "error"));
      newErrorInputs.payment_method = true;
      // return;
    }
    if (!first_name && (currentInput == null || currentInput == "first_name")) {
      dispatch(showNotificationWithTimeout("Enter first name", "error"));
      newErrorInputs.first_name = true;
      return;
    }
    if (!last_name && (currentInput == null || currentInput == "last_name")) {
      dispatch(showNotificationWithTimeout("Enter last name", "error"));
      newErrorInputs.last_name = true;
      return;
    }
    // if (!customer_phone) {
    //   dispatch(showNotificationWithTimeout("Enter phone number", "error"));
    //   return;
    // }
    // if (!/^[0-9]+$/.test(customer_phone) || customer_phone.length > 10) {
    //   dispatch(
    //     showNotificationWithTimeout("Enter a valid phone number", "error")
    //   );
    //   return;
    // }
    if (
      !customer_email &&
      (currentInput == null || currentInput == "customer_email")
    ) {
      dispatch(showNotificationWithTimeout("Enter email address", "error"));
      newErrorInputs.customer_email = true;
      return;
    }
    // if (!validateEmail(customer_email)) return;
    if (
      (currentInput == null || currentInput == "customer_email") &&
      !validateEmail(customer_email)
    ) {
      newErrorInputs.customer_email = true;
    }
    if(payment_method==2){
      if (
        !customer_holder_name &&
        (currentInput == null || currentInput == "customer_holder_name")
      ) {
        dispatch(showNotificationWithTimeout("Enter card holder name", "error"));
        newErrorInputs.customer_holder_name = true;
        return;
      }
      if (
        (!card_number || (card_number && card_number.length < 18)) &&
        (currentInput == null || currentInput == "card_number")
      ) {
        dispatch(showNotificationWithTimeout("Enter card number", "error"));
        newErrorInputs.card_number = true;
        return;
      }
      if (
        (!card_expiry || (card_expiry && card_expiry.length < 5)) &&
        (currentInput == null || currentInput == "card_expiry")
      ) {
        dispatch(showNotificationWithTimeout("Enter card expiry", "error"));
        newErrorInputs.card_expiry = true;
        return;
      }
      if (
        (!card_cvc || (card_cvc && card_cvc.length < 3)) &&
        (currentInput == null || currentInput == "card_cvc")
      ) {
        dispatch(showNotificationWithTimeout("Enter card cvc", "error"));
        newErrorInputs.card_cvc = true;
        return;
      }
      if (
        checkNull(card_expiry, false) &&
        (currentInput == null || currentInput == "card_expiry")
      ) {
        const expiryArr = card_expiry.split("/");
        const currentYear = parseInt(moment().format("YY"));
        const currentMonth = parseInt(moment().format("MM"));
        const expiryMonth = parseInt(expiryArr[0]);
        const expiryYear = parseInt(expiryArr[1]);
        if (!(expiryMonth > 0 && expiryMonth <= 12)) {
          dispatch(
            showNotificationWithTimeout("Enter valid expiry month", "error")
          );
          newErrorInputs.card_expiry = true;
          return;
        }
  
        if (currentYear > expiryYear) {
          dispatch(
            showNotificationWithTimeout("Enter valid expiry year", "error")
          );
          newErrorInputs.card_expiry = true;
          return;
        }
        if (currentYear == expiryYear && currentMonth > expiryMonth) {
          dispatch(showNotificationWithTimeout("Card has been expired", "error"));
          newErrorInputs.card_expiry = true;
          return;
        }
      }
    }
    changeErrorInputs(newErrorInputs);
    console.log("newErrorInputs", newErrorInputs);
    // if (
    //   checkArray(
    //     Object.keys(newErrorInputs).filter((p) => newErrorInputs?.[p] === true)
    //   )
    // ) {
    //   dispatch(
    //     showNotificationWithTimeout(
    //       "Please fill all mandatory details",
    //       "error"
    //     )
    //   );
    //   return;
    // }
    return true;
  }

  useEffect(() => {
    try {
      if (paymentIntent) {
        // console.log("paymentIntentttttttttttt", paymentIntent);
        completedPaymentHandler(paymentIntent);
      }
    } catch (ex) {
      console.log("confirmCardPaymentEx", ex);
    }
  }, [paymentIntent]);

  const parseHTML = (html) => {
    const rootEl = document.getElementById("root");
    if (rootEl) {
      // const p = document.createElement("template");
      // p.innerHTML = html;
      // const doc = new DOMParser().parseFromString(html, "text/xml");
      rootEl.appendChild(document.createRange().createContextualFragment(html));
      // console.log("paymentReqttttttttttt2222",doc.firstChild.innerHTML); // => <a href="#">Link...
    }
    return rootEl;
  };

  const changeInnerText = (
    text = "Connecting to your bank servers securely.."
  ) => {
    const innertxt = document.getElementById("redirectedCon");
    if (innertxt) innertxt.innerText = text;
  };

  const setwaitingText = (
    text = "Please wait while we confirm your payment with you bank. Do not refresh or close this page"
  ) => {
    const innertxt = document.getElementById("redirectedCon");
    if (innertxt) innertxt.innerText = text;
  };

  const createRedirectForm = (url, creq) => {
    const paymentInfoCon = document.getElementById("redirectedCon");
    if (paymentInfoCon) {
      changeInnerText(
        "Connected! You will be redirected to your bank site shortly.. You can also click on the button below if not redirected automaticaly."
      );
      setLoaderImgVal(true);
      console.log("paymentInfoConnnnn", paymentInfoCon);
      const form = document.createElement("form");
      paymentInfoCon.appendChild(form);
      // document.body.appendChild(form); // Commented out since it's appended to paymentInfoCon
      form.setAttribute("target", "_blank");
      form.method = "POST";
      form.action = url;
      
      const element1 = document.createElement("INPUT");
      element1.name = "creq";
      element1.value = creq;
      element1.type = "hidden";
      form.appendChild(element1);
      
      const element2 = document.createElement("button");
      element2.setAttribute("name", "submit");
      element2.setAttribute("type", "submit");
      element2.setAttribute("value", "submit");
      element2.setAttribute("class", "input-btn mt-4 mb-0");
      element2.innerText = "Let's Go!";
      form.appendChild(element2);
      // element2.click()
      // Event listener for the button click
      element2.addEventListener('click', function(event) {
          // Hide all elements with class 'modal_body'
          setTimeout(()=>{
            getPaymentdata()
            setLoaderImgVal(false);
            setwaitingText();
          },100)
          
          form.submit()
          // Optional: Call a function to set waiting text
          
         
          
      
          // Note: Form submission will happen after this
      });
      
    }
    const getPaymentdata=()=>{
      setTimeout(() => {
        const getPaymentBody = {
          merchant_id: table_detail?.Merchant?.id,
          storeId: table_detail?.QrOrdering?.merchant_partnerid,
          transactionId: paymentReq?.ipgTransactionId,
        };
        dispatch(getPaymentDetail(getPaymentBody, true));
      }, 10000);
     
    }
    // const newwindow = window.open("", "payment", "height=400,width=400");

    // if (
    //   !newwindow ||
    //   newwindow.closed ||
    //   typeof newwindow.closed == "undefined" ||
    //   newwindow.outerHeight == 0 ||
    //   newwindow.outerWidth == 0
    // ) {
    //   dispatch(
    //     showNotificationWithTimeout(
    //       `Please allow popups from gogmgo.com in order to proceed add your bank OTP and proceed with the payment.`,
    //       "error"
    //     )
    //   );
    // } else {
    //   if (newwindow.focus) {
    //     newwindow.focus();
    //   }
    //   newwindow.document.body.appendChild(form);
    //   newwindow.document.forms[0].submit();
    // }
    return false;
  };
  const paymentFailedhandler = (errMsg = "Payment Decline or Failed") => {
    dispatch(paymentInitialState());
    dispatch(showNotificationWithTimeout(errMsg, "error"));
  };

  useEffect(() => {
    // console.log("paymentReq inside the useEffect",paymentReq)
    try {
      if (paymentReq) {
        console.log("payment Declinr check",paymentReq);
        
        if (pausePaymentProcess) {
          console.log("check What is PausePaymentProcess",pausePaymentProcess);
          
          setTimeout(() => {
            dispatch(
              updatePaymentDetail({
                merchant_id: table_detail?.Merchant?.id,
                transactionId: paymentReq?.ipgTransactionId,
                storeId: table_detail?.QrOrdering?.merchant_partnerid,
                payment: {
                  authenticationType: "Secure3D21AuthenticationUpdateRequest",
                  storeId: table_detail?.QrOrdering?.merchant_partnerid,
                  methodNotificationStatus: "NOT_EXPECTED",
                },
              })
            );
          }, 2000);
          // if (checkNull(paymentReq?.threeDSMethodData, false) || methodNotificationInx >= 2) {
          //   dispatch(
          //     updatePaymentDetail({
          //       merchant_id: table_detail?.Merchant?.id,
          //       transactionId: paymentReq?.ipgTransactionId,
          //       storeId: table_detail?.QrOrdering?.merchant_partnerid,
          //       payment: {
          //         authenticationType: "Secure3D21AuthenticationUpdateRequest",
          //         storeId: table_detail?.QrOrdering?.merchant_partnerid,
          //         methodNotificationStatus: "NOT_EXPECTED",
          //       },
          //     })
          //   );
          // } else {
            // setTimeout(() => {
            //   const getPaymentBody = {
            //     merchant_id: table_detail?.Merchant?.id,
            //     storeId: table_detail?.QrOrdering?.merchant_partnerid,
            //     transactionId: paymentReq?.ipgTransactionId,
            //   };
            //   dispatch(getPaymentDetail(getPaymentBody, true));
            // }, 1000);
          // }
        } 
        else {
          console.log("check What is PausePaymentProcess 2",pausePaymentProcess);
          if (updatedPaymentReq) {
            console.log("check What is updatedPaymentReq",updatedPaymentReq,paymentReq?.transactionStatus,paymentReq);
            // console.log("paymentReqttttttttttt222222", paymentReq);
            if (
              paymentReq?.transactionStatus === "APPROVED" ||
              paymentReq?.transactionResult === "APPROVED"
            ) {
              completedPaymentHandler(paymentReq);
            } 
            else if (paymentReq?.transactionStatus === "WAITING") {
              console.log("check What is updatedPaymentReq 2" ,updatedPaymentReq);
              const redirectUrl =
                paymentReq?.authenticationResponse?.params?.acsURL;
              if (redirectUrl) {
                // window.open(redirectUrl, "_blank");
                createRedirectForm(
                  redirectUrl,
                  paymentReq?.authenticationResponse?.params?.cReq
                );
              }
            } 
            else {
              // completedPaymentHandler(paymentReq);
              paymentFailedhandler();
            }
          } 
          else {
            console.log("paymentReqttttttttttt", paymentReq);
            if (
              paymentReq?.transactionStatus === "APPROVED" ||
              paymentReq?.transactionResult === "APPROVED"
            ) {
              completedPaymentHandler(paymentReq);
            } 
            else if (paymentReq?.transactionStatus === "WAITING") {
              const frameTemp =
                paymentReq?.authenticationResponse?.secure3dMethod?.methodForm;
              const redirectUrl =
                paymentReq?.authenticationResponse?.params?.acsURL;
              if (frameTemp) {
                const res = parseHTML(frameTemp);
                console.log("paymentReqttttttttttt11111", res);
                dispatch(changeLoadingStatus(true));
                dispatch(
                  changePaymentDetail({
                    pausePaymentProcess: true,
                    recallMethodNotification: true,
                    methodNotificationInx: 0,
                  })
                );
              } else if (redirectUrl) {
                dispatch(
                  changePaymentDetail({
                    updatedPaymentReq: true,
                  })
                );
                createRedirectForm(
                  redirectUrl,
                  paymentReq?.authenticationResponse?.params?.cReq
                );
                // window.open(redirectUrl, "_blank");
              }
            } 
            else {
              console.log("payment Declinr check",paymentReq);
              paymentFailedhandler();
            }
          }
        }
      }
    } catch (ex) {
      console.log("confirmCardPaymentEx", ex);
    }
  }, [pausePaymentProcess, methodNotificationInx, paymentReq]);

  const getBillNo = () => {
    const orderId = recent_order_detail?.order_list?.[0]?.order_id ?? undefined;
    let res = `N/A`;
    if (orderId) {
      res = `${orderId}-1`;
      if (paymentType == "split") {
        console.log("splittttPayment:", splitPaymentArr?.[splitPayIndex]);
        res = `${orderId}-${splitPaymentArr?.[splitPayIndex]?.split_number}`;
      }
    }
    return res;
  };

  const getItemsList = () => {
    let res = [];
    if (checkArray(paymentBody?.menu_item)) {
      res = paymentBody?.menu_item.map((item) => {
        return {
          quantity: item?.menu_item_quantity ?? 0,
          item_name: checkNull(item?.menu_item_name),
          price: `${table_detail?.currency ?? ""}${parseRound(
            parseFloat(item?.menu_item_price) *
            parseInt(item?.menu_item_quantity ?? 0)
          )}`,
        };
      });
    }
    return res;
  };
console.log("Table Details With Card Detaisl",table_detail);

  const handleSubmit = async () => {
    changeInnerText();
    setLoaderImgVal(false);
    dispatch(changeLoadingStatus(true));
    dispatch(changePaymentDetail({ selected_payment_method: null }));
    const first_name = document.getElementById("first-name").value;
    const last_name = document.getElementById("last-name").value;
    const customer_phone = "";
    const country_code = "";
    const customer_email = document.getElementById("user-email").value;
    const customer_holder_name =
      document.getElementById("card-holder-name").value;
    const card_number = document.getElementById("card-number-element").value;
    const card_expiry = document.getElementById("card-expiry-element").value;
    const card_cvc = document.getElementById("card-cvc-element").value;
    const payment_method = table_detail?.QrPaymentMethod[cardDetails];
    const card_ex_month = card_expiry.split("/")?.[0] ?? "";
    const card_ex_year = card_expiry.split("/")?.[1] ?? "";

    if (!validations()) {
      dispatch(changeLoadingStatus(false));
      return;
    }
    const paymentMethodObj = payment_method;
    const mailOrderDetail = {
      email: customer_email,
      logo: table_detail?.Merchant?.logo,
      date: moment().format("MMMM D, yyyy  h:mma"),
      paid_amount: `${table_detail?.currency ?? ""}${getTotalAmount()}`,
      storeName: table_detail?.Merchant?.account_name,
      item_detail: getItemsList(),
      billId: getBillNo(),
      table_number: table_detail?.PosTableMaster?.table_number,
      payment_method_name: checkNull(
        paymentMethodObj?.payment_method_name,
        "N/A"
      ),
      subTotal: `${table_detail?.currency ?? ""}${parseRound(
        paymentBody?.order_total
      )}`,
      discount: `(${table_detail?.currency ?? ""}${parseRound(
        paymentBody?.discount
      )})`,
      service_charge: `${table_detail?.currency ?? ""}${parseRound(
        paymentBody?.service_charge
      )}`,
      sales_tax: `${table_detail?.currency ?? ""}${parseRound(
        paymentBody?.sales_tax
      )}`,
      tips: `${table_detail?.currency ?? ""}${parseRound(selectedTip ?? 0)}`,
    };
    const tempId = getRandomString();
    const paymentIntentBody = {
      merchant_id: table_detail?.Merchant?.id,
      temp_transaction_id: tempId,
      order_detail: mailOrderDetail,
      payment: {
        requestType: "PaymentCardSaleTransaction",
        storeId: table_detail?.QrOrdering?.merchant_partnerid,
        transactionOrigin: "ECOM",
        transactionAmount: {
          total: getTotalAmount(),
          currency: "SGD",
        },
        paymentMethod: {
          paymentCard: {
            number: card_number.replace(/ /g, ""),
            securityCode: card_cvc,
            expiryDate: {
              month: card_ex_month,
              year: card_ex_year,
            },
          },
        },
        authenticationRequest: {
          authenticationType: "Secure3D21AuthenticationRequest",
          termURL: `${process?.env?.REACT_APP_FISERV_TERM_URL}/finserve/payment_term?tempId=${tempId}&redirectUrl=${window.location}`,
          methodNotificationURL: `${process?.env?.REACT_APP_FISERV_API_URL}/finserve/payment_confirm_webhook?tempId=${tempId}`,
          // challengeIndicator: "01",
          // challengeWindowSize: "03",
        },
      },
    };
    // dispatch(getPaymentIntentDetail({
    //   headers: paymentIntentHeaders,
    //   body: paymentIntentBody
    // }))
    if (!checkNull(recent_order_detail?.order_list?.[0]?.order_id, false)) {
      dispatch(changePaymentDetail({ mail_order_detail: mailOrderDetail }));
    }
    dispatch(getPaymentDetail(paymentIntentBody));
  };

  const paymentHandler = () => {
    if (payWith == PAYMENT_TYPE_ENUM.APPLE_PAY && applePayValidation()) {
      applePayButtonClicked(
        parseRound(getTotalAmount()),
        payWith,
        setPayWith,
        selectedTip,
        splitPayIndex,
        paymentTip,
        cartAmount,
        customSplitInput,
        paymentBody,
        recent_order_detail,
        paymentType,
        splitPaymentArr,
        splitType,
        table_detail,
        dispatch,
        setSuccessPageState
      );
    } else if (payWith == PAYMENT_TYPE_ENUM.CARD_PAY) {
      handleSubmit();
    } else if (
      payWith == PAYMENT_TYPE_ENUM.GOOGLE_PAY &&
      applePayValidation()
    ) {
      handleGooglePayClick(
        parseRound(getTotalAmount()),
        payWith,
        setPayWith,
        selectedTip,
        splitPayIndex,
        paymentTip,
        cartAmount,
        customSplitInput,
        paymentBody,
        recent_order_detail,
        paymentType,
        splitPaymentArr,
        splitType,
        table_detail,
        googlePayLoaded,
        setGooglePayLoaded,
        dispatch
      );
    }
  };

  const increOrDecreSplitItems = (
    indx,
    spItem,
    isDecrease = false,
    isZero = false
  ) => {
    let newVal = isZero ? 0 : spItem.quantity;
    if (!isZero) {
      if (isDecrease) {
        if (newVal > 0) {
          newVal -= 1;
        }
      } else {
        if (newVal < spItem.max_quantity) {
          newVal += 1;
        }
      }
    }
    const copySplitArr = deepCopy(splitPaymentArr);
    const copyItemArr = deepCopy(splitPaymentArr?.[splitPayIndex]?.split_items);
    copyItemArr[indx].quantity = newVal;
    copySplitArr[splitPayIndex].split_items = copyItemArr;
    dispatch(changePaymentDetail({ splitPaymentArr: copySplitArr }));
  };




  const getSplitItems = () => {
    let res = null;
    const splitItems = splitPaymentArr?.[splitPayIndex]?.split_items;
    if (checkArray(splitItems)) {
      res = splitItems.map((spItem, spIndx) => {
        console.log("spItem---------", spItem);
        return (
          <li>
            <span className="col-6"> {spItem?.menu_item_name} </span>
            <i className=" col-2 price-split">
              {" "}
              {"+$" +
                parseRound(spItem?.quantity * spItem?.menu_item_price)}{" "}
            </i>

            <div
              // className={`col-4 pr-0 d-flex${
              //   spItem?.quantity == spItem?.max_quantity
              //     ? " counts-sec-end"
              //     : " counts-sec"
              // }`}
              className={`col-4 pr-0 d-flex counts-sec`}
            >
              {/* {spItem?.quantity == spItem?.max_quantity ? (
                <>
                  <button
                    className={`filled`}
                    onClick={() =>
                      increOrDecreSplitItems(spIndx, spItem, true, true)
                    }
                  ></button>
                </>
              ) : ( */}
              <>
                {/* <button
                    className={`dec${spItem?.quantity > 0 ? "" : " disabled"}`}
                    disabled={spItem?.quantity < 1}
                    onClick={() => increOrDecreSplitItems(spIndx, spItem, true)}
                  >
                    {" "}
                  </button> */}
                <button
                  disabled={spItem?.quantity == 0}
                  onClick={() => increOrDecreSplitItems(spIndx, spItem, true)}
                  className="remove-btn bg-none d-flex align-items-center justify-content-center"
                >
                  <StyledSVG
                    color={
                      table_detail?.QrOrdering?.brand_main_color || "#8b8b8b"
                    }
                    src={Dec}
                    width={"24px"}
                  />{" "}
                </button>

                <strong className="count"> {spItem?.quantity} </strong>
                {/* <button
                    className="inc"
                    onClick={() => increOrDecreSplitItems(spIndx, spItem)}
                  >
                    {" "}
                  </button> */}
                <button
                  disabled={spItem?.quantity == spItem?.max_quantity}
                  onClick={() => increOrDecreSplitItems(spIndx, spItem)}
                  className="remove-btn bg-none d-flex align-items-center justify-content-center"
                >
                  <StyledSVG
                    color={
                      table_detail?.QrOrdering?.brand_main_color || "#8b8b8b"
                    }
                    src={Inc}
                    width={"24px"}
                  />{" "}
                </button>
              </>
              {/* )} */}
            </div>
          </li>
        );
      });
    }
    return res;
  };

  const applePayValidation = (currentInput = null) => {
    const first_name = document.getElementById("first-name").value;
    const last_name = document.getElementById("last-name").value;
    const customer_email = document.getElementById("user-email").value;

    function validateEmail(email) {
      var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      console.log(re.test(email), email);
      if (!re.test(email)) {
        // dispatch(
        //   showNotificationWithTimeout("Incorrect email address", "error")
        // );
        return;
      }
      return re.test(email);
    }
    const newErrorInputs =
      checkArray(Object.keys(errorInputs)) && currentInput !== null
        ? { ...errorInputs, [currentInput]: false }
        : {
          customer_email: false,
          first_name: false,
          last_name: false,
          // payment_method: false,
        };

    if (!first_name && (currentInput == null || currentInput == "first_name")) {
      // dispatch(showNotificationWithTimeout("Enter first name", "error"));
      newErrorInputs.first_name = true;
      // return;
    }
    if (!last_name && (currentInput == null || currentInput == "last_name")) {
      // dispatch(showNotificationWithTimeout("Enter last name", "error"));
      newErrorInputs.last_name = true;
      // return;
    }

    if (
      !customer_email &&
      (currentInput == null || currentInput == "customer_email")
    ) {
      // dispatch(showNotificationWithTimeout("Enter email address", "error"));
      newErrorInputs.customer_email = true;
      // return;
    }

    if (
      (currentInput == null || currentInput == "customer_email") &&
      !validateEmail(customer_email)
    ) {
      newErrorInputs.customer_email = true;
    }

    changeErrorInputs(newErrorInputs);
    if (
      checkArray(
        Object.keys(newErrorInputs).filter((p) => newErrorInputs?.[p] === true)
      )
    ) {
      dispatch(
        showNotificationWithTimeout(
          "Please fill all mandatory details",
          "error"
        )
      );
      return;
    }
    return true;
  };
  const appleRef = useRef(null);

  const scrollForApplePay = () => {
    if (appleRef.current) {
      appleRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const cardPayRef = useRef(null);

  const scrollForCardPay = () => {
    if (cardPayRef.current) {
      cardPayRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const getApplePayButton = () => {
    console.log("google paye check",table_detail?.QrOrdering?.enable_apple_pay == "yes");
    
    if (table_detail?.QrOrdering?.enable_apple_pay == "yes") {
      if (!window.ApplePaySession) {
        return;
      } 
    else {
    return (
      <button
        className={`apple_btn py-2 mb-3 paymentText ${payWith == PAYMENT_TYPE_ENUM.APPLE_PAY
            ? "new_apple_btn_border"
            : "new_apple_btn"
          }`}
        style={{
          border: `2px solid ${payWith == PAYMENT_TYPE_ENUM.APPLE_PAY
              ? table_detail?.QrOrdering?.brand_highlight_color
              : "#D8D8D8"
            }`,
        }}
        onClick={() => {
          scrollForApplePay();
          setPayWith(PAYMENT_TYPE_ENUM.APPLE_PAY);
          // applePayButtonClicked();
        }}
        disabled={getTotalAmount() == 0}
      >
        <span className="paymentText ml-3 ">Pay with </span>
        <ImageTag src={applePay} alt="exit" className="pay-apple" />
      </button>
    );
      }
    }
  };

  const getGooglePayButton = () => {
    if (table_detail?.QrOrdering?.enable_google_pay == "yes") {
      if (window.PaymentRequest) {
        return (
          <button
            className={`apple_btn  py-2 mb-3 paymentText ${payWith == PAYMENT_TYPE_ENUM.GOOGLE_PAY
                ? "new_apple_btn_border"
                : "new_apple_btn"
              }`}
            style={{
              border: `2px solid ${payWith == PAYMENT_TYPE_ENUM.GOOGLE_PAY
                  ? table_detail?.QrOrdering?.brand_highlight_color
                  : "#D8D8D8"
                }`,
            }}
            onClick={() => {
              scrollForApplePay();
              setPayWith(PAYMENT_TYPE_ENUM.GOOGLE_PAY);
              // applePayButtonClicked();
            }}
            disabled={getTotalAmount() == 0}
          >
            <span className="paymentText ml-3">Pay with </span>
            <ImageTag src={googelPay} alt="exit" className="pay-google" />
          </button>
        );
      }
    }
  };

  return (
    <>
      {/* {console.log("errorInputs", errorInputs)} */}
      {/* {console.log("applePayRequest:", applePayRequest)} */}
      {splitType === 3 && (
        <>
          <div className="Px-15 tt-p custom-ammount">
            <StyledSVG src={Settings} width={"18px"} />
            <span className="ml-2 payment-header">Custom Amount</span>
          </div>
          <div className="details-sec paying-amount bg-white Px-15 py-4">
            <h3 class="enter-ammount">Enter custom amount? </h3>
            <div className=" bg-white pt-5 pb-3 my-0 ">
              <div class="form-group">
                <span className="dollars"> $ </span>
                <NumberFormat
                  prefix={""}
                  // displayType="text"
                  className={`form-control-payment`}
                  value={customSplitInput == 0 ? "" : customSplitInput}
                  inputmode="decimal"
                  decimalScale={2}
                  fixedDecimalScale={true}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    // formattedValue = $2,223
                    // value ie, 2223
                    setCustomSplitInput(checkNull(value, false) ? value : 0);
                  }}
                  isAllowed={(values, sourceInfo) => {
                    const { value } = values;
                    return value <= parseRound(cartAmount, 2, true);
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-cetner">
              <span className="your-payment"> Remaining </span>
              <strong className="your-payment">
                <small className="fw-bolder your-payment">$</small>
                {parseRound(cartAmount - getTotalAmount())}
              </strong>
            </div>
            {getPaymentSoFarList()}
          </div>
        </>
      )}
      {/* <h3 class="title default-bg py-4 Px-15 mt-5 ">
        <StyledSVG src={payment} width={20} />
        <span className="ml-2">Payment Method</span>
      </h3> */}
      <div className="paymentInfo  " id="paymentInfoCon">
        {splitType == 2 && (
          <>
            <div className="d-flex align-items-center Px-15 tt-p">
              <StyledSVG src={Square} />
              <span class="title Px-15 payment-header"> Select Your Items </span>
            </div>

            <ul class="billing-list selectItems Px-15  py-5  bg-white">
              <span>Items</span>
              {getSplitItems()}
              {/* <li>
                <span> Salmon Bowl </span>
                <div className="col-4 pr-0 d-flex counts-sec-end">
                  <button className="filled"> </button>
                </div>
              </li>
              <li class="total border-bottom-0 border-top-2">
                <span> YOUR PORTION </span>
                <strong> $7.00 </strong>
              </li> */}

              <li className="">
                <span className="your-payment"> Your Payment </span>
                <strong className="your-payment">
                  <small className="fw-bolder your-payment">$</small>
                  {parseRound(getTotalAmount())}
                </strong>
              </li>
              {getPaymentSoFarList()}
            </ul>
          </>
        )}
        <div ref={wholeBill} className="Px-15 d-flex payment-info tt-p">
          <StyledSVG src={moneyBillWave} />
          <span className="ml-2 payment-header ">Payment Info</span>
        </div>
        <div className="bg-white Px-15">
          <div className="details-sec py-2">
            <h3 className="bg-white my-2 details-title">
              <StyledSVG src={userPayment} width={20} />
              <span className="mx-1"></span> Your Details
            </h3>
            <div className="form-group mt-2">
              <label> First name </label>
              <input
                className={`form-control-payment${errorInputs?.first_name ? " invalid" : ""
                  }`}
                type="text"
                id="first-name"
                defaultValue={como_details?.first_name}
                onBlur={(e) => validations("first_name")}
              />
            </div>
            <div className="form-group">
              <label> Last name </label>
              <input
                className={`form-control-payment${errorInputs?.last_name ? " invalid" : ""
                  }`}
                type="text"
                id="last-name"
                onBlur={(e) => validations("last_name")}
                defaultValue={como_details?.last_name}
              />
            </div>
            <div className="form-group mb-2">
              <label> Email </label>
              <input
                className={`form-control-payment${errorInputs?.customer_email ? " invalid" : ""
                  }`}
                type="text"
                id="user-email"
                defaultValue={como_details?.contact_email}
                onBlur={(e) => validations("customer_email")}
              />
            </div>
          </div>
        </div>

        <div className="bg-white mt-5">
          <div className="details-sec Px-15 py-4">
            {/* <div className="form-group border-bottom-3 pb-4 text-center">
              <label className="mb-0">
                {" "}
                <strong> ${parseRound(cartAmount)} </strong>{" "}
              </label>
              <label> Bill Total </label>
            </div> */}
            <div>
              <span className="d-flex align-items-center">
                {" "}
                <StyledSVG src={creditcard} width={20} />{" "}
                <span className="mx-2 payment-title">Payment Method</span>
              </span>
              <label className="details-lable mt-2">
                How would you like to pay?{" "}
              </label>
              <div
                ref={appleRef}
                onClick={() => {
                  console.log("dhfs gdhfsf fhjchenuck ==============>", PAYMENT_TYPE_ENUM.CARD_PAY);

                  scrollForCardPay();
                  setPayWith(PAYMENT_TYPE_ENUM.CARD_PAY);
                }}
                className={`apple_btn py-3 mb-3 payment-card ${payWith == PAYMENT_TYPE_ENUM.CARD_PAY
                    ? "new_apple_btn_border"
                    : "new_apple_btn"
                  }`}
                style={{
                  border: `2px solid ${payWith == PAYMENT_TYPE_ENUM.CARD_PAY
                      ? table_detail?.QrOrdering?.brand_highlight_color
                      : "#D8D8D8"
                    }`,
                }}
              >
                <span className=" paymentText mx-3 py-3">
                  Pay with Credit Card
                </span>
                {/* <ImageTag
                  src={CreditCard}
                  alt="exit"
                  className="pay-credit"
                  // className="Exit_Img mr-1"
                /> */}
              </div>
              {getApplePayButton()}
              {getGooglePayButton()}
            </div>
          </div>
        </div>

        <div className="mt-5">
          {/* <div>
            <div className="d-flex">
              <StyledSVG src={creditcard1}/>
              <p>Card info</p>
            </div>
            <p>How would you like to pay?</p>
          </div> */}

          <div
            className={`py-4 Px-15 bg-white ${payWith != PAYMENT_TYPE_ENUM.CARD_PAY ? "cardPayment" : null
              }`}
            ref={cardPayRef}
          >
            <span className="d-flex align-items-center">
              {" "}
              <StyledSVG src={creditcard} width={20} />{" "}
              <span className="mx-2 payment-title">Card info</span>
            </span>
            <label className="details-lable mt-2">How would you like to pay? </label>
            {/* <div className="form-group mb-4 mt-3">
              <label className="mb-1">Select card type</label>

              <select
                className={`custom-select d-block w-100${
                  errorInputs?.payment_method ? " invalid" : ""
                }`}
                id="payment-method"
                onBlur={(e) => validations("payment_method")}
              >
                <option value={""}>Select</option>
                {table_detail?.QrPaymentMethod &&
                  table_detail?.QrPaymentMethod?.length > 0 &&
                  table_detail?.QrPaymentMethod.map((payMethod, index) => (
                    <option key={index} value={index}>
                      {payMethod?.payment_method_name ?? ""}
                    </option>
                  ))}
              </select>
            </div> */}

            <div className="form-group mt-2 mb-4">
              <label className="mb-1"> Cardholder name </label>
              <input
                className={`form-control-payment${errorInputs?.customer_holder_name ? " invalid" : ""
                  }`}
                id="card-holder-name"
                type="text"
                onBlur={(e) => validations("customer_holder_name")}
              />
            </div>
            <div className={`form-group mb-4 ${cardImgClass}`}>
              <label className="mb-1"> Card number </label>
              <div className=" card-number
              ">
                <MaskedInput
                  mask={[
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  guide={false}
                  placeholder={"xxxx xxxx xxxx xxxx"}
                  className={`form-control-payment  ${errorInputs?.card_number ? " invalid" : ""
                    }`}
                  id="card-number-element"
                  type="text"
                  onBlur={(e) => validations("card_number")}
                  onChange={(e) => checkCardType(e?.target?.value)}
                />
                {/* <img
                  src={creditCard}
                  style={{ width: "40px", height: " 25px" }}
                /> */}
              </div>
            </div>
            <div className="d-flex">
              <div className="form-group col-6 pl-0 pr-2 mb-0">
                <label className="mb-1"> Expiration date </label>
                <MaskedInput
                  mask={[/\d/, /\d/, "/", /\d/, /\d/]}
                  guide={false}
                  placeholder={"MM/YY"}
                  className={`form-control-payment${errorInputs?.card_expiry ? " invalid" : ""
                    }`}
                  id="card-expiry-element"
                  type="text"
                  onBlur={(e) => validations("card_expiry")}
                />
              </div>
              <div className="form-group col-6 pr-0 pl-2 mb-0">
                <label className="mb-1"> CVC </label>
                <MaskedInput
                  mask={[/\d/, /\d/, /\d/, /\d/]}
                  guide={false}
                  className={`form-control-payment${errorInputs?.card_cvc ? " invalid" : ""
                    }`}
                  id="card-cvc-element"
                  type="text"
                  onBlur={(e) => validations("card_cvc")}
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="pay-bill-tip Mx-15">
            <h3 class="title pt-0">
              {" "}
              <span className="like-heart d-flex align-items-center mr-0">
                <StyledSVG
                  color={
                    table_detail?.QrOrdering?.brand_highlight_color || "#8b8b8b"
                  }
                  src={tipHeart}
                  width={"21px"}
                  height={"21px"}

                />{" "}
              </span>
              <span className="payment-header">Want to leave a tip?{" "}</span>
            </h3>
            <p className="my-2"> How was your experience? </p>
            <div className="d-flex ">
              <span
                className={`col sm-box d-flex flex-column mx-1 ps-1 ${selectedTip == percentage(5, cartAmount)
                    ? " sm-box-active"
                    : "sm-box"
                  }`}
                onClick={() => {
                  const newPerVal = percentage(5, cartAmount);
                  if (selectedTip == newPerVal) {
                    setSelectedTip(0);
                  } else {
                    setSelectedTip(newPerVal);
                  }
                }}
              >
                {/* <input
                type="radio"
                id="good"
                className="radio-btn"
                name="tip"
                checked={
                  selectedTip == percentage(5, cartAmount) ? true : false
                }
              /> */}
                <label for="good"> </label>
                <small> Good (5%)</small>
                <strong> ${percentage(5, cartAmount, 2, false)} </strong>
              </span>
              <span
                className={`col sm-box d-flex flex-column  mx-1 ps-1 ${selectedTip == percentage(10, cartAmount)
                    ? " sm-box-active"
                    : "sm-box"
                  }`}
                onClick={() => {
                  const newPerVal = percentage(10, cartAmount);
                  if (selectedTip == newPerVal) {
                    setSelectedTip(0);
                  } else {
                    setSelectedTip(newPerVal);
                  }
                }}
              >
                {/* <input
                type="radio"
                id="great"
                className="radio-btn"
                name="tip"
                checked={
                  selectedTip == percentage(10, cartAmount) ? true : false
                }
              /> */}
                <label for="great"> </label>
                <small> Great (10%)</small>
                <strong> ${percentage(10, cartAmount, 2, false)} </strong>
              </span>
              <span
                className={`col sm-box d-flex flex-column mx-1 ps-1 ${selectedTip == percentage(15, cartAmount)
                    ? " sm-box-active"
                    : "sm-box"
                  }`}
                onClick={() => {
                  const newPerVal = percentage(15, cartAmount);
                  if (selectedTip == newPerVal) {
                    setSelectedTip(0);
                  } else {
                    setSelectedTip(newPerVal);
                  }
                }}
              >
                {/* <input
                type="radio"
                id="amazing"
                className="radio-btn"
                name="tip"
                checked={
                  selectedTip == percentage(15, cartAmount) ? true : false
                }
              /> */}
                <label for="amazing"> </label>
                <small> Amazing (15%)</small>
                <strong> ${percentage(15, cartAmount, 2, false)} </strong>
              </span>
            </div>

            <div className="custom-tip">
              <button className="edit-icon-btn" />
              <NumberFormat
                prefix={"$"}
                // displayType="text"
                className="blank_btn mb-0"
                placeholder="Custom tip"
                value={paymentTip > 0 ? paymentTip : ""}
                // onFocus={(e) => setSelectedTip(0)}
                inputmode="decimal"
                decimalScale={2}
                fixedDecimalScale={true}
                onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  // formattedValue = $2,223
                  // value ie, 2223
                  changeSplitPayTip(checkNull(value, false) ? value : 0);
                  // setSelectedTip(0);
                }}
              />
            </div>
          </div>
        </div>

        <button
          className={`input-btn input-pay mt-4 mb-0 Px-15 button-text ${getTotalAmount() == 0 ? " disable" : ""
            }`}
          style={{ background: table_detail?.QrOrdering?.brand_main_color }}
          onClick={(e) => {
            // if (getTotalAmount() > 0) completedPaymentHandler();
            if (getTotalAmount() > 0 && validations()) paymentHandler();
          }}
          disabled={getTotalAmount() == 0}
        >
          {" "}
          Pay ${parseRound(getTotalAmount())}
        </button>
        <div class=" container row Px-15 mt-4 mb-5 px-0">
          <div
            onClick={() => setShowTermsPopup(true)}
            className="privacy-policy col-4 px-0"
          >
            Terms & Conditions
          </div>
          <div
            onClick={() =>
              window.open("https://www.gogmgo.com/privacy-policy/", "_newtab")
            }
            className="privacy-policy col-4 "
          >
            Privacy Policy
          </div>
          <div className="privacy-policy col-4 pr-0  align-item-end">

            <div className="float-end">
              <span>Powered by</span>
              <StyledSVG className={"d-block mt-2"} src={layer} width={"70px"} height={"30px"} />
            </div>
          </div>

          {/* <p class="my-1 mx-2 text-center">
            By clicking <b>'PAY'</b>, I agree and accept to GoGMGo's{" "}
            <b onClick={() => setShowTermsPopup(true)}>Terms of Use</b> and{" "}
            <b
              onClick={() =>
                window.open("https://www.gogmgo.com/privacy-policy/", "_newtab")
              }
            >
              Privacy Policy
            </b>
          </p> */}
        </div>
        {/* <p className="confirmPay">By clicking 'CONFIRM & PAY', I agree and accept to GoGMGo's Terms of Use and Privacy Policy</p> */}

        <div className="outcome">
          <div className="error"></div>
          <div className="success">
            Success! Your Stripe token is <span className="token"></span>
          </div>
        </div>
      </div>
      <Modal
        show={
          paymentReq?.transactionStatus === "WAITING" ||
          paymentReq?.transactionResult === "WAITING"
        }
        // onCancel={() => {
        // }}
        // OnSubmit={() => {}}
        // cancelBtnText="No"
        // submitBtnText="Yes"
        modalbody={
          <>
            <div className="text-center">
              {loaderImgVal ? (
                <img src={loadingImg2} style={{ maxWidth: "85px" }}></img>
              ) : (
                <img src={loadingImg} style={{ maxWidth: "85px" }}></img>
              )}

              <div className="text-center">
                <h3 id={"redirectedText"}></h3>
              </div>
              <div id={"redirectedCon"}></div>
            </div>
          </>
        }
      />
      <Modal
        show={showTermsPopup}
        title={""}
        extraClass={"top50"}
        modalbody={<Terms />}
        OnSubmit={() => setShowTermsPopup(false)}
        submitBtnText="ok"
      />
    </>
  );
};

export default PaymentInfo;