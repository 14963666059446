import React, { useEffect, useState } from "react";
import {
  capitalizeFirstLetter,
  nFormatter,
  getUnixDateFormat,
  checkItemAvailable,
  deepCopy,
  checkArray,
  pluckArray,
  checkNull,
} from "../../helpers";
import {
  bagsShopping,
  giftCard,
  image2Icon,
  moneyBillWave,
  piggyBank,
  userImgIcon,
} from "../../Assets";
import { connect, useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  emptyEditedItemDetail,
  getSelectedItemDetail,
} from "../../Redux/actions";
import { StyledSVG } from "../../Utils/style";

function LoyalityAccount({addBundlePopUp,addItemPopUp,onCancel}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const userDetail = useSelector(
    (state) => state?.authDetailReducer?.user_detail
  );
  const table_detail = useSelector(
    (state) => state.tableDetailReducer.table_detail
  );
  const {
    xgate_details: xgateDetails,
    xgate_coupons: xgateCoupons,
    como_details: comoDetails,
    eber_details: eberDetails,
  } = useSelector((state) => state?.authDetailReducer);
  const tableReducer = useSelector((state) => state.tableDetailReducer);

  const loyaltyPackage =
    tableReducer?.table_detail?.Merchant?.MerchantLoyaltySetting
      ?.LoyaltyPackage ?? null;
  // const {xgate_details,xgate_coupons, como_details} = useSelector((state) => state?.authDetailReducer);
  // console.log("xgate_details",xgate_details)
  // console.log("xgate_coupons",xgate_coupons)
  // console.log("como_details",como_details)

  const avocadoDetail = useSelector(
    (state) => state?.authDetailReducer?.avocado_detail
  );
  const avocadoMemberList = useSelector(
    (state) => state?.authDetailReducer?.avocado_member_list
  );
  // console.log("avocadoDetail",avocadoDetail)
  // console.log("avocadoMemberList",avocadoMemberList)

  const reorder_item_ids = useSelector(
    (state) => state.cartDetailReducer.reorder_item_ids
  );
  // console.log("reorder_item_ids===========",reorder_item_ids)
  const items_86_detail = useSelector(
    (state) => state.tableDetailReducer.items_86_detail
  );

  const items86Arr = checkArray(items_86_detail)
    ? pluckArray(items_86_detail, "id")
    : [];

  const [reorderArr, setReorderArr] = useState([]);

  // reorder array
  const makeReorderItems = (reorder) => {
    let copyReorderArr = [];

    if (reorder_item_ids?.length > 0) {
      reorder_item_ids.map((itemKey, promoItemIndex) => {
        const promoSubKey = table_detail?.SubCategory?.findIndex(
          (p) => p.MenuItem.findIndex((q) => q.id == itemKey?.menu_item_id) > -1
        );
        const promoItemKey = table_detail?.SubCategory?.[
          promoSubKey
        ]?.MenuItem.findIndex((p) => p.id == itemKey?.menu_item_id);
        if (
          promoSubKey > -1 &&
          table_detail?.SubCategory?.[promoSubKey]?.MenuItem?.[promoItemKey] &&
          checkItemAvailable(
            table_detail?.SubCategory?.[promoSubKey]?.MenuItem?.[promoItemKey],
            table_detail?.Merchant?.timezone_offset ?? "00:00",
            table_detail
          )
        ) {
          const promoItem = deepCopy(
            table_detail?.SubCategory?.[promoSubKey]?.MenuItem?.[promoItemKey]
          );
          promoItem.recomItem = itemKey;
          copyReorderArr.push(promoItem);
        }
      });
    }

    if (copyReorderArr.length > 0)
      setReorderArr([...reorderArr, ...copyReorderArr]);
  };

  useEffect(() => {
    makeReorderItems();
  },[reorder_item_ids]);

  const countRedeemableFalse = (objects) => {
    let count = 0;
    objects.forEach((obj) => {
      if (obj.redeemable === false) {
        count++;
      }
    });
    return count;
  };

  const getActiveVoucher = () => {
    let res = 0;
    if (xgateCoupons?.general?.["ISSUED"]) {
      res = xgateCoupons?.general?.["ISSUED"];
    } else if (avocadoDetail?.campaignBalances) {
      res = avocadoDetail?.campaignBalances?.length;
    } else if (userDetail?.TotalActiveVoucherCount) {
      res = parseInt(userDetail?.TotalActiveVoucherCount);
    } else if (comoDetails?.membership?.assets.length) {
      res = countRedeemableFalse(comoDetails?.membership?.assets);
    } else if (eberDetails?.redeemable_list.length) {
      res = eberDetails?.redeemable_list.length;
    }
    return res;
  };

  const getPointBalance = () => {
    let res = 0;
    if (xgateDetails?.membership?.point_balance?.[0]?.balance) {
      res = parseFloat(xgateDetails?.membership?.point_balance?.[0]?.balance);
    } else if (
      avocadoDetail?.campaignBalances &&
      avocadoDetail?.campaignBalances?.filter(
        (p) => p?.campaignType == "loyalty"
      ).length > 0
    ) {
      res = avocadoDetail?.campaignBalances
        ?.filter((p) => p?.campaignType == "loyalty")
        .reduce(function (sum, current) {
          return sum + parseFloat(current.points);
        }, 0);
    } else if (comoDetails?.membership) {
      res = comoDetails?.membership?.pointsBalance?.balance?.monetary;
      res = Math.floor(res / 100);
    } else if (userDetail?.TotalPointsBAL) {
      res = userDetail?.TotalPointsBAL;
    } else if (eberDetails?.points) {
      res = eberDetails?.points[0]?.points;
    }

    return nFormatter(res);
  };

  const getPointUsed = () => {
    let res = (
      <>
      <h4 className="text-start ml-2" >N/A</h4>
        <p className="text-start ml-2 loyality-title"> Points Used </p>
        
      </>
    );
    if (xgateDetails?.membership?.tlv) {
      res = (
        <>
        <h4 className="text-start ml-2" >
            {`${table_detail?.currency ?? ""}`}
            {nFormatter(parseFloat(xgateDetails?.membership?.tlv))}
          </h4>
          <p className="text-start ml-2 loyality-title"> Total Spends </p>
        </>
      );
    }
    if (
      avocadoDetail?.campaignBalances &&
      avocadoDetail?.campaignBalances?.filter(
        (p) => p?.campaignType == "loyalty"
      ).length > 0
    ) {
      let usedVal = avocadoDetail?.campaignBalances
        ?.filter((p) => p?.campaignType == "loyalty")
        .reduce(function (sum, current) {
          return sum + parseFloat(current.points);
        }, 0);
      res = (
        <>
          <h4 className="text-start ml-2" >{nFormatter(parseFloat(usedVal))}</h4>
          <p className="text-start ml-2 loyality-title"> Points Used </p>
        </>
      );
    } else if (comoDetails?.membership) {
      res = (
        <>
        <h4 className="text-start ml-2" >N/A</h4>
          <p className="text-start ml-2 loyality-title"> Points Used </p>
          
        </>
      );
    } else if (userDetail?.TotalPointsBAL) {
      res = (
        <>
        <h4 className="text-start ml-2">{nFormatter(parseFloat(userDetail?.TotalPointsBAL))}</h4>
          <p className="text-start ml-2 loyality-title"> Points Used </p>
          
        </>
      );
    }
    return res;
  };

  const getTotalVisits = () => {
    let res = "N/A";
    if (avocadoDetail !== null) {
      res = "N/A";
    } else if (userDetail?.TotalVisits) {
      res = userDetail?.TotalVisits;
    } else if (eberDetails?.points[0]?.num_visits) {
      res = eberDetails?.points[0]?.num_visits;
    }
    return res;
  };

  const getMembershipTier = () => {
    let res = "MEMBER";
    if (xgateDetails?.membership?.tiername) {
      res = xgateDetails?.membership?.tiername;
    } else if (
      avocadoDetail?.membership &&
      avocadoDetail?.membership?.length > 0
    ) {
      res = avocadoDetail?.membership?.[0]?.membershipTagName;
    } else if (userDetail?.TierCode) {
      res = userDetail?.TierCode;
    } else if (eberDetails?.member_tiers[0].name) {
      res = eberDetails?.member_tiers[0].name;
    }
    return res;
  };

  const getMemberSince = () => {
    switch (loyaltyPackage?.code) {
      case "acsentis":
        return moment(avocadoDetail?.membership?.[0]?.expiredDate).format(
          "MMMM D, YYYY"
        );
      case "eber":
        return moment(new Date(eberDetails?.member?.created_at)).format(
          "MMMM D, YYYY"
        );
      case "como":
        return moment(comoDetails?.membership?.createdOn).format(
          "MMMM D, YYYY"
        );
      case "xgate":
        return moment(xgateDetails?.birthday).format("MMMM D, YYYY");
      default:
        return getUnixDateFormat(userDetail?.IssueDate);
    }
  };

  const getMemberStatus = () => {
    let res = "N/A";
    if (xgateDetails?.membership?.memberstatus) {
      res = xgateDetails?.membership?.memberstatus;
    } else if (comoDetails?.membership) {
      res = comoDetails?.membership?.status;
    } else if (
      avocadoDetail?.membership &&
      avocadoDetail?.membership?.length > 0
    ) {
      res = "Active";
    } else if (userDetail?.MembershipStatusCode) {
      res = capitalizeFirstLetter(userDetail?.MembershipStatusCode);
    }
    return res;
  };

  const getLastVisitDate = () => {
    let res = "N/A";
    if (avocadoDetail !== null) {
      res = "N/A";
    } else if (userDetail?.LastVisitedDate) {
      res = getUnixDateFormat(userDetail?.LastVisitedDate);
    } else if (eberDetails?.points[0]?.friendly_last_visit_at) {
      res = moment(eberDetails?.points[0]?.friendly_last_visit_at).format(
        "MMMM D, YYYY"
      );
    }
    return res;
  };

  const getRegularStore = () => {
    let res = table_detail?.Merchant?.account_name;
    if (xgateDetails?.membership?.issuestore) {
      res = xgateDetails?.membership?.issuestore;
    } else if (avocadoDetail !== null) {
      res = "N/A";
    } else if (userDetail?.MostCycleVisitedOutletCode) {
      res = userDetail?.MostCycleVisitedOutletCode;
    }
    return res;
  };

  const getBirthDate = () => {
 
    switch (loyaltyPackage?.code) {
      case "acsentis":
        return moment(avocadoDetail?.dateOfBirth).format("MMMM D, YYYY");
      case "eber":
        // return (eber_details?.birth_day+"/"+eber_details?.birth_month+"/"+eber_details?.birth_year);
        return moment(
            eberDetails?.birth_day +
              "/" +
              eberDetails?.birth_month +
              "/" +
              eberDetails?.birth_year,'DD/MM/YYYY'
        ).format("MMMM D, YYYY");
      case "como":
        return moment(comoDetails?.membership?.birthday).format("YYYY D, MMMM");
      case "xgate":
        return moment(xgateDetails?.birthday).format("MMMM D, YYYY");
      default:
        return getUnixDateFormat(userDetail?.date_of_birth);
    }
  };

  const routeToItems = (reorderItem) => {
    onCancel()
    // setTimeout(()=>{
      if (reorderItem?.menu_item_type == "bundled") {
        addBundlePopUp();
      } else {
        addItemPopUp();
      }
    // })
    
  }



  return (
    <div>
      
      { (
        <>
          <section className=" bg-white history-info">
          <section className="info-sec info-sec-fixed">
        <div className="account-info">
          <div className="acc-info-con row mx-0">
            {/* <h4 className="title col-12"> Account Info </h4> */}
            {avocadoDetail !== null && avocadoMemberList.length > 0 && (
              <div className="col-6">
                <button className="btn btn-sm float-right mt-2">UPGRADE</button>
              </div>
            )}
          </div>

          {avocadoDetail !== null && avocadoMemberList.length > 0 && (
            <div className="info d-flex align-items-end flex-wrap">
              {avocadoMemberList.map((memberShip, memInx) => {
                return (
                  <div
                    className={`col-3 my-2 ${
                      memInx % 3 !== 0 || memInx == 0 ? "border-right" : ""
                    }`}
                  >
                    <button className="btn btn-sm btn-primary">
                      {memberShip?.membershipTierName ?? ""}
                    </button>
                  </div>
                );
              })}
            </div>
          )}

          <div className="info d-flex align-items-end mx-3">
            <div className="col-3 d-flex  px-0 my-2">
              <StyledSVG className="loyality-overflow" src={giftCard} width={"21px"} height={"21px"} />
              <span>
                <h4 className="text-start ml-2">{getActiveVoucher()}</h4>
                <p className="text-start ml-2 loyality-title"> Active Vouchers </p>
              </span>
            </div>
            <div className="col-3 px-0 d-flex my-2">
              <StyledSVG className="loyality-overflow" src={piggyBank} width={"21px"} height={"21px"} />
              <span>
                <h4 className="text-start ml-2">{getPointBalance()}</h4>
                <p className="text-start ml-2 loyality-title"> Points Balance </p>
              </span>
            </div>
            <div className="col-3 px-0  d-flex my-2">
              <StyledSVG className="loyality-overflow" src={moneyBillWave} width={"21px"} height={"21px"} />
              <span>

                {getPointUsed()}
              </span>
            </div>
            <div className="col-3 px-0 d-flex my-2">
              <StyledSVG className="loyality-overflow" src={bagsShopping} width={"21px"} height={"21px"} />
              <span>
                <h4 className="text-start ml-2"> {getTotalVisits()} </h4>
                <p className="text-start ml-2 loyality-title"> # of <br/> Visits </p>
              </span>
            </div>
          </div>

          <h2 className="member-info-title ml-3 tt-p">Member Info</h2>

          <div className="info-details row mx-0">
            <div className="col-6">
              <label> Member Tier </label>
              <p className="">{getMembershipTier()}</p>
            </div>
            <div className="col-6">
              <label> Member Since </label>
              <p>{getMemberSince()}</p>
            </div>
            <div className="col-6">
              <label> Status </label>
              <p>{getMemberStatus()}</p>
            </div>
            <div className="col-6">
              <label> Last Visit </label>
              <p> {getLastVisitDate()}</p>
            </div>
            <div className="col-6">
              <label> Regular Store </label>
              <p>{getRegularStore()}</p>
            </div>
            <div className="col-6">
              <label> Birth Date </label>
              <p>{getBirthDate()}</p>
            </div>
          </div>
        </div>
      </section>
            <div className="fav-info">
              <h2 className="member-info-title tt-p bg-white Px-15"> Favorites </h2>
            </div>
            {reorderArr.map((reorderItem, reorderIdx) => {
              const checkValidImg = /\.(jpe?g|png|gif|bmp)$/i.test(
                reorderItem?.image
              );
              return (
                <div
                  className="menu-box row"
                  key={reorderIdx}
                  onClick={() => {
                    const checkPeriodAvailable =
                      reorderItem?.menu_item_type == "bundled"
                        ? true
                        : checkItemAvailable(
                            reorderItem,
                            table_detail?.Merchant?.timezone_offset ?? "00:00",
                            table_detail
                          );
                    const checkAvailable =
                      // reorderItem?.is_86_item == "false" &&
                      !items86Arr.includes(reorderItem?.id) ? true : false;
                    if (checkAvailable && checkPeriodAvailable) {
                      dispatch(getSelectedItemDetail(reorderItem));
                      dispatch(emptyEditedItemDetail());
                      routeToItems(reorderItem)
                      // if (reorderItem?.menu_item_type == "bundled")
                      //   history.push("/bundle-add-item");
                      // else history.push("/add-item");
                      
                    }
                  }}
                >
                  {checkValidImg && (
                    <figure className="col-3 menu-img py-2">
                      <img src={reorderItem?.image} />
                    </figure>
                  )}

                  <div
                    className={`${
                      checkValidImg ? "col-9" : "col"
                    } menu-content pl-2`}
                  >
                    <h4 className="menu-loyality-title">{reorderItem?.menu_item_name}</h4>
                    <p>{reorderItem?.description}</p>
                    <p className="pricing">
                      {" "}
                      <small className="fw-bolder">{`${table_detail?.currency ?? ""}`}</small>
                      {`${reorderItem?.dinein_price?.DineIn}`}
                    </p>
                  </div>
                  {/* <div className="col-3 px-0">
                <a href="#_" className="square-img">
                  <h5> 4 </h5>
                  <p> Promos </p>
                </a>
              </div> */}
                </div>
              );
            })}
          </section>
        </>
      )}
    </div>
  );
}
export default LoyalityAccount;
