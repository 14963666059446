import { PdfViewer, LoginScreen, LoginServerScreen, TableViewerScreen } from "../Scenes/AuthScreens";
import {
  HomeScreen,
  PaymentDetailScreen,
  PaymentScreen,
  PaymentConfirmScreen,
  PaymentSplitScreen,
  PaymentSuccessScreen,
} from "../Scenes/Screens";
import UnableToLoadData from "../Scenes/Components/UnableToLoadData";


export const authRoutes = [
  {
    path: "/pdf-menu/:id",
    component: PdfViewer,
  },
  {
    path: "/get_table_details/:type/:id/",
    component: HomeScreen,
  },
  {
    path: "/get_table_details/:id/",
    component: HomeScreen,
  },
  {
    path: "/get_server_details/:merchantId/:serverId",
    component: LoginServerScreen,
  },
  {
    path: "/payment-detail/:id",
    component: PaymentDetailScreen,
  },
  {
    path: "/payment",
    component: PaymentScreen,
  },
  {
    path: "/payment-confirm",
    component: PaymentConfirmScreen,
  },
  {
    path: "/payment-split",
    component: PaymentSplitScreen,
  },
  {
    path: "/payment-success",
    component: PaymentSuccessScreen,
  },
  {
    path: "/login",
    component: LoginScreen,
  },
  {
    path: "error-page",
    component: UnableToLoadData,
  },
  {
    path: "/table-Viewer",
    component: TableViewerScreen,
  },
];
